import React from 'react';

import OptimizelyChampageEnums from 'optly/modules/optimizely_champagne/enums';
import PerformanceTrackingActions from 'optly/modules/performance_tracking/actions';
import PermissionGetters from 'optly/modules/permissions/getters';
import BundleSplitHelper from 'optly/utils/bundle_split_helper';

import NavConstants from 'optly/services/navigation';
import RoutingFns from 'bundles/p13n/routing_fns';

import { pages } from '.';

const category = 'Implementation';

function ImplementationSection(ctx, next) {
  import(
    /* webpackChunkName: "implementation-bundle" */
    'bundles/p13n/sections/implementation' // eslint-disable-line
  )
    .then(module => {
      // use the function object itself as the section module import. We copy the module properties onto the function
      Object.assign(ImplementationSection, module);
    })
    .then(next);
}

export const routes = [
  {
    match: '/v2/projects/:proj_id/implementation/catalogs',
    metadata: { name: 'Catalogs', category },
    handle: [
      [
        ImplementationSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.PAGES,
          NavConstants.DataLayerTabs.CATALOGS_DASHBOARD_TAB,
        ),
        RoutingFns.parseProjectId,
        (ctx, next) =>
          BundleSplitHelper.getCodeLintingBundleModules().then(next),
      ],
      (ctx, next) =>
        RoutingFns.ensureAccessToFeatureOrRedirect(
          ctx,
          next,
          PermissionGetters.canUseRecommendationsDashboard,
        ),
      (ctx, next) =>
        ImplementationSection.pages.catalog_dashboard.routingSetup(ctx, next),
      (ctx, next) =>
        RoutingFns.setPageTitle({
          category,
          next,
          name: 'Catalogs',
        }),
      () => {
        PerformanceTrackingActions.setFrontendRenderStartTime();
        RoutingFns.renderMainRegion(<pages.catalog_dashboard.component />);
      },
    ],
  },

  {
    match: '/v2/projects/:proj_id/implementation/recommenders',
    metadata: { name: 'Recommenders', category },
    handle: [
      [
        ImplementationSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.PAGES,
          NavConstants.DataLayerTabs.RECOMMENDERS_DASHBOARD_TAB,
        ),
        RoutingFns.parseProjectId,
        (ctx, next) =>
          BundleSplitHelper.getCodeLintingBundleModules().then(next),
      ],
      (ctx, next) =>
        RoutingFns.ensureAccessToFeatureOrRedirect(
          ctx,
          next,
          PermissionGetters.canUseRecommendationsDashboard,
        ),
      (ctx, next) => pages.recommender_dashboard.routingSetup(ctx, next),
      (ctx, next) =>
        RoutingFns.setPageTitle({
          category,
          next,
          name: 'Recommenders',
        }),
      () => {
        PerformanceTrackingActions.setFrontendRenderStartTime();
        RoutingFns.renderMainRegion(<pages.recommender_dashboard.component />);
      },
    ],
  },
  {
    match: '/v2/projects/:proj_id/implementation/inspector',
    metadata: { name: 'Inspector', category },
    handle: [
      [
        ImplementationSection,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.PAGES,
          NavConstants.DataLayerTabs.INSPECTOR_TAB,
        ),
        RoutingFns.parseProjectId,
        (ctx, next) =>
          BundleSplitHelper.getCodeLintingBundleModules().then(next),
      ],
      (ctx, next) =>
        RoutingFns.ensureAccessToFeatureOrRedirect(
          ctx,
          next,
          PermissionGetters.canUseEventInspector,
        ),
      (ctx, next) => pages.event_inspector.routingSetup(ctx, next),
      (ctx, next) =>
        RoutingFns.setPageTitle({
          category,
          next,
          name: 'Inspector',
        }),
      () => {
        PerformanceTrackingActions.setFrontendRenderStartTime();
        RoutingFns.renderMainRegion(<pages.event_inspector.component />);
      },
    ],
  },
];

export default routes;
