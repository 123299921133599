import _ from 'lodash';
import { Store as NuclearStore } from 'nuclear-js';

import cloneDeep from 'optly/clone_deep';
import AudienceEnums from 'optly/modules/entity/audience/enums';
import Immutable, { toImmutable } from 'optly/immutable';
import {
  enums as LayerExperimentEnums,
  fns as LayerExperimentFns,
} from 'optly/modules/entity/layer_experiment';
import * as LayerConstants from 'optly/modules/entity/layer/constants';

import actionTypes from '../action_types';
import enums from '../enums';
import { Variations, Sections } from '../constants';
import fns from '../fns';

const { VARIATION_ORIGINAL, VARIATION_1, SINGLE_VARIATION } = Variations;

const { DEFAULT_SECTION } = Sections;

const initialState = toImmutable({
  currentExperimentId: null,
  name: null,
  description: null,
  selectedAudienceIds: [],
  selectedViewIds: Immutable.Set(),
  variations: [],
  bucketingStrategy: null,
  audienceMatchType: null,
  // TODO(APPX-34) Update to "audience_conditions" when that field is deserialized with rich JSON for all LayerExperiments
  audienceConditions: null,
  audienceConditionsJson: [],
  selectedVariableIds: [],
  sections: [],
  urlTargetingConfig: LayerConstants.DEFAULT_URL_TARGETING_CONFIG,
  targetingType: null,
  currentlySelectedTrafficAllocationPolicy: 'manual',
  currentlySelectedDistributionGoal: null,
  exploitationRate: null,
  isAutomaticDistributionGoal: false,
  errors: {},
});

export default NuclearStore({
  getInitialState() {
    return initialState;
  },

  initialize() {
    this.on(actionTypes.CREATE_EXP_COMPONENT_ADD_AUDIENCE, addAudience);
    this.on(actionTypes.CREATE_EXP_COMPONENT_ADD_VARIABLE, addVariable);
    this.on(actionTypes.CREATE_EXP_COMPONENT_ADD_VARIATION, addVariation);
    this.on(actionTypes.CREATE_EXP_COMPONENT_INIT, init);
    this.on(actionTypes.CREATE_EXP_COMPONENT_PAUSE_VARIATION, pauseVariation);
    this.on(actionTypes.CREATE_EXP_COMPONENT_REMOVE_AUDIENCE, removeAudience);
    this.on(
      actionTypes.CREATE_EXP_COMPONENT_REMOVE_IDS_FROM_VARIATIONS_FOR_DUPLICATE_EXPERIMENTS,
      removeIdsFromVariationsForDuplicateExperiments,
    );
    this.on(actionTypes.CREATE_EXP_COMPONENT_REMOVE_VARIABLE, removeVariable);
    this.on(actionTypes.CREATE_EXP_COMPONENT_REMOVE_VARIATION, removeVariation);
    this.on(
      actionTypes.CREATE_EXP_COMPONENT_RESET_TO_UNIFORM_TRAFFIC,
      resetToUniformTraffic,
    );
    this.on(actionTypes.CREATE_EXP_COMPONENT_RESUME_VARIATION, resumeVariation);
    this.on(actionTypes.CREATE_EXP_COMPONENT_TEARDOWN, teardown);
    this.on(
      actionTypes.CREATE_EXP_COMPONENT_UPDATE_AUDIENCE_CONDITIONS,
      updateAudienceConditions,
    );
    this.on(
      actionTypes.CREATE_EXP_COMPONENT_UPDATE_AUDIENCE_CONDITIONS_JSON,
      updateAudienceConditionsJson,
    );
    this.on(
      actionTypes.CREATE_EXP_COMPONENT_UPDATE_AUDIENCE_IDS,
      updateAudienceIds,
    );
    this.on(
      actionTypes.CREATE_EXP_COMPONENT_UPDATE_AUDIENCE_MATCH_TYPE,
      updateAudienceMatchType,
    );
    this.on(
      actionTypes.CREATE_EXP_COMPONENT_UPDATE_BUCKETING_STRATEGY,
      updateBucketingStrategy,
    );
    this.on(
      actionTypes.CREATE_EXP_COMPONENT_UPDATE_DESCRIPTION,
      updateDescription,
    );
    this.on(
      actionTypes.CREATE_EXP_COMPONENT_UPDATE_LAYER_HOLDBACK,
      updateLayerHoldback,
    );
    this.on(actionTypes.CREATE_EXP_COMPONENT_UPDATE_NAME, updateName);
    this.on(
      actionTypes.CREATE_EXP_COMPONENT_UPDATE_CURRENTLY_SELECTED_TRAFFIC_ALLOCATION_POLICY,
      updateCurrentlySelectedTrafficAllocationPolicy,
    );
    this.on(
      actionTypes.CREATE_EXP_COMPONENT_UPDATE_CURRENTLY_SELECTED_DISTRIBUTION_GOAL,
      updateCurrentlySelectedDistributionGoal,
    );
    this.on(
      actionTypes.CREATE_EXP_COMPONENT_UPDATE_IS_AUTOMATIC_DISTRIBUTION_GOAL,
      updateisAutomaticDistributionGoal,
    );
    this.on(
      actionTypes.CREATE_EXP_COMPONENT_UPDATE_EXPLOITATION_RATE,
      updateExploitationRate,
    );
    this.on(
      actionTypes.CREATE_EXP_COMPONENT_UPDATE_VARIABLE_VALUE,
      updateVariable,
    );
    this.on(actionTypes.CREATE_EXP_COMPONENT_UPDATE_VARIATION, updateVariation);
    this.on(
      actionTypes.CREATE_EXP_SET_CURRENT_EXPERIMENT_ID_TO_NULL,
      setCurrentExperimentIdToNull,
    );
    this.on(actionTypes.CREATE_EXP_COMPONENT_ADD_SECTION, addSection);
    this.on(actionTypes.CREATE_EXP_COMPONENT_REMOVE_SECTION, removeSection);
    this.on(
      actionTypes.CREATE_EXP_COMPONENT_ADD_VARIATION_TO_SECTION,
      addVariationToSection,
    );
    this.on(
      actionTypes.CREATE_EXP_COMPONENT_REMOVE_VARIATION_FROM_SECTION,
      removeVariationFromSection,
    );
    this.on(
      actionTypes.CREATE_EXP_COMPONENT_UPDATE_VARIATION_IN_SECTION,
      updateVariationInSection,
    );
    this.on(
      actionTypes.CREATE_EXP_COMPONENT_UPDATE_SECTION_NAME,
      updateSectionName,
    );
    this.on(actionTypes.CREATE_EXP_COMPONENT_SET_VIEW_IDS, setViewIds);
    this.on(
      actionTypes.CREATE_EXP_COMPONENT_SET_URL_TARGETING_CONFIG,
      setUrlTargetingConfig,
    );
    this.on(
      actionTypes.CREATE_EXP_COMPONENT_SET_TARGETING_TYPE,
      setTargetingType,
    );
    this.on(actionTypes.CREATE_EXP_COMPONENT_SET_ERRORS, setErrors);
    this.on(
      actionTypes.CREATE_EXP_COMPONENT_UPDATE_VARIATION_PROPERTY,
      updateVariationProperty,
    );
    this.on(
      actionTypes.CREATE_EXP_COMPONENT_UPDATE_USER_ATTRIBUTES,
      updateUserAttributes,
    );
  },
});

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {Object} payload.experiment
 */
function init(state, payload) {
  const { experiment, mode, type } = payload;
  let currentExperimentId;
  let name;
  let description;
  let selectedViewIds;
  let selectedAudienceIds;
  let variations;
  let sections;
  let audienceMatchType;
  // TODO(APPX-34) Update to "audience_conditions" when that field is deserialized with rich JSON for all LayerExperiments
  let audienceConditions;
  let audienceConditionsJson;
  let isAutomaticDistributionGoal;
  let exploitationRate;
  const { layerHoldback } = payload;
  let selectedVariableIds = [];
  let userAttributes;

  if (mode === enums.modes.EDIT || mode === enums.modes.DUPLICATE) {
    currentExperimentId = toImmutable(experiment.id);
    name = experiment.name;
    description = experiment.description;
    selectedViewIds = experiment.variations.map(variation => {
      if (variation.actions) {
        return variation.actions.map(action => action.view_id);
      }
    });
    selectedViewIds = toImmutable(_.uniq(_.flattenDeep(selectedViewIds)));
    selectedAudienceIds = toImmutable(experiment.audience_ids);
    // If no existing audience_match_type, default to ALL.
    audienceMatchType = !experiment.audience_match_type
      ? AudienceEnums.audienceMatchTypes.ALL_AUDIENCES
      : toImmutable(experiment.audience_match_type);
    // TODO(APPX-34) Update to "audience_conditions" when that field is deserialized with rich JSON for all LayerExperiments
    audienceConditions = toImmutable(experiment.audience_conditions);
    audienceConditionsJson = toImmutable(experiment.audience_conditions_json);
    isAutomaticDistributionGoal =
      experiment.is_cmab_automatic_distribution || false;
    exploitationRate = experiment.cmab_distribution_exploitation_rate || null;
    variations = toImmutable(fns.guidifyList(experiment.variations));
    if (payload.variableUsages) {
      selectedVariableIds = toImmutable(
        payload.variableUsages.map(usage => usage.live_variable_id),
      );
      variations = fns.addVariableValuesToVariations(
        variations,
        toImmutable(payload.variableUsages),
      );
    }
    userAttributes = experiment.cmab_user_attributes || [];
  }

  if (mode === enums.modes.DUPLICATE) {
    if (!name) {
      name = null;
    } else {
      name = `Copy of ${name}`;
    }

    description = !description ? null : description;

    currentExperimentId = null;
  }

  if (mode === enums.modes.CREATE) {
    if (type === enums.types.P13N_EXP) {
      variations = fns.guidifyList([cloneDeep(SINGLE_VARIATION)]);
    } else if (payload.isMultivariateTest) {
      // If the layer policy is MVT, then empty out the variations array and initialize a sections list
      // with the default section in it.
      variations = toImmutable([]);
      const defaultSection = cloneDeep(DEFAULT_SECTION);
      // add guids to variations.
      defaultSection.variations = fns.guidifyList(defaultSection.variations);
      sections = toImmutable(fns.guidifyList([defaultSection]));
    } else {
      variations = fns.guidifyList([
        cloneDeep(VARIATION_ORIGINAL),
        cloneDeep(VARIATION_1),
      ]);
    }
    return initialState
      .set('variations', toImmutable(variations))
      .set('audienceMatchType', AudienceEnums.audienceMatchTypes.ALL_AUDIENCES)
      .set('layerHoldback', layerHoldback)
      .set('sections', sections)
      .set('type', type);
  }

  return (
    state
      .set('currentExperimentId', currentExperimentId)
      .set('name', name)
      .set('description', description)
      .set('selectedAudienceIds', selectedAudienceIds)
      .set('selectedViewIds', selectedViewIds)
      .set('variations', variations)
      .set('bucketingStrategy', experiment.bucketing_strategy)
      // TODO(APPX-34) Update to "audience_conditions" when that field is deserialized with rich JSON for all LayerExperiments
      .set('audienceConditions', audienceConditions)
      .set('audienceConditionsJson', audienceConditionsJson)
      .set('audienceMatchType', audienceMatchType)
      .set('layerHoldback', layerHoldback)
      .set('type', type)
      .set('selectedVariableIds', selectedVariableIds)
      .set('isAutomaticDistributionGoal', isAutomaticDistributionGoal)
      .set('exploitationRate', exploitationRate)
      .set('userAttributes', userAttributes)
  );
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 */
function teardown(state, payload) {
  return initialState;
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 */
function updateName(state, payload) {
  return state.set('name', payload);
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 */
function updateDescription(state, payload) {
  return state.set('description', payload);
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {Immutable.Set} payload.viewIds
 */
function setViewIds(state, payload) {
  return state.set('selectedViewIds', Immutable.Set(payload.viewIds));
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {Object} payload.urlTargetingConfig
 */
function setUrlTargetingConfig(state, payload) {
  return state.set('urlTargetingConfig', payload.urlTargetingConfig);
}

function setTargetingType(state, payload) {
  return state.set('targetingType', payload.targetingType);
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 */
function addAudience(state, payload) {
  return state.update('selectedAudienceIds', selectedAudienceIds =>
    selectedAudienceIds.push(payload.audienceId),
  );
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 */
function removeAudience(state, payload) {
  return state.update('selectedAudienceIds', audienceIds =>
    audienceIds.filter(audienceId => audienceId !== payload.audienceId),
  );
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 */
function updateAudienceIds(state, payload) {
  return state.set('selectedAudienceIds', toImmutable(payload));
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 */
function updateLayerHoldback(state, payload) {
  return state.set('layerHoldback', toImmutable(payload));
}

/**
 * Sets the variation status to 'paused' and re-distributes its traffic to other active variations.
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @return {Immutable.Map}
 */
function pauseVariation(state, payload) {
  return state.update('variations', variations => {
    const indexOfUpdatedVariation = variations.findIndex(
      variation => variation.get('guid') === payload.variation.guid,
    );
    const variation = variations.get(indexOfUpdatedVariation);
    const pausedTrafficWeight = variation.get('weight');
    const pausedVariation = variation
      .set('status', LayerExperimentEnums.VariationStatus.PAUSED)
      .set('weight', 0);
    const updatedVariations = variations.set(
      indexOfUpdatedVariation,
      pausedVariation,
    );
    const variationsWithRedistributedTraffic = LayerExperimentFns.redistributePausedVariationTraffic(
      updatedVariations,
      pausedTrafficWeight,
    );
    return LayerExperimentFns.addPercentageToVariations(
      variationsWithRedistributedTraffic,
    );
  });
}

/**
 * Sets the variation status to 'active' and re-allocates traffic to it.
 * @param  {Immutable.Map} state
 * @param  {Object} payload
 * @return {Immutable.Map}
 */
function resumeVariation(state, payload) {
  return state.update('variations', variations => {
    const indexOfUpdatedVariation = variations.findIndex(
      variation => variation.get('guid') === payload.variation.guid,
    );
    const variation = variations.get(indexOfUpdatedVariation);
    const resumedVariation = variation.set(
      'status',
      LayerExperimentEnums.VariationStatus.ACTIVE,
    );
    const updatedVariations = variations.set(
      indexOfUpdatedVariation,
      resumedVariation,
    );
    const variationsWithRedistributedTraffic = LayerExperimentFns.redistributeResumedVariationTraffic(
      resumedVariation.get('variation_id'),
      updatedVariations,
    );
    return LayerExperimentFns.addPercentageToVariations(
      variationsWithRedistributedTraffic,
    );
  });
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 */
function updateVariation(state, payload) {
  return state.update('variations', variations => {
    const indexOfUpdatedVariation = variations.findIndex(
      variation => variation.get('guid') === payload.variation.guid,
    );
    return variations.set(
      indexOfUpdatedVariation,
      toImmutable(payload.variation),
    );
  });
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 */
function addVariation(state) {
  return state.update('variations', variations => {
    const newVariation = fns.guidifySingle({
      name: LayerExperimentFns.suggestedNewVariationName(variations),
      percentage: '',
      variable_values: {},
    });
    variations = variations.push(toImmutable(newVariation));
    const variationWithRedistributedTraffic = LayerExperimentFns.redistributeWithNewVariationTrafficImmutable(
      variations,
    );
    const variationsWithPercentages = LayerExperimentFns.addPercentageToVariations(
      variationWithRedistributedTraffic,
    );
    return variationsWithPercentages;
  });
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 */
function removeVariation(state, payload) {
  return state.update('variations', variations => {
    const indexOfDeletedVariation = variations.findIndex(
      variation => variation.get('guid') === payload.variation.guid,
    );
    let updatedVariations = variations.delete(
      indexOfDeletedVariation,
      payload.variation,
    );
    updatedVariations = LayerExperimentFns.redistributeWithNewVariationTrafficImmutable(
      updatedVariations,
    );
    const variationsWithPercentages = LayerExperimentFns.addPercentageToVariations(
      updatedVariations,
    );
    return variationsWithPercentages;
  });
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 */
function resetToUniformTraffic(state, payload) {
  return state.set('variations', toImmutable(payload));
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 */
function updateBucketingStrategy(state, payload) {
  return state.set('bucketingStrategy', payload);
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 */
function updateUserAttributes(state, payload) {
  return state.set('userAttributes', payload);
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 */
function updateAudienceMatchType(state, payload) {
  return state.set('audienceMatchType', payload);
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 */
// TODO(APPX-34) Update to "audience_conditions" when that field is deserialized with rich JSON for all LayerExperiments
function updateAudienceConditions(state, payload) {
  return state.set('audienceConditions', payload);
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 */
// TODO(APPX-34) Update to "audience_conditions" when that field is deserialized with rich JSON for all LayerExperiments
function updateAudienceConditionsJson(state, payload) {
  return state.set('audienceConditionsJson', payload);
}

/**
 * @param {Immutable.Map} state
 */
function removeIdsFromVariationsForDuplicateExperiments(state) {
  return state.updateIn(['variations'], variations =>
    variations.map(variation => variation.delete('variation_id')),
  );
}

/**
 * @param {Immutable.Map} state
 */
function setCurrentExperimentIdToNull(state) {
  return state.set('currentExperimentId', null);
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 */
function addVariable(state, payload) {
  return state.update('selectedVariableIds', variableIds =>
    variableIds.push(toImmutable(payload.variableId)),
  );
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 */
function removeVariable(state, payload) {
  return state.update('selectedVariableIds', variableIds =>
    variableIds.filter(variableId => variableId !== payload.variableId),
  );
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 */
function updateVariable(state, payload) {
  return state.update('variations', variations => {
    const targetIndex = variations.findIndex(
      variation => variation.get('guid') === payload.variationGuid,
    );
    return variations.setIn(
      [targetIndex, 'variable_values', payload.variableId],
      payload.value,
    );
  });
}

/**
 * @param {Immutable.Map} state
 */
function addSection(state) {
  const newSection = fns.createNewSection(state.get('sections').size);
  const updatedSections = state.get('sections').push(newSection);
  return state.set('sections', updatedSections);
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 */
function removeSection(state, payload) {
  const updatedSections = state
    .get('sections')
    .filter(section => section.get('guid') !== payload.sectionGuid);
  return state.set('sections', updatedSections);
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 */
function addVariationToSection(state, payload) {
  // `findEntry` returns the first [key, value] entry for which the predicate returns true
  // https://facebook.github.io/immutable-js/docs/#/Map/findEntry
  const [sectionIndex, sectionToUpdate] = state
    .get('sections')
    .findEntry(section => section.get('guid') === payload.sectionGuid);
  let variations = sectionToUpdate.get('variations');
  const newVariation = fns.guidifySingle({
    name: LayerExperimentFns.suggestedNewVariationName(variations),
    weight: '',
  });
  variations = variations.push(toImmutable(newVariation));
  variations = toImmutable(
    LayerExperimentFns.redistributeWithNewVariationTraffic(variations.toJS()),
  );
  const updatedSection = sectionToUpdate.set('variations', variations);
  return state.setIn(['sections', sectionIndex], updatedSection);
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 */
function removeVariationFromSection(state, payload) {
  const [sectionIndex, sectionToUpdate] = state
    .get('sections')
    .findEntry(section => section.get('guid') === payload.sectionGuid);
  let variations = sectionToUpdate.get('variations');
  variations = variations.filter(
    variation => variation.get('guid') !== payload.variationGuid,
  );
  variations = toImmutable(
    LayerExperimentFns.redistributeWithNewVariationTraffic(variations.toJS()),
  );
  const updatedSection = sectionToUpdate.set('variations', variations);
  return state.setIn(['sections', sectionIndex], updatedSection);
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 */
function updateVariationInSection(state, payload) {
  const [sectionIndex, sectionToUpdate] = state
    .get('sections')
    .findEntry(section => section.get('guid') === payload.sectionGuid);
  let variations = sectionToUpdate.get('variations');
  const updatedVariationIndex = variations.findIndex(
    variation => variation.get('guid') === payload.variation.guid,
  );
  if (!_.isUndefined(payload.variation.weight)) {
    variations = variations.setIn(
      [updatedVariationIndex, 'weight'],
      payload.variation.weight,
    );
  }
  if (!_.isUndefined(payload.variation.name)) {
    variations = variations.setIn(
      [updatedVariationIndex, 'name'],
      payload.variation.name,
    );
  }
  const updatedSection = sectionToUpdate.set('variations', variations);
  return state.setIn(['sections', sectionIndex], updatedSection);
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 */
function updateSectionName(state, payload) {
  const [sectionIndex, sectionToUpdate] = state
    .get('sections')
    .findEntry(section => section.get('guid') === payload.sectionGuid);
  const { sectionName } = payload;
  const updatedSection = sectionToUpdate.set('name', sectionName);
  return state.setIn(['sections', sectionIndex], updatedSection);
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 */
function updateCurrentlySelectedTrafficAllocationPolicy(state, payload) {
  return state.set('currentlySelectedTrafficAllocationPolicy', payload);
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 */
function updateCurrentlySelectedDistributionGoal(state, payload) {
  return state.set('currentlySelectedDistributionGoal', payload);
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 */
function updateisAutomaticDistributionGoal(state, payload) {
  return state.set('isAutomaticDistributionGoal', payload);
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 */
function updateExploitationRate(state, payload) {
  return state.set('exploitationRate', payload);
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 */
function setErrors(state, payload) {
  return state.mergeIn(['errors'], payload);
}

/**
 * Update an individual property on the variation with the given GUID. If no
 * such variation exists, return the existing state with no change.
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {String} payload.propertyName Name of property to update
 * @param {String} payload.propertyValue New value for property
 * @param {String} payload.variationGUID GUID of variation to update
 * @return {Immutable.Map}
 */
function updateVariationProperty(state, payload) {
  const { propertyName, propertyValue, variationGUID } = payload;
  return state.update('variations', variations => {
    const indexOfUpdatedVariation = variations.findIndex(
      variation => variation.get('guid') === variationGUID,
    );
    if (indexOfUpdatedVariation > -1) {
      return variations.setIn(
        [indexOfUpdatedVariation, propertyName],
        propertyValue,
      );
    }
    return variations;
  });
}
