import { fns as MetricFns } from 'optly/modules/entity/metric';

export const getFullMetricDescription = metricWrapper => {
  if (!metricWrapper) {
    return '';
  }

  const metric = metricWrapper.get('metric');
  const metricDescription = metricWrapper.get('description');

  if (MetricFns.isMetricOverallRevenue(metric)) {
    return metricDescription;
  }

  const metricEvent = metricWrapper.get('eventName', metric.get('eventName'));
  return tr('{0} for {1} event', metricDescription, metricEvent);
};

export default {
  getFullMetricDescription,
};
