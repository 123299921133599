import React from 'react';

import flux from 'core/flux';
import Router from 'core/router';

import CampaignManagerEnums from 'bundles/p13n/modules/campaign_manager/enums';
import CurrentLayerGetters from 'bundles/p13n/modules/current_layer/getters';
import BundleSplitHelper from 'optly/utils/bundle_split_helper';
import NavConstants from 'optly/services/navigation';
import LayerGetters from 'optly/modules/entity/layer/getters';
import LayerExperimentGetters from 'optly/modules/entity/layer_experiment/getters';
import UrlHelper from 'optly/services/url_helper';
import PerformanceTrackingActions from 'optly/modules/performance_tracking/actions';

import { withSidebarAndManagerChangeHistory } from 'bundles/p13n/components/change_history';

import Sidebar from './components/sidebar';

import CampaignManagerRoutingFns from './routing_fns';

import RoutingFns from '../../routing_fns';

const category = 'Manage Campaign';

/*
 * Routing function that ensures the Campaign Overview dynamic bundle has been fetched
 * We store the returned dynamic bundle module on the function object itself
 */

function CampaignOverviewSection(ctx, next) {
  import(
    /* webpackChunkName: "campaign-overview-bundle" */
    'bundles/p13n/sections/campaign_overview' // eslint-disable-line
  )
    .then(module => {
      // use the function object itself as the section module import. We copy the module properties onto the function
      Object.assign(CampaignOverviewSection, module);
    })
    .then(next);
}

function P13nLegacyCampaignEditorComponent(ctx, next) {
  import(
    /* webpackChunkName: "campaign-overview-bundle" */
    'bundles/p13n/components/p13n_campaign_editor_legacy' // eslint-disable-line
  )
    .then(module => {
      // use the function object itself as the section module import. We copy the module properties onto the function
      Object.assign(P13nLegacyCampaignEditorComponent, module);
    })
    .then(next);
}

function setPageTitle({ next, name, params }) {
  next();
  let primaryName = '';
  if (params.layer_id) {
    const currentLayer = flux.evaluate(
      LayerGetters.byId(Number(params.layer_id)),
    );
    primaryName = !!currentLayer && currentLayer.get('name');
  }
  RoutingFns.setPageTitle({
    category: !primaryName ? category : '',
    name,
    primaryName,
  });
}

const CampaignOverviewRoutingFns = [
  CampaignOverviewSection,
  P13nLegacyCampaignEditorComponent,
];

/*
 * Routes for the campaign overview section in nuclear-router format
 */
const routes = [
  // Temporary redirect for old p13n route
  {
    match: '/v2/projects/:proj_id/campaign_overview/:layer_id/*',
    handle: [
      ctx => {
        const campaignHomeUrl = UrlHelper.campaignHome(
          ctx.params.proj_id,
          ctx.params.layer_id,
        );
        Router.redirect(campaignHomeUrl);
      },
    ],
  },

  {
    match: '/v2/projects/:proj_id/campaigns/:layer_id',
    metadata: { name: 'Experiences', category },
    handle: [
      [
        ...CampaignOverviewRoutingFns,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseProjectId,
      ],
      RoutingFns.disallowCustomProjects,
      (ctx, next) =>
        CampaignOverviewSection.pages.experiments.routingSetup(ctx, next),
      (ctx, next) => CampaignOverviewSection.outboundRedirectCheck(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Experiences',
          params,
        }),
      () => {
        PerformanceTrackingActions.setFrontendRenderStartTime();
        if (flux.evaluate(CurrentLayerGetters.canViewVariationsInP13N)) {
          RoutingFns.renderMainRegion(
            <CampaignOverviewSection.pages.experiments.component />,
          );
        } else {
          RoutingFns.renderMainRegion(P13nLegacyCampaignEditorComponent);
        }
      },
    ],
  },

  {
    match:
      '/v2/projects/:proj_id/campaigns/:layer_id/experiments/:experiment_id',
    handle: [
      [
        ...CampaignOverviewRoutingFns,
        ...RoutingFns.standardNavHandlers(NavConstants.NavWidth.COLLAPSED),
        RoutingFns.parseProjectId,
      ],
      RoutingFns.disallowCustomProjects,
      (ctx, next) =>
        CampaignOverviewSection.pages.experiments.routingSetup(ctx, next),
      ctx => {
        const experiment = flux.evaluateToJS(
          LayerExperimentGetters.byId(Number(ctx.params.experiment_id)),
        );
        const firstVariationId = experiment.variations[0].variation_id;
        Router.redirect(
          UrlHelper.campaignEditor(
            ctx.params.proj_id,
            ctx.params.layer_id,
            ctx.params.experiment_id,
            firstVariationId,
          ),
        );
      },
    ],
  },

  {
    match:
      '/v2/projects/:proj_id/campaigns/:layer_id/experiments/:experiment_id/variations/:variation_id',
    metadata: { name: 'Variation Editor', category },
    handle: [
      [
        ...CampaignOverviewRoutingFns,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseProjectId,
      ],
      RoutingFns.disallowCustomProjects,
      RoutingFns.fetchProjectPlugins,
      (ctx, next) =>
        CampaignOverviewSection.pages.experiments.routingSetup(ctx, next),
      (ctx, next) => CampaignOverviewSection.outboundRedirectCheck(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Variation Editor',
          params,
        }),
      ctx => {
        const editorOptions = {
          variationId: Number(ctx.params.variation_id),
          experimentOrSectionId: Number(ctx.params.experiment_id),
        };
        if (flux.evaluate(CurrentLayerGetters.canViewVariationsInP13N)) {
          RoutingFns.renderEditor(
            <CampaignOverviewSection.pages.experiments.component
              editorOptions={editorOptions}
            />,
            ctx.params.variation_id,
            ctx.params.experiment_id,
          );
        } else {
          RoutingFns.renderEditor(
            P13nLegacyCampaignEditorComponent,
            ctx.params.variation_id,
            ctx.params.experiment_id,
          );
        }
      },
    ],
  },

  {
    match: '/v2/projects/:proj_id/campaigns/:layer_id/integrations',
    metadata: { name: 'Integrations', category },
    handle: [
      [
        ...CampaignOverviewRoutingFns,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseProjectId,
      ],
      RoutingFns.disallowCustomProjects,
      RoutingFns.fetchIntegrationsTabData,
      (ctx, next) =>
        CampaignOverviewSection.pages.integrations.routingSetup(ctx, next),
      (ctx, next) => CampaignOverviewSection.outboundRedirectCheck(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Integrations',
          params,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <CampaignOverviewSection.pages.integrations.component />,
        ),
    ],
  },

  {
    match: '/v2/projects/:proj_id/campaigns/:layer_id/metrics',
    metadata: { name: 'Metrics', category },
    handle: [
      [
        ...CampaignOverviewRoutingFns,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseProjectId,
      ],
      RoutingFns.disallowCustomProjects,
      (ctx, next) =>
        CampaignOverviewSection.pages.metrics.routingSetup(ctx, next),
      (ctx, next) => CampaignOverviewSection.outboundRedirectCheck(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Metrics',
          params,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <CampaignOverviewSection.pages.metrics.component />,
        ),
    ],
  },

  {
    match: '/v2/projects/:proj_id/campaigns/:layer_id/pages',
    metadata: { name: 'Targeting', category },
    handle: [
      [
        ...CampaignOverviewRoutingFns,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseProjectId,
        (ctx, next) =>
          BundleSplitHelper.getCodeLintingBundleModules().then(next),
      ],
      RoutingFns.disallowCustomProjects,
      (ctx, next) =>
        CampaignOverviewSection.pages.pages.routingSetup(ctx, next),
      (ctx, next) => CampaignOverviewSection.outboundRedirectCheck(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Targeting',
          params,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <CampaignOverviewSection.pages.pages.component />,
        ),
    ],
  },

  {
    match: '/v2/projects/:proj_id/campaigns/:layer_id/api_names',
    metadata: { name: 'API Names', category },
    handle: [
      [
        ...CampaignOverviewRoutingFns,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseProjectId,
        (ctx, next) =>
          BundleSplitHelper.getCodeLintingBundleModules().then(next),
      ],
      RoutingFns.disallowCustomProjects,
      (ctx, next) =>
        CampaignOverviewSection.pages.apiNames.routingSetup(ctx, next),
      (ctx, next) => CampaignOverviewSection.outboundRedirectCheck(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'API Names',
          params,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <CampaignOverviewSection.pages.apiNames.component />,
        ),
    ],
  },

  {
    match: '/v2/projects/:proj_id/campaigns/:layer_id/settings',
    metadata: { name: 'Settings', category },
    handle: [
      [
        ...CampaignOverviewRoutingFns,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseProjectId,
      ],
      RoutingFns.disallowCustomProjects,
      (ctx, next) =>
        CampaignOverviewSection.pages.settings.routingSetup(ctx, next),
      (ctx, next) => CampaignOverviewSection.outboundRedirectCheck(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Settings',
          params,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <CampaignOverviewSection.pages.settings.component />,
        ),
    ],
  },

  {
    match: '/v2/projects/:proj_id/campaigns/:layer_id/custom_code',
    metadata: { name: 'Shared Code', category },
    handle: [
      [
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        ...CampaignOverviewRoutingFns,
        RoutingFns.parseProjectId,
        (ctx, next) =>
          BundleSplitHelper.getCodeLintingBundleModules().then(next),
      ],
      RoutingFns.disallowCustomProjects,
      (ctx, next) =>
        CampaignOverviewSection.pages.customCode.routingSetup(ctx, next),
      (ctx, next) => CampaignOverviewSection.outboundRedirectCheck(ctx, next),
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'Shared Code',
          params,
        }),
      () =>
        RoutingFns.renderMainRegion(
          <CampaignOverviewSection.pages.customCode.component />,
        ),
    ],
  },

  {
    match: '/v2/projects/:proj_id/campaigns/:layer_id/history',
    metadata: { name: 'History', category },
    handle: [
      [
        ...CampaignOverviewRoutingFns,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.COLLAPSED,
          NavConstants.NavItems.PERSONALIZATION,
        ),
        RoutingFns.parseProjectId,
      ],
      RoutingFns.disallowCustomProjects,
      ({ params }, next) =>
        CampaignManagerRoutingFns.fetchLayerAndSetAsCurrent(
          params.layer_id,
          next,
        ),
      CampaignManagerRoutingFns.fetchRequiredDataForWebP13nManagerChangeHistory,
      ({ params }, next) =>
        setPageTitle({
          next,
          name: 'History',
          params,
        }),
      // ctx.fetchedData is set within RoutingFns.fetchRequiredDataForWebManagerChangeHistory
      ({ fetchedData }) => {
        // Define within route since the componentId needs to change for renderMainRegion to remount the component
        const ManagerChangeHistory = withSidebarAndManagerChangeHistory(
          Sidebar,
        );
        RoutingFns.renderMainRegion(
          <ManagerChangeHistory
            fetchedEntities={fetchedData}
            sidebarProps={{
              activeTab: CampaignManagerEnums.tabs.HISTORY,
            }}
          />,
        );
      },
    ],
  },
];

export default routes;
