/**
 * Navigation Constants
 * These are shared between the NAVBAR and several places
 * in the P13N routing functions, which require them at import time.
 *
 * TODO - Ensure new SCS consumers consume these via the NAVBAR
 */
import keyMirror from 'optly/utils/key_mirror';

/**
 * Possible active tabs for the global navbar.
 */
export const LinkActionTypes = keyMirror({
  PUSH_STATE_HREF: null,
  LINK_HREF: null,
  FUNCTION: null,
});

export const NavItems = keyMirror({
  ABTESTING: null,
  PERSONALIZATION: null,
  AUDIENCES: null,
  FLAGS: null,
  FEATURES: null,
  FEATURESOLD: null,
  ONBOARDING: null,
  VARIABLES: null,
  PAGES: null,
  OASIS: null,
  OPTIONS: null,
  ACCOUNT: null,
  PROFILE: null,
  REPORTS: null,
  ROLLOUTS: null,
  HISTORY: null,
  SETTINGS: null,
  TODOS: null, // Internal only - See #opp on Slack
});

export const NavWidth = keyMirror({
  COLLAPSED: null,
  HIDDEN: null,
  OPEN: null,
});

export const AudienceTabs = keyMirror({
  ATTRIBUTE_DASHBOARD: null,
  AUDIENCE_DASHBOARD: null,
});

export const DataLayerTabs = keyMirror({
  CATALOGS_DASHBOARD_TAB: null,
  RECOMMENDERS_DASHBOARD_TAB: null,
  EVENT_TAB: null,
  INSPECTOR_TAB: null,
  PLUGIN_TAB: null,
  VIEW_TAB: null,
  METRIC_TAB: null,
});

export const DataLayerPaths = {
  catalogs: DataLayerTabs.CATALOGS_DASHBOARD_TAB,
  recommenders: DataLayerTabs.RECOMMENDERS_DASHBOARD_TAB,
  events: DataLayerTabs.EVENT_TAB,
  inspector: DataLayerTabs.INSPECTOR_TAB,
  plugins: DataLayerTabs.PLUGIN_TAB,
  pages: DataLayerTabs.VIEW_TAB,
  metrics: DataLayerTabs.METRIC_TAB,
};

export const FeaturesTabs = keyMirror({
  FEATURES_DASHBOARD: null,
});

export const LayersTabs = keyMirror({
  GROUPS: null,
  PERSONALIZATIONS: null,
  EXPERIMENTS: null,
  LAYERS: null,
});

export const OasisTabs = keyMirror({
  EXPERIMENTS: null,
  GROUPS: null,
});

export const SettingsTabs = keyMirror({
  ADVANCED_TAB: null,
  COLLABORATORS_TAB: null,
  IMPLEMENTATION_TAB: null,
  INTEGRATIONS_TAB: null,
  JAVASCRIPT_TAB: null,
  LABS_TAB: null,
  MIGRATION_TAB: null,
  WEBHOOKS_TAB: null,
});

export const AccountTabs = keyMirror({
  BILLING_TAB: null,
  DCP_SERVICE_TAB: null,
  OPTIMIZELY_CLASSIC_TAB: null,
  PLAN_BILLING_TAB: null,
  USAGE_TAB: null,
  PLAN_TAB: null,
  PROJECTS_TAB: null,
  REGISTERED_APPS_TAB: null,
  SECURITY_TAB: null,
  SNIPPETS_TAB: null,
  SUBJECT_ACCESS_REQUESTS_TAB: null,
  USERS_TAB: null,
  TEAMS_TAB: null,
});

export const ProfileTabs = keyMirror({
  PREFERENCES_TAB: null,
  NOTIFICATIONS_TAB: null,
  API_ACCESS_TAB: null,
  API_TOKENS_V1_TAB: null,
});

export default {
  LinkActionTypes,
  NavItems,
  NavWidth,
  AudienceTabs,
  DataLayerPaths,
  DataLayerTabs,
  FeaturesTabs,
  LayersTabs,
  OasisTabs,
  SettingsTabs,
  AccountTabs,
  ProfileTabs,
};
