/**
 * THIS IS THE LEGACY URL HELPERS FILE.
 * See ./url_helpers for the currently maintained file.
 */
import config from 'atomic-config';

import UrlUtil from 'optly/utils/url';
import uri from 'optly/utils/uri';
import domain from 'optly/modules/domain';

import NavConstants from 'optly/services/navigation';
import ProjectEnums from 'optly/modules/entity/project/enums';

import UrlHelperLegacy from './url_helper_legacy';

/**
 * Returns a project url (v1 or X) from provided product details
 * @param {object} projectConfig
 * @param {number} projectConfig.projectId
 * @param {string} projectConfig.projectPlatform
 * @param {number} projectConfig.manifestVersion - defaults to manifestVersions.V2 (optional)
 * @param {string} projectConfig.activeNavItem (optional)
 * @return {string}
 */
export function projectUrl(projectConfig) {
  const {
    projectId,
    projectPlatform,
    manifestVersion,
    activeNavItem,
  } = Object.assign(
    { manifestVersion: ProjectEnums.manifestVersions.V2 },
    projectConfig,
  );

  // Always link to Optimizely Classic for Mobile projects and old Custom Projects
  if (
    projectPlatform === ProjectEnums.project_platforms.ANDROID ||
    projectPlatform === ProjectEnums.project_platforms.IOS ||
    (projectPlatform === ProjectEnums.project_platforms.CUSTOM &&
      manifestVersion !== ProjectEnums.manifestVersions.V2)
  ) {
    return this.v1OptimizelyHome(projectId);
  }

  // Always link to Optimizely X for Full Stack projects
  if (
    projectPlatform === ProjectEnums.project_platforms.CUSTOM &&
    manifestVersion === ProjectEnums.manifestVersions.V2
  ) {
    return this.xProjectHome(projectId, projectPlatform);
  }

  // Give section project link when in Optimizely X
  if (activeNavItem === NavConstants.NavItems.AUDIENCES) {
    return this.audiencesHome(projectId);
  }
  if (activeNavItem === NavConstants.NavItems.PAGES) {
    return this.pagesHome(projectId);
  }
  if (activeNavItem === NavConstants.NavItems.SETTINGS) {
    return this.projectSettingsImplementation(projectId);
  }

  // Otherwise direct to Optimizely X home
  return this.xProjectHome(projectId, projectPlatform);
}

/**
 * Returns a url for X project, depending on project platform
 * @param {number} projectId
 * @param {string} projectPlatform
 * @param {boolean} [isFlagsEnabled] optional
 * @return {string}
 */
export function xProjectHome(projectId, projectPlatform, isFlagsEnabled) {
  if (projectPlatform === ProjectEnums.project_platforms.CUSTOM) {
    return isFlagsEnabled ? flagsHome(projectId) : xFullStackHome(projectId);
  }
  return xWebHome(projectId);
}

/**
 * Returns a url for X Web project
 * @param {string} projectId
 * @return {string}
 */
export function xWebHome(projectId) {
  return `/v2/projects/${projectId}`;
}

/**
 * Root path for X Custom Project (Full Stack) project
 * @param {string} projectId
 * @return {string}
 */
export function xFullStackHome(projectId) {
  return `/v2/projects/${projectId}/experiments`;
}

// Used in Experiments / Features Dashboard view
export function projectChangeHistory(projectId) {
  return `/v2/projects/${projectId}/changes`;
}

export function projectHistory(projectId) {
  return `/v2/projects/${projectId}/history`;
}

export function changeHistoryLog(currentLocationPathname, changeId) {
  return UrlUtil.optimizelyHRDUrl(`${currentLocationPathname}?id=${changeId}`);
}

export function experiments(projectId) {
  return `/v2/projects/${projectId}/experiment_list`;
}

export function personalizations(projectId) {
  return `/v2/projects/${projectId}/personalizations`;
}

export function groups(projectId) {
  return `/v2/projects/${projectId}/groups`;
}

export function pagesHome(projectId) {
  return `/v2/projects/${projectId}/implementation/pages`;
}

export function eventsHome(projectId) {
  return `/v2/projects/${projectId}/implementation/events`;
}

export function eventsEdit(projectId, eventId, eventType) {
  return `/v2/projects/${projectId}/implementation/events/${eventId}/${eventType}`;
}

export function metricsHome(projectId) {
  return `/v2/projects/${projectId}/implementation/metrics`;
}

export function extensionsHome(projectId) {
  return `/v2/projects/${projectId}/implementation/extensions`;
}

export function eventInspector(projectId) {
  return `/v2/projects/${projectId}/implementation/inspector`;
}

/**
 * Returns a url for the X Audiences home
 * @param {number} projectId
 * @return {string}
 */
export function audiencesHome(projectId) {
  return `/v2/projects/${projectId}/audiences`;
}

/**
 * Returns a url for editing the X Audiences
 * @param {number} projectId
 * @param {number} audienceId
 * @return {string}
 */
export function audiencesEdit(projectId, audienceId) {
  return `/v2/projects/${projectId}/audiences/${audienceId}/`;
}

/**
 * Returns the url for plugin editing
 *
 * @param {number} projectId
 * @param {number} pluginId
 * @return {string}
 */
export function extensionsEdit(projectId, pluginId) {
  return `/v2/projects/${projectId}/extensions/${pluginId}`;
}

export function projectIntegrationSettings(projectId) {
  return `/v2/projects/${projectId}/settings/integrations`;
}

export function customIntegrationEditor(projectId, pluginId) {
  return `/v2/projects/${projectId}/settings/integrations/${pluginId}`;
}

export function campaignHome(projectId, layerId) {
  return `/v2/projects/${projectId}/campaigns/${layerId}`;
}

export function campaignIntegrations(projectId, layerId) {
  return `/v2/projects/${projectId}/campaigns/${layerId}/integrations`;
}

export function campaignMetrics(projectId, layerId) {
  return `/v2/projects/${projectId}/campaigns/${layerId}/metrics`;
}

export function campaignPages(projectId, layerId) {
  return `/v2/projects/${projectId}/campaigns/${layerId}/pages`;
}

export function campaignCustomCode(projectId, layerId) {
  return `/v2/projects/${projectId}/campaigns/${layerId}/custom_code`;
}

export function campaignApiNames(projectId, layerId) {
  return `/v2/projects/${projectId}/campaigns/${layerId}/api_names`;
}

export function campaignHistory(projectId, layerId) {
  return `/v2/projects/${projectId}/campaigns/${layerId}/history`;
}

export function campaignSettings(projectId, layerId) {
  return `/v2/projects/${projectId}/campaigns/${layerId}/settings`;
}

export function campaignEditor(projectId, layerId, experimentId, variationId) {
  return `/v2/projects/${projectId}/campaigns/${layerId}/experiments/${experimentId}/variations/${variationId}`;
}

export function experimentApiNames(projectId, experimentId) {
  return `/v2/projects/${projectId}/experiments/${experimentId}/api_names`;
}

export function experimentHome(projectId, experimentId) {
  return `/v2/projects/${projectId}/experiments/${experimentId}`;
}

export function experimentAudiences(projectId, experimentId) {
  return `/v2/projects/${projectId}/experiments/${experimentId}/audiences`;
}

export function experimentHistory(projectId, experimentId) {
  return `/v2/projects/${projectId}/experiments/${experimentId}/history`;
}

export function experimentPages(projectId, experimentId) {
  return `/v2/projects/${projectId}/experiments/${experimentId}/pages`;
}

export function experimentIntegrations(projectId, experimentId) {
  return `/v2/projects/${projectId}/experiments/${experimentId}/integrations`;
}

export function experimentMetrics(projectId, experimentId) {
  return `/v2/projects/${projectId}/experiments/${experimentId}/metrics`;
}

export function experimentSchedule(projectId, experimentId) {
  return `/v2/projects/${projectId}/experiments/${experimentId}/schedule`;
}

export function experimentSharedCode(projectId, experimentId) {
  return `/v2/projects/${projectId}/experiments/${experimentId}/shared_code`;
}

export function experimentSettings(projectId, experimentId) {
  return `/v2/projects/${projectId}/experiments/${experimentId}/settings`;
}

export function experimentTrafficAllocation(projectId, experimentId) {
  return `/v2/projects/${projectId}/experiments/${experimentId}/traffic_allocation`;
}

export function experimentVariations(projectId, experimentId) {
  return `/v2/projects/${projectId}/experiments/${experimentId}/variations`;
}

export function experimentEditor(projectId, experimentId, variationId) {
  return `/v2/projects/${projectId}/experiments/${experimentId}/variations/${variationId}`;
}

export function v1OptimizelyHome(projectId) {
  return UrlUtil.optimizelyHRDUrl(`/projects/${projectId}`);
}

export function accountSettings() {
  return UrlUtil.optimizelyHRDUrl('/v2/accountsettings');
}

export function profileSettings() {
  return UrlUtil.optimizelyHRDUrl('/v2/profile');
}

export function projectSettings(projectId) {
  return `/v2/projects/${projectId}/settings`;
}

export function projectSettingsAdvanced(projectId) {
  return `/v2/projects/${projectId}/settings/advanced`;
}

export function projectSettingsCollaborators(projectId) {
  return `/v2/projects/${projectId}/settings/collaborators`;
}

export function projectSettingsImplementation(projectId) {
  return `/v2/projects/${projectId}/settings/implementation`;
}

export function projectSettingsIntegrations(projectId, integrationId) {
  let url = `/v2/projects/${projectId}/settings/integrations`;
  if (integrationId) {
    url += `?integration_id=${integrationId}`;
  }
  return url;
}

export function projectSettingsJavascript(projectId) {
  return `/v2/projects/${projectId}/settings/javascript`;
}

export function projectSettingsLabs(projectId) {
  return `/v2/projects/${projectId}/settings/labs`;
}

export function projectSettingsWebhooks(projectId) {
  return `/v2/projects/${projectId}/settings/webhooks`;
}

export function projectSettingsMigration(projectId) {
  return `/v2/projects/${projectId}/settings/migration`;
}

/**
 * Routes used for Multivariate Tests.
 */
export function mvtHome(projectId, experimentId) {
  return `/v2/projects/${projectId}/multivariate/${experimentId}`;
}

export function mvtSectionsDashboard(projectId, experimentId) {
  return `/v2/projects/${projectId}/multivariate/${experimentId}/sections`;
}

export function mvtCombinationsDashboard(projectId, experimentId) {
  return `/v2/projects/${projectId}/multivariate/${experimentId}/combinations`;
}

export function mvtExperimentEditor(
  projectId,
  experimentId,
  sectionId,
  variationId,
) {
  return `/v2/projects/${projectId}/multivariate/${experimentId}/sections/${sectionId}/variations/${variationId}`;
}

export function mvtPages(projectId, experimentId) {
  return `/v2/projects/${projectId}/multivariate/${experimentId}/pages`;
}

export function mvtAudiences(projectId, experimentId) {
  return `/v2/projects/${projectId}/multivariate/${experimentId}/audiences`;
}

export function mvtTrafficAllocation(projectId, experimentId) {
  return `/v2/projects/${projectId}/multivariate/${experimentId}/traffic_allocation`;
}

export function mvtIntegrations(projectId, experimentId) {
  return `/v2/projects/${projectId}/multivariate/${experimentId}/integrations`;
}

export function mvtMetrics(projectId, experimentId) {
  return `/v2/projects/${projectId}/multivariate/${experimentId}/metrics`;
}

export function mvtCustomCode(projectId, experimentId) {
  return `/v2/projects/${projectId}/multivariate/${experimentId}/custom_code`;
}

export function mvtSchedule(projectId, experimentId) {
  return `/v2/projects/${projectId}/multivariate/${experimentId}/schedule`;
}

export function mvtApiNames(projectId, experimentId) {
  return `/v2/projects/${projectId}/multivariate/${experimentId}/api_names`;
}

export function mvtHistory(projectId, experimentId) {
  return `/v2/projects/${projectId}/multivariate/${experimentId}/history`;
}

export function mvtSettings(projectId, experimentId) {
  return `/v2/projects/${projectId}/multivariate/${experimentId}/settings`;
}

/**
 * Returns the hostname for teams
 * @returns {String}
 */
export function getTeamsHostname() {
  return `https://teams.optimizely.${domain.getSuffix()}`;
}

/**
 * Returns url to Teams in Program Management
 * @param {number} currentAccountId
 * @returns {string}
 */
export function programManagementTeams(currentAccountId) {
  const hostname = getTeamsHostname();
  const path = '/social-auth/login/optimizely/';
  return `${hostname}${path}?account_id=${currentAccountId}&next=/accounts/${currentAccountId}`;
}

/**
 * Returns a url for layer results.
 * @param {number} projectId
 * @param {number} layerId
 * @param {object} params optional query parameters
 * @return {string}
 */
export function layerResults(projectId, layerId, params) {
  const urlBase = `/v2/projects/${projectId}/results/${layerId}`;
  const url = uri.create(urlBase);
  if (params) {
    url.param(params);
  }
  return url.toString();
}

/**
 * Returns a url for a specific experiments results page
 *
 * @param {number} projectId
 * @param {number} layerId
 * @param {number} experimentId
 * @param {object} params optional query parameters
 * @return {string}
 */
export function layerExperimentResults(
  projectId,
  layerId,
  experimentId,
  params,
) {
  const urlBase = `/v2/projects/${projectId}/results/${layerId}/experiments/${experimentId}`;
  const url = uri.create(urlBase);
  if (params) {
    url.param(params);
  }
  const useEncoding = params.share_token === undefined;
  return url.toString(useEncoding);
}

/**
 * Returns a url for a specific experiments results page
 *
 * @param {number} projectId
 * @param {number} experimentId
 * @param {object} params optional query parameters
 * @return {string}
 */
export function experimentResults(projectId, experimentId, params) {
  const urlBase = `/v2/projects/${projectId}/results/experiments/${experimentId}`;
  const url = uri.create(urlBase);
  if (params) {
    url.param(params);
  }
  return url.toString();
}

/**
 * Returns a url for a specific mvt rollup results page
 * @param {number} projectId
 * @param {number} experimentId
 * @param {number} sectionId
 * @param {object} params
 * @returns {string}
 */
export function mvtRollupResults(projectId, experimentId, sectionId, params) {
  const urlBase = `/v2/projects/${projectId}/results/experiments/${experimentId}/sections/${sectionId}`;
  const url = uri.create(urlBase);
  if (params) {
    url.param(params);
  }
  return url.toString();
}

/**
 * URL for Oasis Rollouts Dashboard
 * @param {string} projectId
 * @return {string}
 */
export function rolloutsHome(projectId) {
  return `/v2/projects/${projectId}/rollouts`;
}

/**
 * URL for Oasis Live Variables Dashboard
 * @param {string} projectId
 * @return {string}
 */
export function variablesHome(projectId) {
  return `/v2/projects/${projectId}/live_variables`;
}

/**
 * Routes used for Features.
 */

/**
 * URL for Oasis Old Features Dashboard
 * @param  {string} projectId
 * @return {string}
 */
export function featuresOldHome(projectId) {
  return `/v2/projects/${projectId}/feature_flags`;
}

/**
 * URL for Oasis Features Dashboard
 * @param  {string} projectId
 * @return {string}
 */
export function featuresHome(projectId) {
  return `/v2/projects/${projectId}/features`;
}

/**
 * URL for Flags Dashboard
 * @param  {string} projectId
 * @return {string}
 */
export function flagsHome(projectId) {
  return `/v2/projects/${projectId}/flags`;
}

/**
 * URL for Reports Dashboard
 * @param  {string} projectId
 * @return {string}
 */
export function reportsHome(projectId) {
  return `/v2/projects/${projectId}/reports`;
}

/**
 * URL for Flag ruleset
 * @param  {string} projectId
 * @param  {string} flagKey
 * @param  {string} envKey
 * @param  {string} ruleKey
 * @return {string}
 */
export function flagRule(projectId, flagKey, envKey, ruleKey) {
  return `/v2/projects/${projectId}/flags/manage/${flagKey}/rules/${envKey}/edit/${ruleKey}`;
}

/**
 * URL for editing specific Full Stack Feature Flag via the Feature Dialog or Targeted Rollouts
 * @param  {string} projectId
 * @param  {string} featureId
 * @return {string}
 */
export function editFeature(projectId, featureId) {
  return `/v2/projects/${projectId}/features/${featureId}`;
}

export function featureManagerRules(projectId, featureId) {
  return `/v2/projects/${projectId}/features/${featureId}/rules`;
}

export function featureManagerVariables(projectId, featureId) {
  return `/v2/projects/${projectId}/features/${featureId}/variables`;
}

export function featureManagerExperimentUsage(projectId, featureId) {
  return `/v2/projects/${projectId}/features/${featureId}/experiment-usage`;
}

export function featureManagerHistory(projectId, featureId) {
  return `/v2/projects/${projectId}/features/${featureId}/history`;
}

export function featureManagerSettings(projectId, featureId) {
  return `/v2/projects/${projectId}/features/${featureId}/settings`;
}

export function featureManagerSDKSetup(projectId, featureId) {
  return `/v2/projects/${projectId}/features/${featureId}/sdk-setup`;
}

/**
 * URL for Oasis Onboarding
 * @param  {string} projectId
 * @param  {integer} step
 * @param  {string} language
 * @return {string}
 */
export function fullStackOnboarding(projectId, step = 0, language = 'node') {
  let url = `/v2/projects/${projectId}/onboarding`;
  if (step !== null) {
    url += `?step=${step}`;
  }
  if (language !== null) {
    url += `&language=${language}`;
  }
  return url;
}

/**
 * URL for Projects Dashboard
 * @param {boolean} useFullyQualifiedUrl
 * @return {string}
 */
export function manageProjectsPage(useFullyQualifiedUrl) {
  const manageProjectsUri = '/v2/accountsettings/projects';
  if (!useFullyQualifiedUrl) {
    return manageProjectsUri;
  }
  return UrlUtil.optimizelyHRDUrl(manageProjectsUri);
}

/**
 * URL for Users Dashboard
 * @return {string}
 */
export function manageAccountUsersPage() {
  return '/v2/accountsettings/users';
}

/**
 * URL for Teams Dashboard
 * @return {string}
 */
export function manageAccountTeamsPage() {
  return '/v2/accountsettings/teams';
}

/**
 * URL for Snippet Dashboard
 * @return {string}
 */
export function manageAccountSnippetsPage() {
  return '/v2/accountsettings/snippets';
}

/**
 * URL for Account Plan Dashboard
 * @return {string}
 */
export function manageAccountPlanPage() {
  return '/v2/accountsettings/account/plan';
}

/**
 * URL for Account Billing Dashboard
 * @return {string}
 */
export function manageAccountBillingPage() {
  return '/v2/accountsettings/account/billing';
}

/**
 * URL for Account Plan & Billing Dashboard
 * @return {string}
 */
export function manageAccountPlanBillingPage() {
  return '/v2/accountsettings/account/plan-billing';
}

/**
 * URL for Account Usage Dashboard
 * @return {string}
 */
export function manageAccountPlanUsagePage() {
  return '/v2/accountsettings/account/usage';
}

/**
 * URL for Security and Privacy Dashboard
 * @param {boolean} useFullyQualifiedUrl
 * @return {string}
 */
export function manageAccountSecurityPage(useFullyQualifiedUrl) {
  const accountSecurityUri = '/v2/accountsettings/security';
  if (!useFullyQualifiedUrl) {
    return accountSecurityUri;
  }
  return UrlUtil.optimizelyHRDUrl(accountSecurityUri);
}

/**
 * URL for DCP Services Dashboard
 * @return {string}
 */
export function manageAccountDcpServicesPage() {
  return '/v2/accountsettings/dcp-services';
}

/**
 * URL for Registered Apps Dashboard
 * @return {string}
 */
export function manageAccountRegisteredAppsPage() {
  return '/v2/accountsettings/registered-apps';
}

/**
 * URL for Optimizely Classic Settings Dashboard
 * @return {string}
 */
export function manageAccountOptimizelyClassicPage() {
  return '/v2/accountsettings/classic';
}

/**
 * URL for Subject Access Requests Dashboard
 * @return {string}
 */
export function manageAccountSubjectAccessRequestsPage() {
  return '/v2/accountsettings/subject-access-requests';
}

/**
 * URL for Profile Preferences
 * @returns {string}
 */
export function profilePreferencesUrl() {
  return '/v2/profile/preferences';
}

/**
 * URL for Profile Notifications
 * @returns {string}
 */
export function profileNotificationsUrl() {
  return '/v2/profile/notifications';
}

/**
 * URL for Profile API access
 * @returns {string}
 */
export function profileAPIAccessUrl() {
  return '/v2/profile/api';
}

/**
 * URL for Profile API Tokens V1
 * @returns {string}
 */
export function profileAPITokensV1Url() {
  return '/v2/profile/api_tokens_v1';
}

/**
 * URL for export results as a CSV
 * @param {string} type
 * @param {string} contentId
 * @param {string} startTime
 * @param {string} endTime
 * @param {string} baselineVariationId
 * @param {string} segmentConditions
 * @return {string}
 */
export function resultsCSV({
  type,
  contentId,
  startTime,
  endTime,
  baselineVariationId,
  segmentConditions,
}) {
  let queryString = `start_time=${startTime}&end_time=${endTime}`;

  if (baselineVariationId) {
    queryString += `&baseline_variation_id=${baselineVariationId}`;
  }

  if (segmentConditions) {
    queryString += `&segment_conditions=${segmentConditions}`;
  }

  // get this hostname from the python layer, remove the protocol to allow the URL to be used as a download link with //
  const frontDoorHost = !__TEST__
    ? config.get('env.PUBLIC_API_HOST_CSV').replace('https://', '')
    : 'https://testapi.optimizely.com';

  // return null if the URL isn't set = safety mode - this will disable the button
  return frontDoorHost
    ? `//${frontDoorHost}/v2/export/${type}/${contentId}/results/csv?${queryString}`
    : null;
}

/**
 * URL to sign out and land on Optimizely X
 * The signout page is a v1 path, so we want to redirect the user to /v2/ when logging in again
 * @return {string}
 */
export function signout() {
  return '/account/signout?landing_page=/v2';
}

/**
 * URL for Optimizely X welcome page
 * @return {string}
 */
export function welcomePage() {
  return '/v2/welcome';
}

/**
 * FULL STACK UI MANAGER START
 */

export function fullStackManagerVariations(projectId, experimentId) {
  return `/v2/projects/${projectId}/experiments/${experimentId}/variations`;
}

export function fullStackManagerAudiences(projectId, experimentId) {
  return `/v2/projects/${projectId}/experiments/${experimentId}/audiences`;
}

export function fullStackManagerMetrics(projectId, experimentId) {
  return `/v2/projects/${projectId}/experiments/${experimentId}/metrics`;
}

export function fullStackManagerTrafficAllocation(projectId, experimentId) {
  return `/v2/projects/${projectId}/experiments/${experimentId}/traffic_allocation`;
}

export function fullStackManagerWhitelist(projectId, experimentId) {
  return `/v2/projects/${projectId}/experiments/${experimentId}/whitelist`;
}

export function fullStackManagerApiNames(projectId, experimentId) {
  return `/v2/projects/${projectId}/experiments/${experimentId}/api_names`;
}

export function fullStackManagerHistory(projectId, experimentId) {
  return `/v2/projects/${projectId}/experiments/${experimentId}/history`;
}

export function fullStackManagerSettings(projectId, experimentId) {
  return `/v2/projects/${projectId}/experiments/${experimentId}/settings`;
}

/**
 * FULL STACK UI MANAGER END
 */

/**
 * URLs for Recommendations UI (Catalogs and Recommenders)
 */
export function catalogDashboard(projectId) {
  return `/v2/projects/${projectId}/implementation/catalogs`;
}

export function recommenderDashboard(projectId) {
  return `/v2/projects/${projectId}/implementation/recommenders`;
}

/**
 * Helper method to figure out project URL for projects in the project drawer.
 * @param {number} currentProject
 * @param {string} activeNavItem
 * @return {string}
 */
export function projectDrawerRouter(currentProject, activeNavItem) {
  const projectId = currentProject.get('id');
  const projectPlatform = currentProject.getIn(['project_platforms', 0]);
  const manifestVersion = currentProject.get('manifest_version');
  const isFlagsEnabled = currentProject.get('is_flags_enabled');
  // Always link to Optimizely Classic for Mobile projects and old Custom Projects
  if (
    projectPlatform === ProjectEnums.project_platforms.ANDROID ||
    projectPlatform === ProjectEnums.project_platforms.IOS ||
    (projectPlatform === ProjectEnums.project_platforms.CUSTOM &&
      manifestVersion !== ProjectEnums.manifestVersions.V2)
  ) {
    return UrlHelperLegacy.dashboardTab(projectId);
  }

  // Always link to the project home for X Full Stack projects
  if (
    projectPlatform === ProjectEnums.project_platforms.CUSTOM &&
    manifestVersion === ProjectEnums.manifestVersions.V2
  ) {
    return xProjectHome(projectId, projectPlatform, isFlagsEnabled);
  }

  // Link to previous page when possible in X Web
  if (activeNavItem === NavConstants.NavItems.AUDIENCES) {
    return audiencesHome(projectId);
  }
  if (activeNavItem === NavConstants.NavItems.PAGES) {
    return UrlHelperLegacy.dataLayerTab(projectId);
  }
  if (activeNavItem === NavConstants.NavItems.SETTINGS) {
    return projectSettingsImplementation(projectId);
  }
  return xProjectHome(projectId, projectPlatform);
}

export default {
  projectUrl,
  xProjectHome,
  xWebHome,
  xFullStackHome,
  fullStackOnboarding,
  projectHistory,
  changeHistoryLog,
  experiments,
  personalizations,
  groups,
  pagesHome,
  eventInspector,
  eventsHome,
  eventsEdit,
  metricsHome,
  extensionsHome,
  audiencesHome,
  audiencesEdit,
  extensionsEdit,
  projectIntegrationSettings,
  customIntegrationEditor,
  campaignHome,
  campaignIntegrations,
  campaignMetrics,
  campaignPages,
  campaignCustomCode,
  campaignApiNames,
  campaignHistory,
  campaignSettings,
  campaignEditor,
  experimentApiNames,
  experimentAudiences,
  experimentHistory,
  experimentHome,
  experimentPages,
  experimentIntegrations,
  experimentMetrics,
  experimentSchedule,
  experimentSettings,
  experimentSharedCode,
  experimentTrafficAllocation,
  experimentVariations,
  experimentEditor,
  v1OptimizelyHome,
  accountSettings,
  profileSettings,
  projectSettings,
  projectSettingsAdvanced,
  projectSettingsCollaborators,
  projectSettingsImplementation,
  projectSettingsIntegrations,
  projectSettingsJavascript,
  projectSettingsLabs,
  projectSettingsWebhooks,
  projectSettingsMigration,
  mvtHome,
  mvtSectionsDashboard,
  mvtCombinationsDashboard,
  mvtExperimentEditor,
  mvtPages,
  mvtAudiences,
  mvtTrafficAllocation,
  mvtIntegrations,
  mvtMetrics,
  mvtCustomCode,
  mvtSchedule,
  mvtApiNames,
  mvtHistory,
  mvtSettings,
  getTeamsHostname,
  programManagementTeams,
  layerResults,
  layerExperimentResults,
  experimentResults,
  mvtRollupResults,
  rolloutsHome,
  variablesHome,
  featuresOldHome,
  featuresHome,
  flagsHome,
  editFeature,
  featureManagerRules,
  featureManagerVariables,
  featureManagerExperimentUsage,
  featureManagerHistory,
  featureManagerSettings,
  featureManagerSDKSetup,
  manageProjectsPage,
  manageAccountSnippetsPage,
  manageAccountPlanPage,
  manageAccountBillingPage,
  manageAccountPlanBillingPage,
  manageAccountPlanUsagePage,
  manageAccountSecurityPage,
  manageAccountDcpServicesPage,
  manageAccountRegisteredAppsPage,
  manageAccountOptimizelyClassicPage,
  manageAccountSubjectAccessRequestsPage,
  profilePreferencesUrl,
  profileAPIAccessUrl,
  reportsHome,
  flagRule,
  resultsCSV,
  signout,
  welcomePage,
  fullStackManagerVariations,
  fullStackManagerAudiences,
  fullStackManagerMetrics,
  fullStackManagerTrafficAllocation,
  fullStackManagerWhitelist,
  fullStackManagerApiNames,
  fullStackManagerHistory,
  fullStackManagerSettings,
  catalogDashboard,
  recommenderDashboard,
  projectDrawerRouter,
};
