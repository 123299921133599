import Nuclear from 'nuclear-js';

import { toImmutable } from 'optly/immutable';

import actionTypes from '../action_types';

const setAndOrSegmentCondition = (state, payload) =>
  state.set('andOrSegmentCondition', payload.andOrSegmentCondition);

const setSegments = (state, payload) => state.set('segments', payload.segments);

const reset = state => {
  return state
    .set('segments', toImmutable([]))
    .set('andOrSegmentCondition', null);
};

export default Nuclear.Store({
  getInitialState() {
    return toImmutable({
      andOrSegmentCondition: null,
      segments: [],
    });
  },

  initialize() {
    this.on(actionTypes.SEGMENT_DISPLAY_RESET, reset);
    this.on(
      actionTypes.SEGMENT_DISPLAY_SET_AND_OR_CONDITION,
      setAndOrSegmentCondition,
    );
    this.on(actionTypes.SEGMENT_DISPLAY_SET_SEGMENTS, setSegments);
  },
});
