import React from 'react';

// Core
import flux from 'core/flux';
import { error } from 'core/ui';

// Modules
import { getters as AdminAccountGetters } from 'optly/modules/admin_account';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import { fns as ProjectPermissionsFns } from 'optly/modules/permissions';
import { actions as SupportActions } from 'optly/modules/support';
import NavConstants from 'optly/services/navigation';
import FilterableTableEnums from 'optly/modules/filterable_table/enums';
import BundleSplitHelper from 'optly/utils/bundle_split_helper';

// Section routes
import ABExperimentEditorSectionRoutes from 'bundles/p13n/sections/ab_experiment_editor/routes';
import AccountDashboardSectionRoutes from 'bundles/p13n/sections/account_dashboard/routes';
import AudiencesSectionRoutes from 'bundles/p13n/sections/audiences/routes';
import CampaignOverviewSectionRoutes from 'bundles/p13n/sections/campaign_overview/routes';
import ChangeHistorySectionRoutes from 'bundles/p13n/sections/change_history/routes';
import FeaturesSectionRoutes from 'bundles/p13n/sections/features/routes';
import FullStackOnboardingSectionRoutes from 'bundles/p13n/sections/full_stack_onboarding/routes';
import ImplementationSectionRoutes from 'bundles/p13n/sections/implementation/routes';
import ResultsSectionRoutes from 'bundles/p13n/sections/results/routes';
import PluginsSectionRoutes from 'bundles/p13n/sections/plugin_builder/routes';
import ProjectSettingsSectionRoutes from 'bundles/p13n/sections/project_settings/routes';
import ProfileSectionRoutes from 'bundles/p13n/sections/profile/routes';
import ManagerMVTSectionRoutes from 'bundles/p13n/sections/manager_mvt/routes';
import ManagerFeatureSectionRoutes from 'bundles/p13n/sections/manager_feature/routes';
import ManagerFullstackSectionRoutes from 'bundles/p13n/sections/manager_fullstack/routes';
import OasisExperimentManagerSectionRoutes from 'bundles/p13n/sections/oasis_experiment_manager/routes';
import RolloutsSectionRoutes from 'bundles/p13n/sections/rollouts/routes';
import LiveVariablesSectionRoutes from 'bundles/p13n/sections/live_variables/routes';
import ViewsSectionRoutes from 'bundles/p13n/sections/views/routes';
import WelcomeSectionRoutes from 'bundles/p13n/sections/welcome/routes';
import LayersSectionRoutes from 'bundles/p13n/sections/layers/routes';
import TryRolloutsRoutes from 'bundles/p13n/sections/try_rollouts/routes';
import MetricsRoutes from 'bundles/p13n/sections/metrics/routes';

// Sections
import FeaturesOldSection from './sections/features_old';
import RoutingFns from './routing_fns';
import EventsDashboard from './sections/web_events/pages/dashboard';

let OasisImplementationSection;
const EnsureOasisBundle = (ctx, next) => {
  BundleSplitHelper.getOasisBundleModules()
    .then(modules => {
      ({ OasisImplementationSection } = modules);
    })
    .then(next);
};

export default [
  {
    match: '/v2/(projects)?',
    handle: [
      RoutingFns.parseProjectId,
      ctx => {
        /**
         * Show "Something went wrong" error dialog if "something_went_wrong" query param has a value of "true".
         * Optionally allow for backend exception ID to be presented and used in Sentry log. Examples:
         * - https://app.optimizely.com/v2?something_went_wrong=true
         * - https://app.optimizely.com/v2?something_went_wrong=true&eid=ABCD-1234
         */
        const { something_went_wrong, eid } = ctx.queryParams;
        if (something_went_wrong === 'true') {
          error({
            ...(eid && { errorId: eid }),
          });
        }

        const isCustomProject = flux.evaluate(
          CurrentProjectGetters.isCustomProject,
        );
        if (!isCustomProject) {
          RoutingFns.redirectToCurrentProjectLayers(ctx);
        } else {
          RoutingFns.redirectToCustomProjectHome(ctx);
        }
      },
    ],
  },

  ...WelcomeSectionRoutes,

  // Redirects to home and launches Support Portal
  {
    match: '/v2/support',
    handle: [
      RoutingFns.parseProjectId,
      ctx => {
        const { eid } = ctx.queryParams;
        SupportActions.showSupportDialog(
          {
            errorId: eid || '',
          },
          {
            onHideDialog: () => {
              const isCustomProject = flux.evaluate(
                CurrentProjectGetters.isCustomProject,
              );
              if (!isCustomProject) {
                RoutingFns.redirectToCurrentProjectLayers();
              } else {
                RoutingFns.redirectToCustomProjectHome();
              }
            },
          },
        );
      },
    ],
  },

  ...TryRolloutsRoutes,

  ...LayersSectionRoutes,

  ...AudiencesSectionRoutes,

  ...ABExperimentEditorSectionRoutes,

  ...CampaignOverviewSectionRoutes,

  ...ChangeHistorySectionRoutes,

  ...ManagerMVTSectionRoutes,

  ...ManagerFeatureSectionRoutes,

  ...ManagerFullstackSectionRoutes,

  ...OasisExperimentManagerSectionRoutes,

  ...FullStackOnboardingSectionRoutes,

  {
    match: '/v2/projects/:proj_id/feature_flags',
    handle: [
      ...RoutingFns.standardNavHandlers(),
      RoutingFns.parseProjectId,
      FeaturesOldSection.pages.featuresDashboard.routingSetup,
      () => {
        // redirect if not a fullstack project
        const isFullStackProject = flux.evaluate(
          CurrentProjectGetters.isFullStackProject,
        );
        if (!isFullStackProject) {
          RoutingFns.redirectToCurrentProjectLayers();
        }

        const canUseFeatureFlags = flux.evaluateToJS([
          AdminAccountGetters.accountPermissions,
          ProjectPermissionsFns.canUseFeatureFlags,
        ]);

        // redirect if user does not have permission
        if (!canUseFeatureFlags) {
          RoutingFns.redirectToCustomProjectHome();
        }

        RoutingFns.renderMainRegion(
          FeaturesOldSection.pages.featuresDashboard.component,
        );
      },
    ],
  },

  ...FeaturesSectionRoutes,

  ...LiveVariablesSectionRoutes,

  ...RolloutsSectionRoutes,

  ...ResultsSectionRoutes,

  ...ImplementationSectionRoutes,

  ...ViewsSectionRoutes,

  ...PluginsSectionRoutes,

  ...ProjectSettingsSectionRoutes,

  ...AccountDashboardSectionRoutes,

  ...ProfileSectionRoutes,

  {
    match:
      '/v2/projects/:proj_id/implementation/events/:event_id?/:event_type?',
    metadata: { name: 'Events', category: 'Implementation' },
    handle: [
      [
        RoutingFns.parseProjectId,
        EnsureOasisBundle,
        ...RoutingFns.standardNavHandlers(
          NavConstants.NavWidth.OPEN,
          NavConstants.NavItems.PAGES,
        ),
      ],
      (ctx, next) => {
        RoutingFns.parseQueryFilters(
          FilterableTableEnums.tableIds.P13N_DATA_LAYER_EVENTS,
        )(ctx, next);
      },
      RoutingFns.fetchEventImplementationData,
      (ctx, next) => {
        OasisImplementationSection.pages.EventsDashboard.routingSetup(
          ctx,
          next,
        );
      },
      (ctx, next) =>
        RoutingFns.setPageTitle({
          next,
          name: 'Events',
          category: 'Implementation',
        }),
      async (ctx, next) => {
        const eventId = Number(ctx.params.event_id);
        const eventType = ctx.params.event_type;
        const isWebProject = await flux.evaluate(
          CurrentProjectGetters.isWebProject,
        );
        const componentToLoad = isWebProject ? (
          <EventsDashboard eventId={eventId} eventType={eventType} />
        ) : (
          <OasisImplementationSection.pages.EventsDashboard.pageComponent />
        );
        RoutingFns.renderMainRegion(componentToLoad);
      },
    ],
  },

  ...MetricsRoutes,

  {
    match: '/v2/projects/:projId/flags/*',
    handle: [
      // catchall no-op
      function() {},
    ],
  },
];
