import {
  EventTypeEnum,
  MetricAggregatorEnum,
  MetricFieldEnum,
  MetricWinningDirectionEnum,
  MetricScopeEnum,
} from './types';

/* eslint-disable camelcase */
export const DefaultMetrics = {
  CUSTOM: {
    aggregator: MetricAggregatorEnum.Unique,
    display_title: undefined,
    display_unit: undefined,
    event_id: undefined,
    event_type: EventTypeEnum.Custom,
    field: undefined,
    scope: MetricScopeEnum.Visitor,
    winning_direction: MetricWinningDirectionEnum.Increasing,
  },
  OVERALL_REVENUE: {
    aggregator: MetricAggregatorEnum.Sum,
    display_title: 'Overall Revenue',
    display_unit: undefined,
    event_id: undefined,
    event_type: undefined,
    field: MetricFieldEnum.Revenue,
    scope: MetricScopeEnum.Visitor,
    winning_direction: MetricWinningDirectionEnum.Increasing,
  },
};
/* eslint-enable camelcase */

// Used to denote a metric field is undefined
export const UNDEFINED = 'UNDEFINED';

/**
 * @description Character length enforced by the backend for all keys
 */
export const MAX_EVENT_KEY_CHARACTER_LENGTH = 64;

export const ReadableMetricsFields = {
  aggregatorAndField: {
    count: {
      [UNDEFINED]: 'total conversions',
    },
    sum: {
      revenue: 'total revenue',
      value: 'total value',
    },
    unique: {
      [UNDEFINED]: 'unique conversions',
    },
  },
  scope: {
    event: 'conversion',
    visitor: 'visitor',
  },
  winningDirection: {
    decreasing: 'Decrease',
    increasing: 'Increase',
  },
};
