const flux = require('core/flux');
const ui = require('core/ui').default;

const Layer = require('optly/modules/entity/layer');
const LayerExperiment = require('optly/modules/entity/layer_experiment');
const { isCMABGroupedExperienceEnabled } = require('optly/utils/features');

const ExperimentPrioritiesDialog = require('../pages/experiments/components/experiment_priorities_dialog')
  .default;

const ExperimentPrioritiesDialogV2 = require('../pages/experiments/components/experiment_priorities_dialog_v2')
  .default;

const getters = require('./getters');

exports.openExperimentPrioritiesDialog = layerId =>
  Layer.actions.fetch(layerId).then(() =>
    ui.showReactDialog(
      isCMABGroupedExperienceEnabled()
        ? ExperimentPrioritiesDialogV2
        : ExperimentPrioritiesDialog,
      {
        dataBindings: {
          data: getters.experimentPriorityDialogData,
        },
      },
      {
        fullScreen: true,
      },
    ),
  );

/**
 * Given the reorganized priority groups from the dialog, add the archived
 * experiment ids that were not given to the dialog, and update the layer's
 * experiment priorities
 * @param {Number} layerId
 * @param {Array} experimentPriorities - An array of arrays, with each sub-array
 * representing a priority level, and containing experiment IDs. This should not
 * include archived experiment IDs
 * @return {Deferred}
 */
exports.saveExperimentPriorities = (layerId, experimentPriorities) => {
  const layer = flux.evaluate(Layer.getters.byId(layerId));
  const experimentsCache = flux.evaluate(LayerExperiment.getters.entityCache);
  const { archivedGroup } = Layer.fns.getPriorityGroupsSplitByStatus(
    layer,
    experimentsCache,
  );
  let newPriorityGroups = experimentPriorities;
  if (archivedGroup.size) {
    newPriorityGroups = newPriorityGroups.concat([archivedGroup.toJS()]);
  }
  return Layer.actions.updateExperimentPriorities(layerId, newPriorityGroups);
};

exports.attemptCreatingNewVariation = (variationName, experimentId) => {
  const experiment = flux.evaluateToJS(
    LayerExperiment.getters.byId(experimentId),
  );
  const validation = LayerExperiment.fns.validateVariationName(
    experiment,
    variationName,
  );
  if (!validation.valid) {
    return {
      isNameValid: false,
      errorMessage: validation.message,
    };
  }
  return {
    isNameValid: true,
    saveDef: LayerExperiment.actions.createNewVariationWithRedistributedTraffic(
      variationName,
      experiment,
    ),
  };
};
