/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';

import { DragAndDrop, Tile, Attention, Dropdown } from 'optimizely-oui';

import truncate from 'optly/filters/truncate';

import { DefaultMetrics } from '../../component_module/constants';
import { getMetricDescription } from '../../component_module/fns';
import ComponentModuleActions from '../../component_module/actions';

import { EventsAndMetricsBuilderProps } from '../../events_and_metrics_builder';
import { Metric } from '../../component_module/types';

export interface ConfigureMetricsProps extends EventsAndMetricsBuilderProps {
  eventEntityCache: Immutable.Map<string, any>;
}

export const ConfigureMetrics = ({
  currentProjectId,
  disablePrimaryMetricEdit,
  eventEntityCache,
  isDisabled,
  onChange,
  selectedMetrics,
}: ConfigureMetricsProps) => {
  const draggableMetrics = selectedMetrics.map((metric, index) => {
    const { aggregator, event_id, field, scope, winning_direction } = metric;
    const eventNameToDisplay = event_id
      ? eventEntityCache.getIn([event_id, 'api_name'])
      : DefaultMetrics.OVERALL_REVENUE.display_title;
    const { description } = getMetricDescription(
      winning_direction,
      aggregator,
      field,
      scope,
      eventNameToDisplay,
    );
    return {
      description,
      id: `${metric.display_title}:${metric.event_id}:${index}`,
      metric,
    };
  });
  const getActionsDropdownItems = (
    index: number,
    selectedMetrics: Metric[],
    metric: any,
  ) => {
    return [
      <>
        <Dropdown.ListItem key={`${index}-edit-action`}>
          <Dropdown.BlockLink
            onClick={() => {
              ComponentModuleActions.showEditMetricSheet({
                currentProjectId,
                metric,
                selectedMetrics,
              }).then(updatedMetric => {
                const updatedSelectedMetrics = [...selectedMetrics];
                updatedSelectedMetrics.splice(index, 1, updatedMetric);
                onChange(updatedSelectedMetrics);
              });
            }}
            testSection="tile-edit">
            <Dropdown.BlockLinkText text="Edit" />
          </Dropdown.BlockLink>
        </Dropdown.ListItem>
        <Dropdown.ListItem key={`${index}-delete-action`}>
          <Dropdown.BlockLink
            onClick={() => {
              const updatedSelectedMetrics = [...selectedMetrics];
              updatedSelectedMetrics.splice(index, 1);
              onChange(updatedSelectedMetrics);
            }}
            testSection="tile-delete">
            <Dropdown.BlockLinkText isDestructive={true} text="Delete" />
          </Dropdown.BlockLink>
        </Dropdown.ListItem>
      </>,
    ];
  };

  return (
    <DragAndDrop
      idForDroppableRegion="metrics-drag-and-drop"
      items={draggableMetrics}
      onDragEnd={updatedSelectedMetrics =>
        onChange(updatedSelectedMetrics.map(({ metric }) => metric))
      }
      renderItem={items => {
        const { index, item } = items;
        const { description, metric } = item;
        const { display_title, event_id } = metric;
        const eventNameToDisplay = event_id
          ? eventEntityCache.getIn([event_id, 'api_name'])
          : DefaultMetrics.OVERALL_REVENUE.display_title;

        const isPrimaryMetricDisabledEdit =
          !!disablePrimaryMetricEdit &&
          selectedMetrics.length > 0 &&
          index === 0;

        const isEnabled = isPrimaryMetricDisabledEdit ? false : !isDisabled;
        const renderName = () => (
          <span style={{ display: 'inline-grid' }}>
            <span
              className="truncate"
              data-test-section="tile-name"
              title={display_title || eventNameToDisplay}>
              {truncate(display_title || eventNameToDisplay, 64)}
            </span>
          </span>
        );

        const renderDescription = () => (
          <span style={{ display: 'inline-grid' }}>
            <span
              className="word-break--word"
              data-test-section="tile-description"
              title={description}>
              {description}
            </span>
          </span>
        );

        return (
          <div className="width--1-1">
            <Tile
              description={renderDescription()}
              {...(isEnabled
                ? {
                    dropdownItems: getActionsDropdownItems(
                      index,
                      selectedMetrics,
                      metric,
                    ),
                  }
                : {})}
              isDraggable={
                !isDisabled &&
                selectedMetrics.length > 1 &&
                !!!disablePrimaryMetricEdit
              }
              key={event_id}
              name={renderName()}
              status={
                selectedMetrics.length > 1 && index === 0 ? 'Primary' : ''
              }
              onTileClick={() => {}}
              testSection={`metric-tile-${event_id || 'overall-revenue'}`}
            />
            {isPrimaryMetricDisabledEdit && (
              <div className="push--top">
                <Attention
                  alignment="left"
                  testSection="attention-metrics"
                  type="brand">
                  <p>
                    Multi-armed bandits only use the primary metric to determine
                    traffic allocation. You can’t edit or remove the primary
                    metric after the optimization has started.
                  </p>
                </Attention>
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

export default ConfigureMetrics;
