import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox } from 'optimizely-oui';

import Immutable from 'optly/immutable';

import ui from 'core/ui';
import OutlierFilterPopover from 'bundles/p13n/components/help_popovers/outlier_filter';
import {
  actions as MetricsManagerModuleActions,
  getters as MetricsManagerModuleGetters,
} from 'bundles/p13n/modules/metrics_manager';

class OutlierFilter extends React.Component {
  static propTypes = {
    layer: PropTypes.instanceOf(Immutable.Map).isRequired,
    outlierFilter: PropTypes.instanceOf(Immutable.Map).isRequired,
  };

  UNSAFE_componentWillMount() {
    const { layer } = this.props;
    if (layer.get('id')) {
      MetricsManagerModuleActions.setOriginalOutlierFilter(layer);
    } else {
      MetricsManagerModuleActions.setDefaultOutlierFilter(layer);
    }
  }

  componentWillUnmount() {
    MetricsManagerModuleActions.setOriginalOutlierFilter();
  }

  updateOutlierFilterEnabledState = () => {
    const { outlierFilter } = this.props;
    const enabled = outlierFilter.get('enabled');

    const updatedOutlierFilter = outlierFilter.set('enabled', !enabled);
    MetricsManagerModuleActions.updateOutlierFilter(updatedOutlierFilter);
  };

  render() {
    const { outlierFilter } = this.props;
    const enabled = outlierFilter.get('enabled');

    return (
      <div className="flex">
        <Checkbox
          checked={enabled}
          label="Enable outlier smoothing for revenue"
          onChange={this.updateOutlierFilterEnabledState}
          data-test-section="outlier-filter-checkbox"
        />
        <OutlierFilterPopover />
      </div>
    );
  }
}

export default ui.connectGetters(OutlierFilter, {
  outlierFilter: MetricsManagerModuleGetters.outlierFilter,
});
