import Template from './list_templates_dialog.html';

const ListTemplatesDialog = {
  replace: true,

  template: Template,

  data: {},

  methods: {},

  afterDestroy() {},

  created() {},

  ready() {},
};

export default ListTemplatesDialog;
