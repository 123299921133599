import React, { useEffect, useState } from 'react';

import {
  Button,
  Col,
  Container,
  Dropdown,
  HelpPopover,
  Link,
  Row,
  SearchPicker,
  Spinner,
  Icon,
} from 'optimizely-oui';

import { brandBlueDark } from '@optimizely/design-tokens/dist/json/colors.json';
import { getFeatureVariableInteger } from '@optimizely/js-sdk-lab/src/actions';

import ui from 'core/ui';

import truncate from 'optly/filters/truncate';
import constants from 'optly/modules/rest_api/constants';

import PublicApiConsumerActions from 'optly/modules/public_api_consumer/actions';
import enums from 'optly/modules/entity/project/enums';

import ComponentModuleActions from '../../component_module/actions';
import {
  computeDefaultMetricFromSearchApiEvent,
  computeSearchApiEventFormatFromEvent,
} from '../../component_module/fns';

import { DefaultMetrics } from '../../component_module/constants';

import {
  Event,
  EventFromSearchApi,
  Metric,
} from '../../component_module/types';

import { EventsAndMetricsBuilderProps } from '../../events_and_metrics_builder';

export interface MetricsSearchPickerProps
  extends EventsAndMetricsBuilderProps {}

/**
 * @description Search audience data for given projectIds
 */
const ITEMS_PER_PAGE = 50;

export const searchByProjectIds = (projectIds: number[]) => {
  return ({ query }: { query: string }): Promise<Event[]> => {
    // For projects with a lot of events, start fetching from page 1
    // ITEMS_PER_PAGE number of events at a time and continues until reaches last page
    return fetchEvents(projectIds, query, 1, []);
  };
};

const fetchEvents = async (
  projectIds: number[],
  query: string,
  page: number,
  collectedData: Event[],
): Promise<Event[]> => {
  try {
    const response = await PublicApiConsumerActions.fetchPage('search', {
      queryParams: {
        type: 'event',
        page,
        per_page: ITEMS_PER_PAGE,
        archived: false,
        projectId: projectIds,
        query,
        ...(query === '' ? { sort: 'created' } : {}),
      },
    });
    if (response.ok) {
      const data = await response.json();
      const newData = collectedData.concat(data);

      if (data.length < ITEMS_PER_PAGE) {
        newData.sort((a, b) =>
          a.name.toUpperCase().localeCompare(b.name.toUpperCase()),
        );
        return newData;
      } else {
        return fetchEvents(projectIds, query, page + 1, newData);
      }
    } else {
      ui.showNotification({
        message: 'There was an error loading Events. Please refresh the page or visit our Help Center if the issue persists.',
        type: 'error',
      });
      // SearchPicker expects an array returned from the searchFunction or it will throw an error
      return collectedData;
    }
  } catch (error: any) {
    ui.showNotification({
      message: error.message,
      type: 'error',
    });
    // SearchPicker expects an array returned from the searchFunction or it will throw an error
    return collectedData;
  }
};

/**
 * @description Render a search-input picker for metrics
 */
const MetricsSearchPicker = ({
  canUseCrossProjectMetrics = false,
  currentProjectId,
  currentProjectStatus,
  onChange,
  projectIds = [],
  projects = [],
  selectedMetrics,
}: MetricsSearchPickerProps) => {
  const [
    isDropdownOpenAndSearchInputFocused,
    setIsDropdownOpenAndSearchInputFocused,
  ] = useState(false);
  const [projectIdsToQuery, setProjectIdsToQuery] = useState([
    currentProjectId,
  ]);
  const [shouldQueryAllProjects, setShouldQueryAllProjects] = useState(false);
  const [createdEvent, setCreatedEvent] = useState(false);
  const [refreshKey, setRefreshKey] = useState(false);

  useEffect(() => {
    setProjectIdsToQuery(
      shouldQueryAllProjects ? [...projectIds] : [currentProjectId],
    );
    setIsDropdownOpenAndSearchInputFocused(true);
  }, [shouldQueryAllProjects]);

  const refreshOnCreate = () => {
    if (createdEvent) setRefreshKey(!refreshKey);
    setCreatedEvent(false);
  };

  const additionalItems = 2;
  const projectsPageSize =
    getFeatureVariableInteger('fetch_all_paginated', 'initial_page_size') ||
    constants.INITIAL_PAGE_SIZE;
  const shouldDisplayProjectNames =
    projects.length && projects.length < projectsPageSize;

  const renderEventDesc = (searchApiEvent: any) => {
    let eventDescription = searchApiEvent.description;
    if (
      shouldDisplayProjectNames &&
      currentProjectStatus === enums.project_status.ACTIVE &&
      projects.length > 0
    ) {
      const project = projects.find(
        (project: { id: number }) => project.id == searchApiEvent.project_id,
      );
      if (project !== undefined) eventDescription = project.project_name;
    }
    return eventDescription;
  };

  const renderAllProjectsHelp = () => {
    return (
      <HelpPopover behavior="hover" popoverTitle="Cross Project Events">
        <p>
        Choosing "all projects" will show events across all projects you have access to in both Web and Feature Experimentation.
        </p>
      </HelpPopover>
    );
  };

  return (
    <SearchPicker
      additionalItems={additionalItems}
      key={`shouldQueryAllProjects=${projectIdsToQuery.join()}-${refreshKey}`}
      onItemSelected={async (index: number, options: any) => {
        // Add support for keyboard list navigation and interacts with hasFauxFocus and the currentFauxFocusIndex render prop
        if (index === 0) {
          const updatedSelectedMetrics: Metric[] = await ComponentModuleActions.addEventFromSearchApiViaEditMetricSheet(
            {
              currentProjectId,
              metric: DefaultMetrics.OVERALL_REVENUE,
              selectedMetrics,
            },
          );
          onChange(updatedSelectedMetrics);
          return;
        }
        if (index === 1) {
          const event = (await ComponentModuleActions.showCreateOrEditEventSheet(
            {
              currentProjectId,
            },
          )) as Event;
          const searchApiEvent = computeSearchApiEventFormatFromEvent(event);
          const updatedSelectedMetrics: Metric[] = await ComponentModuleActions.addEventFromSearchApiViaEditMetricSheet(
            {
              currentProjectId,
              metric: computeDefaultMetricFromSearchApiEvent(searchApiEvent),
              selectedMetrics,
            },
          );
          onChange(updatedSelectedMetrics);
          return;
        }
        const event = options[index - additionalItems];
        const searchApiEvent = computeSearchApiEventFormatFromEvent(event);
        const updatedSelectedMetrics: Metric[] = await ComponentModuleActions.addEventFromSearchApiViaEditMetricSheet(
          {
            currentProjectId,
            metric: computeDefaultMetricFromSearchApiEvent(searchApiEvent),
            selectedMetrics,
          },
        );
        onChange(updatedSelectedMetrics);
      }}
      searchFunction={searchByProjectIds(projectIdsToQuery)}
      supportedTypes={['event']}>
      {({
        availableEntities,
        currentFauxFocusIndex,
        isLoading,
        renderInput,
      }: {
        availableEntities: EventFromSearchApi[];
        currentFauxFocusIndex: number;
        isLoading: boolean;
        renderInput: (props: { [key: string]: any }) => {};
      }) => (
        <Dropdown
          fullWidth={true}
          isLoading={isLoading}
          isOpen={isDropdownOpenAndSearchInputFocused}
          onClick={refreshOnCreate}
          renderActivator={(activatorProps: { [key: string]: any }) =>
            renderInput({
              ...activatorProps,
              focus: isDropdownOpenAndSearchInputFocused,
              placeholder: 'Search to add metrics',
              testSection: 'metric-search-picker-input',
            })
          }
          testSection="metric-search-picker-dropdown">
          <Dropdown.Contents direction="right">
            <Dropdown.ListItem role="separator" ignoreToggle={true}>
              <div className="zeta">Global Metrics</div>
              <div className="micro hard weight--normal">
                These metrics are tracked across all your events.
              </div>
            </Dropdown.ListItem>
            <Dropdown.ListItem>
              <Container pullRowPadding={true}>
                <Row verticalAlignment="center">
                  <Col small="fillSpace">
                    <Dropdown.BlockLink
                      hasFauxFocus={currentFauxFocusIndex === 0}
                      hideFocusCheckIcon={true}
                      onClick={async () => {
                        const updatedSelectedMetrics: Metric[] = await ComponentModuleActions.addEventFromSearchApiViaEditMetricSheet(
                          {
                            currentProjectId,
                            metric: DefaultMetrics.OVERALL_REVENUE,
                            selectedMetrics,
                          },
                        );
                        onChange(updatedSelectedMetrics);
                      }}
                      testSection="metric-search-picker-overall-revenue-add-button">
                      <div className="flex flex-align--center">
                        <Icon color={brandBlueDark} name="plus" size="small" />
                        <span className="push--left">
                          {truncate(
                            DefaultMetrics.OVERALL_REVENUE.display_title,
                            68,
                          )}
                        </span>
                      </div>
                    </Dropdown.BlockLink>
                  </Col>
                </Row>
              </Container>
            </Dropdown.ListItem>
            <Dropdown.ListItem role="separator" ignoreToggle={true}>
              <div className="flex">
                <div className="flex--1 zeta">Events</div>
                {canUseCrossProjectMetrics && projectIds.length > 1 && (
                  <div
                    className="flex flex--dead-center "
                    data-test-section="projects-filter-options">
                    <Button
                      onClick={() => setShouldQueryAllProjects(false)}
                      style="unstyled"
                      testSection="current-project-button">
                      <Link
                        href="#current"
                        isDisabled={!shouldQueryAllProjects}>
                        Current project only
                      </Link>
                    </Button>
                    <span className="push-half--sides">|</span>
                    <Button
                      onClick={() => setShouldQueryAllProjects(true)}
                      style="unstyled"
                      testSection="all-projects-button">
                      <Link href="#all" isDisabled={shouldQueryAllProjects}>
                        All projects
                      </Link>
                      {renderAllProjectsHelp()}
                    </Button>
                  </div>
                )}
              </div>
              <div
                className="micro hard weight--normal"
                data-test-section="metric-search-picker-list-divider">
                To create an event specific metric, pick{' '}
                {canUseCrossProjectMetrics &&
                projectIds.length > 1 &&
                shouldQueryAllProjects
                  ? 'an account'
                  : 'a project'}{' '}
                event.
              </div>
            </Dropdown.ListItem>
            <Dropdown.ListItem>
              <Dropdown.BlockLink
                hasFauxFocus={currentFauxFocusIndex === 1}
                onClick={async () => {
                  const event: Event = await ComponentModuleActions.showCreateOrEditEventSheet(
                    {
                      currentProjectId,
                    },
                  );
                  const searchApiEvent = computeSearchApiEventFormatFromEvent(
                    event,
                  );
                  const updatedSelectedMetrics: Metric[] = await ComponentModuleActions.addEventFromSearchApiViaEditMetricSheet(
                    {
                      currentProjectId,
                      metric: computeDefaultMetricFromSearchApiEvent(
                        searchApiEvent,
                      ),
                      selectedMetrics,
                    },
                  );
                  onChange(updatedSelectedMetrics);
                  setCreatedEvent(true);
                }}
                testSection="metric-search-picker-create-event-button">
                <div className="flex flex-align--center">
                  <Icon color={brandBlueDark} name="plus" size="small" />
                  <span className="push--left">Create new event</span>
                </div>
              </Dropdown.BlockLink>
            </Dropdown.ListItem>
            {availableEntities.length ? (
              availableEntities.map(
                (searchApiEvent: EventFromSearchApi, index: number) => (
                  <Dropdown.ListItem key={searchApiEvent.id}>
                    <Container pullRowPadding={true}>
                      <Row verticalAlignment="center">
                        <Col small="fillSpace">
                          <Dropdown.BlockLink
                            hasFauxFocus={
                              currentFauxFocusIndex === index + additionalItems
                            }
                            onClick={async () => {
                              const updatedSelectedMetrics: Metric[] = await ComponentModuleActions.addEventFromSearchApiViaEditMetricSheet(
                                {
                                  currentProjectId,
                                  metric: computeDefaultMetricFromSearchApiEvent(
                                    searchApiEvent,
                                  ),
                                  selectedMetrics,
                                },
                              );
                              onChange(updatedSelectedMetrics);
                            }}
                            testSection={`metric-search-picker-event-add-button-${index}`}>
                            <div className="flex flex-align--center">
                              <Icon
                                color={brandBlueDark}
                                name="plus"
                                size="small"
                              />
                              <div className="push--left word-break--word">
                                <div>{truncate(searchApiEvent.name, 68)}</div>
                                <div className="micro muted">
                                  {renderEventDesc(searchApiEvent)}
                                </div>
                              </div>
                            </div>
                          </Dropdown.BlockLink>
                        </Col>
                        <Col small="fitContent">
                          <Button
                            style="outline"
                            size="small"
                            onClick={() => {
                              ComponentModuleActions.showCreateOrEditEventSheet(
                                {
                                  currentProjectId,
                                  id: searchApiEvent.id,
                                },
                              );
                            }}
                            testSection={`metric-search-pick-event-view-button-${index}`}>
                            View
                          </Button>
                        </Col>
                      </Row>
                    </Container>
                  </Dropdown.ListItem>
                ),
              )
            ) : (
              <Dropdown.ListItem className="soft-double">
                <span
                  className="style--italic muted"
                  data-test-section="no-created-events">
                  {!shouldQueryAllProjects
                    ? 'No recently created events in the current project.'
                    : 'No recently created events in all projects.'}
                </span>
              </Dropdown.ListItem>
            )}
            {isLoading && <Spinner hasOverlay={true} />}
          </Dropdown.Contents>
        </Dropdown>
      )}
    </SearchPicker>
  );
};

export default MetricsSearchPicker;
