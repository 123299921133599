/**
 * Services layer pure functions for the layers
 */
import _ from 'lodash';

import cloneDeep from 'optly/clone_deep';
import flux from 'core/flux';
import { toImmutable, isImmutable } from 'optly/immutable';

import LayerExperimentEnums from 'optly/modules/entity/layer_experiment/enums';

import LiveCommitTagGetters from 'optly/modules/entity/live_commit_tag/getters';
import tr from 'optly/translate';
import { isWinnerRolloutFeatureEnabled } from 'optly/utils/features';

import * as humanReadable from './human_readable';
import * as enums from './enums';

function getPausedLayers(layers) {
  const commitTags = flux.evaluateToJS(LiveCommitTagGetters.entityCache);
  return layers.filter(layer => {
    const liveTag = _.find(
      commitTags,
      commitTag => commitTag.layer_id === layer.get('id'),
    );
    return (
      liveTag &&
      liveTag.active === false &&
      !layer.get('archived') &&
      !hasLayerConcluded(layer)
    );
  });
}

function getRunningLayers(layers) {
  const commitTags = flux.evaluateToJS(LiveCommitTagGetters.entityCache);
  return layers.filter(layer => {
    const liveTag = _.find(
      commitTags,
      commitTag => commitTag.layer_id === layer.get('id'),
    );
    return liveTag && liveTag.active === true;
  });
}

function getDraftLayers(layers) {
  const commitTags = flux.evaluateToJS(LiveCommitTagGetters.entityCache);
  return layers.filter(layer => {
    const liveTag = _.find(
      commitTags,
      commitTag => commitTag.layer_id === layer.get('id'),
    );
    return (
      !layer.get('archived') &&
      !hasLayerConcluded(layer) &&
      (!liveTag || liveTag.active === undefined)
    );
  });
}

/**
 * Filter layers by a given status
 * @param {Immutable.Map} layers
 * @param {string} status
 * @return {Immutable.Map}
 */
export function filterLayersByStatus(layers, status) {
  switch (status) {
    case enums.status.ACTIVE:
      layers = layers.filter(
        layer => !layer.get('archived') && !hasLayerConcluded(layer),
      );
      break;
    case enums.status.ARCHIVED:
      layers = layers.filter(layer => layer.get('archived'));
      break;
    case enums.status.CONCLUDED:
      layers = layers.filter(layer => hasLayerConcluded(layer));
      break;
    case enums.status.PAUSED:
      layers = getPausedLayers(layers);
      break;
    case enums.status.RUNNING:
      layers = getRunningLayers(layers);
      break;
    case enums.status.DRAFT:
      layers = getDraftLayers(layers);
      break;
    default:
      break;
  }
  return layers;
}

/**
 * Helper method to determine if the passed layer is a personalization layer
 *
 * @param {Object} layer
 * @return {Boolean}
 */
export function isPersonalizationLayer(layer) {
  // @TODO(ricky): Replace this with a different check once we figure out what distinguishes an AB test from P13N.
  if (!layer) {
    return false;
  }

  const policy = isImmutable(layer) ? layer.get('policy') : layer.policy;

  return (
    policy === enums.policy.ORDERED || policy === enums.policy.EQUAL_PRIORITY
  );
}

/**
 * Helper method to determine if the passed layer is an A/B test layer
 *
 * @param {Object|Immutable.Map} layer
 * @return {Boolean}
 */
export function isABTestLayer(layer) {
  if (!layer) {
    return false;
  }

  const policy = isImmutable(layer) ? layer.get('policy') : layer.policy;

  // TODO (asa): Remove once migration is done for all existing ab tests
  return (
    policy === enums.policy.RANDOM || policy === enums.policy.SINGLE_EXPERIMENT
  );
}

/**
 * Returns true if layer's policy is equal priority
 * @param {Object | Immutable.Map} layer
 * @return {Boolean}
 */
export function isEqualPriorityLayer(layer) {
  if (!layer) {
    return false;
  }
  const policy = isImmutable(layer) ? layer.get('policy') : layer.policy;
  return policy === enums.policy.EQUAL_PRIORITY;
}

/**
 * Returns true if layer's policy is of type single experiment.
 * This includes AB Test Layers and Multivariate Test Layers.
 * @param {Object | Immutable.Map} layer
 * @return {Boolean}
 */
export function isSingleExperimentLayer(layer) {
  if (!layer) {
    return false;
  }
  const policy = isImmutable(layer) ? layer.get('policy') : layer.policy;

  return (
    policy === enums.policy.SINGLE_EXPERIMENT ||
    policy === enums.policy.MULTIVARIATE
  );
}

/**
 * Returns true if layer's policy is multivariate
 * @param {Object | Immutable.Map} layer
 * @return {Boolean}
 */
export function isMultivariateTestLayer(layer) {
  if (!layer) {
    return false;
  }
  const policy = isImmutable(layer) ? layer.get('policy') : layer.policy;

  return policy === enums.policy.MULTIVARIATE;
}

/**
 * Returns true if layer's type is mab
 * @param {Object | Immutable.Map } layer
 * @return {Boolean}
 */
export function isMultiArmedBandit(layer) {
  if (!layer) {
    return false;
  }

  const type = isImmutable(layer) ? layer.get('type') : layer.type;

  if (type) {
    return type === enums.type.MULTIARMED_BANDIT;
  }

  return false;
}

export function isFeatureTestLayer(layer) {
  if (!layer) {
    return false;
  }

  const type = isImmutable(layer) ? layer.get('type') : layer.type;

  if (type) {
    return type === enums.type.FEATURE;
  }

  return false;
}

/**
 * @param {Array} experimentPriorities
 * @param {Object} idTranslationMap
 * @return {Array} Same priority groups as experimentPriorities, but with ids
 * replaced using idTranslationMap
 */
export function getExperimentPrioritiesForDuplicate(
  experimentPriorities,
  idTranslationMap,
) {
  const translatedGroups = _.map(experimentPriorities, priorityGroup => {
    const translatedGroup = _.map(
      priorityGroup,
      experimentId => idTranslationMap[experimentId],
    );
    // Remove any IDs that were missing from the translation map
    // This shouldn't happen, but we'll try to fail gracefully
    return _.filter(translatedGroup, _.isNumber);
  });
  // Remove any empty groups resulting from untranslated IDs.
  // Again, this shouldn't happen, but if it does, try to fail gracefully
  return _.filter(translatedGroups, group => group.length > 0);
}

const MAX_HOLDBACK_PERCENTAGE = 99.99;

const MIN_HOLDBACK_PERCENTAGE = 0;

/**
 * Return an object representing the validity of the argument percentage.
 * @param {Number} holdback
 * @return {Object} Returns an object with isValid: true if the percentage is
 * valid. Returns an object with isError: true if the percentage is outside the
 * allowed range. Returns an object with isWarning: true if the percentage is
 * zero. When isWarning or isError is true, the object also has a
 * human-readable message as its message property.
 */
export function validateHoldbackPercentage(holdbackPercentage) {
  if (_.isNaN(holdbackPercentage) || !_.isFinite(holdbackPercentage)) {
    return {
      isError: true,
      message: humanReadable.INVALID_HOLDBACK_ERROR,
    };
  }
  if (
    holdbackPercentage > MAX_HOLDBACK_PERCENTAGE ||
    holdbackPercentage < MIN_HOLDBACK_PERCENTAGE
  ) {
    return {
      isError: true,
      message: humanReadable.INVALID_HOLDBACK_ERROR,
    };
  }
  if (holdbackPercentage === MIN_HOLDBACK_PERCENTAGE) {
    return {
      isWarning: true,
      message: humanReadable.MIN_HOLDBACK_WARNING,
    };
  }
  return {
    isValid: true,
  };
}

/**
 * Convert a number representining holdback percentage, to the number used to
 * represent that percentage that should be stored in the Layer model
 * @param {Number} holdbackPercentage
 * @return {Number}
 */
export function convertHoldbackPercentageToStoredValue(holdbackPercentage) {
  return Math.round(holdbackPercentage * 100);
}

/**
 * Return the experiment's name, or if it has no name, return a fallback name
 * derived from its layer. For P13N layers, use ''. For A/B layers, use the layer's name.
 * @param {Immutable.Map} experiment
 * @param {Immutable.Map} layer
 * @return String
 */
export function getExperimentName(experiment, layer) {
  const providedName = experiment.get('name');
  if (providedName) {
    return providedName;
  }

  if (isABTestLayer(layer)) {
    return layer.get('name');
  }
  if (!isABTestLayer(layer) && !!experiment.get('name')) {
    return experiment.get('name');
  }
  return null;
}

/**
 * Return the decision metadata associated with the layer
 * @param {Object} layer
 * @return {Object}
 */
export function getDecisionMetadata(layer) {
  let decisionMetadata = cloneDeep(layer.decision_metadata);
  if (!decisionMetadata) {
    decisionMetadata = {
      offer_consistency: false,
      experiment_priorities: [],
    };
  }
  return decisionMetadata;
}

/**
 * Get the most recent last modified time among the layer and all
 * layer experiments pointing to the layer
 * @param {Immutable.Map} layer
 * @param {Immutable.Map} experimentsCache
 * @return {String} The most recent last modified time among the layer and all
 * layer experiments pointing to the layer
 */
export function getLayerAndExperimentsLastModified(layer, experimentsCache) {
  const experimentsInLayer = experimentsCache
    .filter(experiment => experiment.get('layer_id') === layer.get('id'))
    .toList();
  const experimentLastModifieds = experimentsInLayer.map(experiment =>
    experiment.get('last_modified'),
  );
  return experimentLastModifieds
    .push(layer.get('last_modified'))
    .max((dateA, dateB) => {
      if (tr.date(dateA).isBefore(dateB)) {
        return -1;
      }
      if (tr.date(dateB).isBefore(dateA)) {
        return 1;
      }
      return 0;
    });
}

/**
 * Given a layer, returns its campaign type from enums.campaignType
 * @param {Object | Immutable.Map} layer
 * @return {String} One of enums.campaignTypes
 */
export function getCampaignTypeOfLayer(layer) {
  // The order here matters, since a Multi-Armed Bandit is technically both a MULTIARMED_BANDIT type and a
  // SINGLE_EXPERIMENT policy and so is a Feature Test. We should consider changing all isXXXLayer checks to use type
  // now that it's a required field.
  if (isMultiArmedBandit(layer)) {
    return enums.campaignTypes.MULTIARMED_BANDIT;
  }
  if (isFeatureTestLayer(layer)) {
    return enums.campaignTypes.FEATURE;
  }
  if (isABTestLayer(layer)) {
    return enums.campaignTypes.SINGLE_EXPERIMENT;
  }
  if (isMultivariateTestLayer(layer)) {
    return enums.campaignTypes.MULTIVARIATE;
  }
  return enums.campaignTypes.P13N_CAMPAIGN;
}

/**
 * Given a layer, returns an object containing text for actions related to that
 * layer
 * @param {Object} layer
 * @return {Object}
 */
export function getText(layer) {
  return humanReadable.TEXT_BY_CAMPAIGN_TYPE[getCampaignTypeOfLayer(layer)];
}

/**
 * @param {Immutable.Map} layer
 * @param {Immutable.Map} liveCommitTag
 * @return {String} one of Layer.enums.status
 */
export function getStatus(layer, liveCommitTag) {
  if (layer.get('archived')) {
    return enums.status.ARCHIVED;
  }
  if (hasLayerConcluded(layer)) {
    return enums.status.CONCLUDED;
  }
  if (liveCommitTag && liveCommitTag.get('active') === true) {
    return enums.status.RUNNING;
  }
  if (liveCommitTag && liveCommitTag.get('active') === false) {
    return enums.status.PAUSED;
  }
  if (!liveCommitTag || liveCommitTag.get('active') === undefined) {
    return enums.status.DRAFT;
  }
  // This is a bad state we shouldn't be able to get into. This layer's status
  // is unclear, so return DRAFT just because we have to return something.
  if (__DEV__) {
    throw new Error(
      `Unknown layer status for layer: ${layer &&
        layer.get &&
        layer.get('id')}`,
    );
  }
  return enums.status.DRAFT;
}

export function isLayerInDraftStatus(layer) {
  // Return true here because brand new layers don't have ids, so we want to put them in draft status by default
  if (!layer) {
    return true;
  }
  return layer.get('status') === enums.entityStatus.NOT_STARTED;
}

function getPriorityGroups(layer, experimentsCache) {
  let priorityGroups;
  if (isEqualPriorityLayer(layer)) {
    // Equal priority policy - read from decision metadata
    priorityGroups = layer.getIn([
      'decision_metadata',
      'experiment_priorities',
    ]);
  } else {
    // Each experiment in its own priority group (ordered policy)
    priorityGroups = layer
      .get('experiment_ids')
      .map(experimentId => toImmutable([experimentId]));
  }
  const abandonedLayerChildExperimentIds = experimentsCache
    .filter(experiment => experiment.get('layer_id') === layer.get('id'))
    .map(experiment => experiment.get('id'))
    .filterNot(experimentId =>
      priorityGroups.some(priorityGroup =>
        priorityGroup.contains(experimentId),
      ),
    )
    .toList()
    .sort();
  if (abandonedLayerChildExperimentIds.size) {
    priorityGroups = priorityGroups.push(abandonedLayerChildExperimentIds);
  }
  return priorityGroups;
}

/**
 * Breaks up and orders experiments by priority group as well as archived vs active
 * Note: If an experimentId exists in the priority group but
 *       not in experimentsCache, it will be considered archived.
 *
 * @param {Immutable.Map} layer
 * @param {Immutable.List} experimentsCache
 * @returns {{unarchivedGroups: Immutable.List, archivedGroup: Immutable.List}}
 */
export function getPriorityGroupsSplitByStatus(layer, experimentsCache) {
  const priorityGroups = getPriorityGroups(layer, experimentsCache);
  // Helper filter function for archived experiments or ones not present in experimentsCache
  const isArchivedOrMissing = experimentId =>
    !experimentsCache.has(experimentId) ||
    experimentsCache.getIn([experimentId, 'status']) ===
      LayerExperimentEnums.status.ARCHIVED;
  const unarchivedGroups = priorityGroups
    .map(priorityGroup => priorityGroup.filterNot(isArchivedOrMissing))
    .filter(priorityGroup => priorityGroup.size > 0);
  const archivedGroup = priorityGroups.flatten().filter(isArchivedOrMissing);
  return {
    unarchivedGroups,
    archivedGroup,
  };
}

/**
 * Whether a layer has ever been started (thereby having results for the variations in it).
 * @param {Immutable.Map} layer
 * @returns {Boolean}
 */
export function hasLayerStarted(layer) {
  return (
    layer.get('status') === enums.entityStatus.RUNNING ||
    layer.get('status') === enums.entityStatus.PAUSED
  );
}

/**
 * Helper method to determine if the passed layer is Concluded
 * @param {Immutable.Map} layer
 * @returns {Boolean}
 */
export function hasLayerConcluded(layer) {
  return Boolean(isWinnerRolloutFeatureEnabled() && layer.get('concluded'));
}

/**
 * Helper method to determine if the passed layer is readonly
 * Readonly if the Winner Rollout feature is enabled and the Layer is Archived or Concluded
 * @param {Immutable.Map} layer
 * @returns {Boolean}
 */
export function isLayerReadonly(layer) {
  return Boolean(
    isWinnerRolloutFeatureEnabled() &&
      (layer.get('archived') || layer.get('concluded')),
  );
}

export default {
  filterLayersByStatus,
  isPersonalizationLayer,
  isABTestLayer,
  isEqualPriorityLayer,
  isSingleExperimentLayer,
  isMultiArmedBandit,
  isMultivariateTestLayer,
  getExperimentPrioritiesForDuplicate,
  validateHoldbackPercentage,
  convertHoldbackPercentageToStoredValue,
  getExperimentName,
  getDecisionMetadata,
  getLayerAndExperimentsLastModified,
  getCampaignTypeOfLayer,
  getText,
  getStatus,
  isLayerInDraftStatus,
  getPriorityGroupsSplitByStatus,
  hasLayerStarted,
  hasLayerConcluded,
  isLayerReadonly,
};
