import constants from './constants';

export const getTrafficAllocationPolicyItems = (
  canUseMultiArmedBandits,
  canUseStatsAccelerator,
  canUseContextualMultiArmedBandits,
  isABTest,
) => {
  const policyItems = [constants.ManualPolicyItem];

  if (canUseMultiArmedBandits && !isABTest) {
    policyItems.push(constants.MultiArmedBanditPolicyItem);
  }

  if (canUseContextualMultiArmedBandits && !isABTest) {
    policyItems.push(constants.ContextualMultiArmedBanditPolicyItem);
  }

  if (canUseStatsAccelerator) {
    policyItems.push(constants.StatsAcceleratorPolicyItem);
  }

  return policyItems;
};

export const IsContextualMultiArmedBanditPolicy = currentlySelectedTrafficAllocationPolicy => {
  return (
    currentlySelectedTrafficAllocationPolicy ===
    constants.TrafficAllocationPolicyTypes.CMAB
  );
};

export const getPolicyLabel = policyType => {
  switch (policyType) {
    case constants.TrafficAllocationPolicyTypes.MANUAL:
      return constants.ManualPolicyItem.label;
    case constants.TrafficAllocationPolicyTypes.MAXIMIZE_CONVERSIONS:
      return constants.MultiArmedBanditPolicyItem.label;
    case constants.TrafficAllocationPolicyTypes.CMAB:
      return constants.ContextualMultiArmedBanditPolicyItem.label;
    case constants.TrafficAllocationPolicyTypes.MINIMIZE_TIME:
      return constants.StatsAcceleratorPolicyItem.label;
    default:
      return constants.ManualPolicyItem.label;
  }
};

export const isContextualMultiArmedBanditPolicy = currentlySelectedTrafficAllocationPolicy => {
  return (
    currentlySelectedTrafficAllocationPolicy ===
    constants.TrafficAllocationPolicyTypes.CMAB
  );
};

export default {
  getTrafficAllocationPolicyItems,
  getPolicyLabel,
  IsContextualMultiArmedBanditPolicy,
};
