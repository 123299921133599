import keyMirror from 'optly/utils/key_mirror';

const CODE_BLOCK_REACT = `import { withOptimizely } from '@optimizely/react-sdk';

class TheComponent extends React.Component {
  onEvent = () => {
    const { optimizely } = this.props;
    optimizely.track('%s');
  }
}

const WrappedComponent = withOptimizely(TheComponent);
`;

const CODE_BLOCK_AGENT = `curl -X POST \\
  '{HOST_URL}/v1/track?eventKey=%s' \\
  -H 'Content-Type: application/json' \\
  -H 'X-Optimizely-SDK-Key: "YOUR-SDK-KEY"' \\
  -d '{
    "userId":"USER_ID"
  }'
`;

const JAVA_CODE_BLOCK = `OptimizelyUserContext user = optimizelyClient.createUserContext(userId, attributes);
user.trackEvent("%s");`;

const JAVASCRIPT_CODE_BLOCK = `var user = optimizelyClientInstance.createUserContext(userId, attributes);
user.trackEvent('%s');`;

const CSHARP_CODE_BLOCK = `OptimizelyUserContext user = optimizelyClientInstance.CreateUserContext(userId, attributes);
user.TrackEvent("%s");`;

const SWIFT_CODE_BLOCK = `let user = optimizelyClient.createUserContext(userId: userId, attributes: attributes)
try? user.trackEvent(eventKey: "%s")`;

const PHP_CODE_BLOCK = `$user = $optimizelyClient->createUserContext(userId, $attributes);
$user->trackEvent('%s');`;

const PYTHON_CODE_BLOCK = `user = optimizely_client.create_user_context(user_id, attributes)
user.track_event("%s")`;

const RUBY_CODE_BLOCK = `user = optimizely_client.create_user_context(user_id, attributes)
user.track_event("%s")`;

const GO_CODE_BLOCK = `user := optimizelyClientInstance.CreateUserContext(userId, attributes)
user.TrackEvent("%s", nil)`;

const FLUTTER_CODE_BLOCK = `var user = await optimizelyClientInstance.createUserContext(userId, attributes);
user.trackEvent("%s")`;

export const TABLE_IDS = keyMirror({
  EVENTS: null,
});

export const TABS = keyMirror({
  EVENTS: null,
});

export const CODE_BLOCKS = {
  android: JAVA_CODE_BLOCK,
  android_tv: JAVA_CODE_BLOCK,
  csharp: CSHARP_CODE_BLOCK,
  ios: '[client track:@"%s", userId:userId];',
  java: JAVA_CODE_BLOCK,
  javascript: JAVASCRIPT_CODE_BLOCK,
  node: JAVASCRIPT_CODE_BLOCK,
  objectivec: '[client track:@"%s" userId:userId];',
  php: PHP_CODE_BLOCK,
  python: PYTHON_CODE_BLOCK,
  ruby: RUBY_CODE_BLOCK,
  swift: SWIFT_CODE_BLOCK,
  tv_os: '[client track:@"%s", userId:userId];',
  go: GO_CODE_BLOCK,
  jsx: CODE_BLOCK_REACT,
  html: CODE_BLOCK_AGENT,
  flutter: FLUTTER_CODE_BLOCK,
};

export default {
  TABLE_IDS,
  TABS,
  CODE_BLOCKS,
};
