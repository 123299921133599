const _ = require('lodash');
import ui from 'core/ui';

import EventActions from 'optly/modules/entity/event/actions';
import ProjectActions from 'optly/modules/entity/project/actions';
import ProjectEnums from 'optly/modules/entity/project/enums';

import CreateOrEditEventSheet from '../components/create_or_edit_custom_event_sheet';
import EditMetricSheet, {
  // eslint-disable-next-line no-unused-vars
  EditMetricSheetCoreProps,
} from '../components/edit_metric_sheet';

// eslint-disable-next-line no-unused-vars
import { Event, Metric } from './types';

/**
 * @description Fetches all projects the user has access to for the current account with CROSS_PROJECT_METRICS account feature.
 * 
 *  TODO(APPX-1777) Use $idsOnly filter once Projects bug is fixed
 */
export const fetchProjectsAndSetProvidedProjectAsDeduplicatedFirst = (): Promise<any[]> => {
  return new Promise((resolve, reject) => {
    const projectPlatforms: string[] = [
      ProjectEnums.project_platforms.CUSTOM,
      ProjectEnums.project_platforms.WEB,
    ];

    // creates a separate Promise for each project_platform we want to fetch
    // and extracts out allPages, which is the paginated results of all projects for each platform 
    const allProjects: Promise<{ firstPage: any, allPages: Promise<any[]> }>[] = projectPlatforms.map(platform => {
      return ProjectActions.fetchAllPages({
        project_status: ProjectEnums.project_status.ACTIVE,
        project_platforms: platform,
      });
    });

    Promise.all(allProjects)
      // returns an array of objects, where each object contains the results for each platform
      .then(results => {
        const allPages: Promise<any[]>[] = results.map(result => result.allPages);

        Promise.all(allPages)
          .then(allPagesResults => {
            const combinedProjects = _.uniqBy(allPagesResults.flat(), 'id');
            resolve(combinedProjects);
          })
          .catch(reject);
      })
      .catch(reject);
  });
};


/**
 * @description Fetches the event for every metric that has a event_id. Not memoized since our entity cache already handles that
 */
export const fetchEventsForSelectedMetrics = (selectedMetrics: Metric[]) => {
  selectedMetrics.forEach(selectedMetric => {
    const { event_id: eventId } = selectedMetric;
    if (eventId) {
      EventActions.fetch(eventId);
    }
  });
};

/**
 * @description Opens the editable event fields in an OUI Sheet
 */
export const showCreateOrEditEventSheet = ({
  currentProjectId,
  id,
  name,
}: {
  currentProjectId: number;
  id?: number;
  name?: string;
}): Promise<Event> =>
  new Promise(resolve => {
    ui.showReactDialog(
      CreateOrEditEventSheet,
      {
        props: {
          ...(id && { id }),
          ...(name && { name }),
          currentProjectId,
          onSave: (savedEvent: Event) =>
            ui.hideDialog().then(() => resolve(savedEvent)),
          onCancel: () => ui.hideDialog(),
        },
      },
      {
        fullScreen: true,
        dismissOnBack: true,
        isOuiDialog: true,
      },
    );
  });

/**
 * @description Opens the editable metric fields in an OUI Sheet
 */
export const showEditMetricSheet = ({
  currentProjectId,
  isNewMetric = false,
  metric,
  selectedMetrics,
}: EditMetricSheetCoreProps): Promise<Metric> =>
  new Promise(resolve => {
    ui.showReactDialog(
      EditMetricSheet,
      {
        props: {
          currentProjectId,
          isNewMetric,
          metric,
          selectedMetrics,
          onSave: (configuredMetric: Metric) =>
            ui.hideDialog().then(() => resolve(configuredMetric)),
          onCancel: () => ui.hideDialog(),
        },
      },
      {
        fullScreen: true,
        dismissOnBack: true,
        isOuiDialog: true,
      },
    );
  });

/**
 * @description Given an event, edit and add the metric via the edit metric sheet
 */
export function addEventFromSearchApiViaEditMetricSheet(
  // phantom param definition https://github.com/microsoft/TypeScript/wiki/'this'-in-TypeScript#specify-type-of-this-in-function-signature
  this: {
    showEditMetricSheet: (config: EditMetricSheetCoreProps) => Promise<Metric>;
  },
  { currentProjectId, metric, selectedMetrics }: EditMetricSheetCoreProps,
): Promise<Metric[]> {
  return new Promise(resolve => {
    this.showEditMetricSheet({
      currentProjectId,
      isNewMetric: true,
      metric,
      selectedMetrics,
    }).then((updatedMetric: Metric) =>
      resolve([...selectedMetrics, updatedMetric]),
    );
  });
}

export default {
  addEventFromSearchApiViaEditMetricSheet,
  fetchEventsForSelectedMetrics,
  fetchProjectsAndSetProvidedProjectAsDeduplicatedFirst,
  showCreateOrEditEventSheet,
  showEditMetricSheet,
};
