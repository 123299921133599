/* Using eslint-disable flag due to improper declarations on legacy code */
/* eslint-disable class-methods-use-this */
import classNames from 'classnames';
import { Button, Link, Poptip, ProgressDots, Table } from '@optimizely/axiom';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { actions as jsSDKLabActions } from '@optimizely/js-sdk-lab';

import { List, Map } from 'immutable';

import flux from 'core/flux';
import { connect } from 'core/ui/decorators';
import Immutable from 'optly/immutable';
import {
  enums as LayerEnums,
  fns as LayerFns,
  humanReadable as LayerHumanReadable,
} from 'optly/modules/entity/layer';
import { fns as MetricFns } from 'optly/modules/entity/metric';
import { getPolicyLabel } from 'bundles/p13n/components/traffic_allocation/traffic_allocation_policy/fns';
import LayerGetters from 'optly/modules/entity/layer/getters';
import { getters as ViewGetters } from 'optly/modules/entity/view';
import { getters as AudienceGetters } from 'optly/modules/entity/audience';
import LayerExperimentFns from 'optly/modules/entity/layer_experiment/fns';
import ResultsSectionConstants from 'bundles/p13n/sections/results/section_module/constants';
import shortDate from 'optly/filters/shortdate';
import tr from 'optly/translate';
import UrlHelperV2 from 'optly/services/url_helper';

import JiraIssuesTableCell from 'bundles/p13n/components/jira/issues_table_cell';

import pushStateHandler from 'optly/utils/push_state';

import { getters as DashboardGetters } from 'optly/modules/dashboard';

import SchedulePoptip from '../schedule_poptip';
import LayerTableRowActions from './layer_table_row_actions';
import getLayerUrl from '../../fns/getLayerUrl';

const DisplayValueComponent = ({ displayName, poptipClassnames }) => {
  const [showTooltip, setShowTooltip] = React.useState(false);

  const ref = React.createRef();
  const prevWidthRef = React.useRef(null);

  React.useLayoutEffect(() => {
    if (ref.current) {
      const currentWidth = ref.current.clientWidth;

      if (prevWidthRef.current !== currentWidth) {
        setShowTooltip(ref.current.clientWidth < ref.current.scrollWidth);
      }
      prevWidthRef.current = currentWidth;
    }
  }, [ref]);

  return (
    <div className="nowrap">
      <Poptip trigger="mouseenter" disable={!showTooltip} content={displayName}>
        <div ref={ref} className={`truncate ${poptipClassnames || ''}`}>
          {displayName}
        </div>
      </Poptip>
    </div>
  );
};

DisplayValueComponent.propTypes = {
  displayName: PropTypes.string,
  poptipClassnames: PropTypes.string,
};

DisplayValueComponent.defaultProps = {
  displayName: PropTypes.string,
  poptipClassnames: PropTypes.string,
};
@connect({
  activeTab: DashboardGetters.getActiveTab,
})
class LayerTableRow extends React.Component {
  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    columnsVisibility: PropTypes.instanceOf(Immutable.Map).isRequired,
    currentProject: PropTypes.instanceOf(Immutable.Map).isRequired,
    dashboardLayer: PropTypes.instanceOf(Immutable.Map).isRequired,
    handleEntityUpdate: PropTypes.func,
    jiraIntegrationEnabled: PropTypes.bool,
    jiraIntegrationSettings: PropTypes.instanceOf(Immutable.Map),
    shouldShowRowActions: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    jiraIntegrationEnabled: false,
    jiraIntegrationSettings: Immutable.Map({}),
    handleEntityUpdate: () => Promise.resolve(),
  };

  useCustomTable = jsSDKLabActions.getFeatureVariableBoolean(
    'experiment_list_m1',
    'use_custom_table',
  );

  shouldDisableResultsButton = () => {
    const { dashboardLayer } = this.props;
    const liveTag = dashboardLayer.get('liveTag');
    return !liveTag || liveTag.get('active') === undefined;
  };

  getStatusText = () => {
    const { dashboardLayer } = this.props;
    const status = dashboardLayer.get('status');
    switch (status) {
      case LayerEnums.status.CONCLUDED:
        return tr('Concluded');

      case LayerEnums.status.ARCHIVED:
        return tr('Archived');

      case LayerEnums.status.RUNNING:
        return tr('Running');

      case LayerEnums.status.PAUSED:
        return tr('Paused');

      case LayerEnums.status.DRAFT:
        return tr('Not Started');

      case LayerEnums.status.CONCLUDED:
        return tr('Concluded');

      default:
        if (__DEV__) {
          throw new Error(`Unknown layer status: ${status}`);
        }
        return '';
    }
  };

  /**
   * @typedef {Object} Outcome
   * @property {string} label - The label of result outcome.
   * @property {string} className - The classname to control text color.
   */

  /**
   * Returns a label with the corresponding classname for text color
   * @returns {Outcome}
   */
  getResultsOutcome = () => {
    const { dashboardLayer } = this.props;
    const layer = dashboardLayer.get('layer');

    if (!layer) {
      return {};
    }

    const outcome = layer.get('concluded_results_outcome');

    const className = classNames({
      'color--bad-news': outcome === LayerEnums.resultsOutcome.NEGATIVE,
      'color--good-news': outcome === LayerEnums.resultsOutcome.POSITIVE,
      'min-width--60': true,
    });

    let label;
    switch (outcome) {
      case LayerEnums.resultsOutcome.POSITIVE:
        label = tr('Positive');
        break;
      case LayerEnums.resultsOutcome.NEGATIVE:
        label = tr('Negative');
        break;
      case LayerEnums.resultsOutcome.INCONCLUSIVE:
        label = tr('Inconclusive');
        break;
      default:
        label = tr('--');
        break;
    }
    return { label, className };
  };

  getStatusClassName = () =>
    classNames({
      'color--good-news': this.isRunning(),
      'min-width--60': true,
    });

  getTypeText = () => {
    const { dashboardLayer } = this.props;
    return LayerHumanReadable.TEXT_BY_LAYER_TYPE[dashboardLayer.get('type')]
      .name;
  };

  isArchived = () => {
    const { dashboardLayer } = this.props;
    return dashboardLayer.get('status') === LayerEnums.status.ARCHIVED;
  };

  isRunning = () => {
    const { dashboardLayer } = this.props;
    return dashboardLayer.get('status') === LayerEnums.status.RUNNING;
  };

  getLayerDaysRunning = layer => {
    const today = Date.now();
    const isRunning = layer.get('status') === LayerEnums.entityStatus.RUNNING;
    const end = isRunning ? today : layer.get('latest') || today;
    const begin = layer.get('earliest') || today;

    const beginDate = new Date(begin).valueOf();
    const endDate = new Date(end).valueOf();

    const adjustedBegin = beginDate >= endDate ? beginDate : beginDate;
    const adjustedEnd = beginDate >= endDate ? beginDate + 1000 : endDate;

    return moment(adjustedEnd).diff(adjustedBegin, 'days');
  };

  renderVariationsCount = layer => {
    let variationCount;

    const type = layer.get('type');

    if (type === LayerEnums.type.PERSONALIZATION) {
      const layerExperiments = flux.evaluate(
        LayerGetters.experimentsByLayerId(layer.get('id')),
      );

      variationCount =
        layerExperiments.reduce(
          (count, layerExperiment) =>
            count + layerExperiment.get('variations')?.length || 0,
          0,
        ) || null;
    } else {
      const layerExperiment = flux.evaluate(
        LayerGetters.singleExperimentByLayerId(layer.get('id')),
      );
      let variations = layerExperiment?.get('variations')?.toJS() || [];

      // For the multivariate test we don't have the variations, we have the variation combinations
      if (
        variations.length &&
        layerExperiment?.get('type') === LayerEnums.policy.MULTIVARIATE
      ) {
        variations = variations.reduce(
          (uniqueVariations, variation) => [
            ...new Set([
              ...uniqueVariations,
              ...Object.values(variation.combination_sources),
            ]),
          ],
          [],
        );
      }

      variationCount = variations.length || null;
    }

    return variationCount;
  };

  renderPages = layer => {
    if (layer?.get('url_targeting')) {
      return <span>URL Targeting</span>;
    }

    const totalPages = flux.evaluate(ViewGetters.byLayer(layer));
    let pages;
    let remainingPagesCount;
    let url;

    if (totalPages.length > 3) {
      remainingPagesCount = totalPages.length - 3;
      pages = totalPages.slice(0, 3);

      const layerType = layer?.get('type');
      const projectId = layer.get('project_id');
      let experimentId;
      let experimentType;

      if (layerType === 'a/b' || layerType === 'multiarmed_bandit') {
        const layerExperiment = flux.evaluate(
          LayerGetters.singleExperimentByLayerId(layer.get('id')),
        );

        experimentId = layerExperiment.get('id');
        experimentType = 'experiments';
      } else if (layerType === 'personalization') {
        experimentId = layer.get('id');
        experimentType = 'campaigns';
      } else if (layerType === 'multivariate') {
        const layerExperiment = flux.evaluate(
          LayerGetters.singleExperimentByLayerId(layer.get('id')),
        );

        experimentId = layerExperiment.get('id');
        experimentType = 'multivariate';
      }

      url = `/v2/projects/${projectId}/${experimentType}/${experimentId}/pages`;
    } else {
      pages = totalPages;
    }

    return (
      <>
        <div>
          {pages.map(page => (
            <DisplayValueComponent
              key={page.get('id')}
              displayName={page.get('name')}
            />
          ))}
        </div>
        {remainingPagesCount && (
          <Link href={url}>+ {remainingPagesCount} more</Link>
        )}
      </>
    );
  };

  renderAudiences = layer => {
    if (!layer) {
      return <ProgressDots />;
    }

    const defaultAudience = <span>Everyone</span>;

    const layerExperiments = flux.evaluate(
      LayerGetters.experimentsByLayerId(layer.get('id')),
    );

    if (!layerExperiments.size) {
      return defaultAudience;
    }

    const audiences = layerExperiments.flatMap(layerExperiment => {
      const audiences = flux.evaluate(
        AudienceGetters.byLayerExperiment(layerExperiment),
      );

      return !audiences.size
        ? List.of(Map({ id: null, name: 'Everyone' }))
        : audiences;
    });

    if (!audiences.size) {
      return defaultAudience;
    }

    // Remove duplicates for Personalization Campaigns
    const uniqueAudiences = Object.values(
      audiences.reduce((acc, obj) => ({ ...acc, [obj.get('id')]: obj }), {}),
    ).sort((a, b) => {
      return a
        .get('name')
        .toUpperCase()
        .localeCompare(b.get('name').toUpperCase());
    });

    return (
      <div>
        {uniqueAudiences.map(audience => (
          <DisplayValueComponent
            key={audience.get('id')}
            displayName={audience.get('name')}
          />
        ))}
      </div>
    );
  };

  renderDistributionMode = layer => {
    let distributionModeElement;
    const type = layer.get('type');

    if (type === LayerEnums.type.PERSONALIZATION) {
      const layerExperiments = flux.evaluate(
        LayerGetters.experimentsByLayerId(layer.get('id')),
      );

      const uniqueAllocationPolicies = layerExperiments.reduce(
        (allocationPoliciesSet, layerExperiment) =>
          allocationPoliciesSet.add(
            getPolicyLabel(layerExperiment.get('allocation_policy')),
          ),
        new Set(),
      );

      distributionModeElement = [
        ...uniqueAllocationPolicies,
      ].map(policyName => <div key={policyName}>{policyName}</div>);
    } else {
      const layerExperiment = flux.evaluate(
        LayerGetters.singleExperimentByLayerId(layer.get('id')),
      );

      const allocationPolicy = layerExperiment?.get('allocation_policy');
      const holdback = layerExperiment?.get('layer_holdback');
      const variations = layerExperiment?.get('variations') || Immutable.List();
      const variationWeights = variations
        .map(variation => {
          const weight = variation.get('weight');
          const percentage = weight / 100;
          return Math.round(percentage);
        })
        .join('/');
      const distributionMode = holdback
        ? `${holdback / 100}/${variationWeights}`
        : variationWeights;

      distributionModeElement = (
        <div>
          <span>{getPolicyLabel(allocationPolicy)}</span>
          <DisplayValueComponent
            displayName={distributionMode}
            poptipClassnames="muted micro"
          />
        </div>
      );
    }

    return distributionModeElement;
  };

  render() {
    const {
      activeTab,
      currentProject,
      jiraIntegrationEnabled,
      jiraIntegrationSettings,
      dashboardLayer,
      handleEntityUpdate,
      shouldShowRowActions,
      columnsVisibility,
    } = this.props;

    const layer = dashboardLayer.get('layer');

    let layerExperiment;
    if (dashboardLayer.get('layer')) {
      layerExperiment = flux.evaluate(
        LayerGetters.singleExperimentByLayerId(
          dashboardLayer.getIn(['layer', 'id']),
        ),
      );
    }

    const layerUrl = getLayerUrl({
      layer: dashboardLayer.get('layer'),
      projectId: currentProject.get('id'),
      unique_id: dashboardLayer.get('unique_id'),
      type: dashboardLayer.get('type'),
    });

    // Results button replaced by loader if layer isn't provided
    const resultsUrl =
      layer &&
      UrlHelperV2.layerResults(currentProject.get('id'), layer.get('id'), {
        previousView: activeTab,
      });

    // Jira controls replaced by loader if layer isn't provided
    const showJiraIssues =
      jiraIntegrationEnabled &&
      jiraIntegrationSettings.get('enabled') &&
      !!jiraIntegrationSettings.get('resource_id');
    const isPersonalizationLayer =
      layer && LayerFns.isPersonalizationLayer(layer);

    const [primaryMetric] = layer?.get('metrics') || [];

    const resultsOutcome = this.getResultsOutcome();

    return (
      <Table.TR
        testSection={`layer-table-row-for-${dashboardLayer.get('unique_id')}`}>
        {columnsVisibility.get('name') && (
          <Table.TD className="max-width--100">
            <div>
              <Link
                className="cursor--pointer"
                href={layerUrl}
                onClick={event => pushStateHandler(layerUrl, event)}
                testSection="layer-table-row-name">
                {dashboardLayer.get('name')}
              </Link>
              <div
                className="muted micro"
                style={{
                  maxWidth: '600px',
                }}
                data-test-section="layer-table-row-description">
                {dashboardLayer.get('description')}
              </div>
            </div>
          </Table.TD>
        )}
        {columnsVisibility.get('type') && (
          <Table.TD testSection="layer-table-row-type">
            {LayerExperimentFns.isStatsAcceleratorExperiment(layerExperiment)
              ? 'Stats Accelerator'
              : this.getTypeText()}
          </Table.TD>
        )}
        {showJiraIssues && columnsVisibility.get('jira') && (
          <Table.TD testSection="layer-table-row-jira">
            {!layer ? (
              <ProgressDots />
            ) : (
              <JiraIssuesTableCell
                source={layer}
                sourceType={isPersonalizationLayer ? 'campaign' : 'experiment'}
              />
            )}
          </Table.TD>
        )}
        {columnsVisibility.get('status') && (
          <Table.TD>
            <div className="flex">
              <div
                className={this.getStatusClassName()}
                data-test-section="layer-table-row-status">
                {this.getStatusText()}
              </div>
              {this.useCustomTable && (
                <SchedulePoptip experiment={layerExperiment} />
              )}
            </div>
          </Table.TD>
        )}
        {columnsVisibility.get('concluded_results_outcome') && (
          <Table.TD>
            <div className="flex">
              <div
                className={resultsOutcome.className}
                data-test-section="layer-table-row-status">
                {resultsOutcome.label}
              </div>
            </div>
          </Table.TD>
        )}
        {columnsVisibility.get('creator') && (
          <Table.TD className="max-width--100">
            <DisplayValueComponent displayName={layer?.get('creator') || ''} />
          </Table.TD>
        )}
        {columnsVisibility.get('last_modified') && (
          <Table.TD testSection="layer-table-row-modified">
            <span className="nowrap">
              {shortDate(dashboardLayer.get('last_modified'))}
            </span>
          </Table.TD>
        )}
        {columnsVisibility.get('first_published') && (
          <Table.TD testSection="layer-table-row-first-published">
            <span className="nowrap">
              {shortDate(layer?.get('earliest_original'))}
            </span>
          </Table.TD>
        )}
        {columnsVisibility.get('last_paused') && (
          <Table.TD testSection="layer-table-row-last-paused">
            <span className="nowrap">
              {shortDate(layer?.get('last_paused'))}
            </span>
          </Table.TD>
        )}
        {columnsVisibility.get('last_published') && (
          <Table.TD testSection="layer-table-row-last-published">
            <span className="nowrap">
              {shortDate(layer?.get('last_published'))}
            </span>
          </Table.TD>
        )}
        {columnsVisibility.get('primary_metric') && (
          <Table.TD
            className="max-width--100"
            testSection="layer-table-row-primary-metric">
            {primaryMetric && (
              <div>
                <span>{primaryMetric.get('display_title')}</span>
                <div
                  className="muted micro"
                  style={{
                    maxWidth: '600px',
                  }}
                  data-test-section="layer-table-row-description">
                  {MetricFns.getMetricDescription(primaryMetric)}
                </div>
              </div>
            )}
          </Table.TD>
        )}
        {columnsVisibility.get('days_running') && (
          <Table.TD isNumerical={true}>
            <span>
              {layer &&
                layer?.get('status') !== LayerEnums.entityStatus.NOT_STARTED &&
                this.getLayerDaysRunning(layer)}
            </span>
          </Table.TD>
        )}
        {columnsVisibility.get('variations') && (
          <Table.TD isNumerical={true}>
            <span>{layer && this.renderVariationsCount(layer)}</span>
          </Table.TD>
        )}
        {columnsVisibility.get('pages') && (
          <Table.TD className="max-width--100">
            {layer && this.renderPages(layer)}
          </Table.TD>
        )}
        {columnsVisibility.get('audiences') && (
          <Table.TD
            className="max-width--100"
            testSection="layer-table-row-audiences">
            {this.renderAudiences(layer)}
          </Table.TD>
        )}
        {columnsVisibility.get('targeting_method') && (
          <Table.TD>
            <span>{layer?.get('url_targeting') ? 'URL' : 'Page'}</span>
          </Table.TD>
        )}
        {columnsVisibility.get('unique_id') && (
          <Table.TD>
            <span className="nowrap">{dashboardLayer.get('unique_id')}</span>
          </Table.TD>
        )}
        {columnsVisibility.get('traffic_allocation') && (
          <Table.TD isNumerical={true}>
            <span className="nowrap">
              {`${100 - (layer?.get('holdback') ?? 0) / 100}%`}
            </span>
          </Table.TD>
        )}
        {columnsVisibility.get('distribution_mode') && (
          <Table.TD className="max-width--100">
            {layer && this.renderDistributionMode(layer)}
          </Table.TD>
        )}
        {columnsVisibility.get('results') && (
          <Table.TD>
            {!layer || dashboardLayer.get('liveTag') === undefined ? (
              <ProgressDots testSection="layer-table-row-results-loader" />
            ) : (
              <Link
                href={resultsUrl}
                onClick={event => pushStateHandler(resultsUrl, event)}
                isDisabled={
                  this.shouldDisableResultsButton() ||
                  (layer && !layer.get('metrics').size)
                }
                testSection="layer-table-row-results">
                <Button
                  style="outline"
                  isLink={true}
                  size="small"
                  leftIcon="chart-column"
                  isDisabled={
                    this.shouldDisableResultsButton() ||
                    (layer && !layer.get('metrics').size)
                  }>
                  Results
                </Button>
              </Link>
            )}
          </Table.TD>
        )}
        {shouldShowRowActions && (
          <Table.TD isNumerical={true}>
            <LayerTableRowActions
              dashboardLayer={dashboardLayer}
              currentProject={currentProject}
              handleEntityUpdate={handleEntityUpdate}
              jiraIntegrationEnabled={jiraIntegrationEnabled}
            />
          </Table.TD>
        )}
      </Table.TR>
    );
  }
}

export default LayerTableRow;
