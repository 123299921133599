/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import { feature } from '@optimizely/js-sdk-lab/src/decorators';
import { Sortable, Button, ButtonRow, Pill } from 'optimizely-oui';

import { connect } from 'core/ui/decorators';
import LayerFns from 'optly/modules/entity/layer/fns';
import { getters as CurrentLayerGetters } from 'bundles/p13n/modules/current_layer';
import Immutable from 'optly/immutable';
import ui from 'core/ui';
import ChampagneEnums from 'optly/modules/optimizely_champagne/enums';

import { actions as SectionModuleActions } from 'bundles/p13n/sections/campaign_overview/section_module';

@connect({
  currentLayer: CurrentLayerGetters.layer,
})
@feature(ChampagneEnums.FEATURES.audience_combo_reskin.FEATURE_KEY)
class ExperimentPrioritiesDialog extends React.Component {
  static propTypes = {
    currentLayer: PropTypes.instanceOf(Immutable.Map).isRequired,
    data: PropTypes.instanceOf(Immutable.Map).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      priorityGroups: props.data.get('priorityGroups'),
      isSaving: false,
    };
  }

  handleSubmit = () => {
    const { data } = this.props;
    const { priorityGroups, isSaving } = this.state;

    if (isSaving) {
      return;
    }

    this.setState({ isSaving: true });

    SectionModuleActions.saveExperimentPriorities(
      data.get('layerId'),
      priorityGroups,
    )
      .then(() => {
        ui.showNotification({
          message: tr('Experience priorities have been updated.'),
          type: 'success',
        });
        this.setState({ isSaving: false });
        ui.hideDialog();
      })
      .fail(() => {
        this.setState({ isSaving: false });
      });
  };

  handleCancel = () => {
    ui.hideDialog();
  };

  handleSortableChange = sortableGroups => {
    const priorityGroups = sortableGroups.map(sortableItems =>
      sortableItems.map(item => item.get('id')),
    );
    this.setState({
      priorityGroups,
    });
  };

  getExperimentDisplayName = experiment => {
    const name = experiment.get('name');
    if (name) {
      return name;
    }

    return experiment.get('audienceLabels').join(' ');
  };

  renderSortableItem = ({ item, connectDragSource, position }) => {
    let name = item.get('text');
    if (item.get('isPaused')) {
      name = `${name} (Paused)`;
    } else if (item.get('hasUnpublishedChanges')) {
      name = `${name} (Draft)`;
    }

    return connectDragSource(
      <div>
        <Pill
          isDraggable={true}
          isFullWidth={
            this[ChampagneEnums.FEATURES.audience_combo_reskin.FEATURE_KEY]
          }
          name={name}
          order={position[0] + 1}
          showWell={false}
          usesDragHandle={
            this[ChampagneEnums.FEATURES.audience_combo_reskin.FEATURE_KEY]
          }
        />
      </div>,
    );
  };

  render() {
    const { data, currentLayer } = this.props;
    const { isSaving } = this.state;

    const sortablePriorityGroups = data
      .get('priorityGroups', Immutable.List())
      .map(group =>
        group.map(id =>
          Immutable.Map({
            id,
            text: this.getExperimentDisplayName(
              data.getIn(['experiments', id]),
            ),
            isPaused: data.getIn(['experiments', id, 'isPaused']),
            hasUnpublishedChanges: data.getIn([
              'experiments',
              id,
              'hasUnpublishedChanges',
            ]),
            type: 'item',
          }),
        ),
      );

    return (
      <div
        className="overflow-y--auto flex--1"
        data-test-section="experiment-priorities-dialog">
        <div className="reading-column">
          <div className="beta">Experience Priorities</div>
        </div>
        <div className="reading-column">
          <form>
            <fieldset>
              <ol>
                <li>
                  <h3>Prioritize Experiences</h3>
                  <p className="push-double--bottom">
                    Change the order of each experience to control what visitors
                    are bucketed into what experiences. You can group
                    experiences here as well, by dragging one onto another.
                    Grouped experiences will have traffic split between them
                    randomly.
                  </p>
                </li>

                <Sortable
                  defaultValue={sortablePriorityGroups.toJS()}
                  renderItem={this.renderSortableItem}
                  allowGrouping={true}
                  onChange={this.handleSortableChange}
                  testSection="experiment-priorities-sortable"
                />
              </ol>
            </fieldset>
          </form>
          <div className="border--top soft--ends push-quad--top">
            <ButtonRow
              rightGroup={[
                <Button
                  key="Cancel"
                  style="plain"
                  isDisabled={isSaving}
                  testSection="experiment-priorities-cancel-button"
                  onClick={this.handleCancel}>
                  Cancel
                </Button>,
                <Button
                  key="Save"
                  style="highlight"
                  isDisabled={
                    isSaving || LayerFns.isLayerReadonly(currentLayer)
                  }
                  testSection="experiment-priorities-save-button"
                  onClick={this.handleSubmit}>
                  Save
                </Button>,
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ExperimentPrioritiesDialog;
