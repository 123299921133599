// eslint disable below required due to legacy code importing ui actions
/* eslint-disable import/no-cycle */
/**
 * Audience Combinations Builder:
 * - This component is meant for use throughout Web and Full Stack and is meant to replace all
 *   other Audience Picker / Selector / Configuration components
 * - Inspired by the React docs, this component allows for parent refresh via a React ref
 *   Learn more at https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#alternative-2-reset-uncontrolled-component-with-an-instance-method
 * - While deferring state management to the parent doesn't feel perfect, it allows us to much more effectively manage
 *   updating this component for cases of dirty state checking and reverting
 * - While the backend provides and expects stringified JSON for audience_conditions, this component expects and saves
 *   audienceConditions in an Immutable.List format. The deserialization happens in layer_experiment/entity_definition.js
 *   and the cleanup happens in layer_experiment/actions.js' save() method
 * - If audienceConditions is undefined (e.g. for new Feature Experiments), the defaultAudienceMatchType will be used to build the Audience structure
 * - When onSelectionChange is called, audienceConditions {Immutable.List} and isValidAudienceConfig {Boolean} will be passed.
 *   audienceConditions should be converted via the Immutable toJS() method before being saved and
 *   isValidAudienceConfig should be used to prevent save when false. This is especially important because an invalid
 *   audienceConfig will actually pass audienceConditions as an empty Immutable.List, which could cause some confusing outcomes
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

import {
  Attention,
  Button,
  Col,
  Container,
  Dropdown as AxiomDropDown,
  HelpPopover,
  Label,
  Link,
  Pill,
  Poptip,
  Row,
  SelectDropdown,
  Tile,
} from 'optimizely-oui';

import { OptimizelyFeature } from '@optimizely/react-sdk';

import {
  Dropdown,
  DropdownContents,
  DropdownListItem,
  DropdownBlockLink,
} from 'react_components/dropdown';
import AudienceExperimentUsage from 'bundles/p13n/components/dialogs/audience_experiment_usage';

import Immutable, { toImmutable } from 'optly/immutable';
import truncate from 'optly/filters/truncate';
import { connect } from 'core/ui/decorators';
import ui from 'core/ui';

import {
  enums as AudienceEnums,
  fns as AudienceFns,
} from 'optly/modules/entity/audience';
import { fns as ExperimentCountFns } from 'optly/modules/entity/audience_experiment_count';
import { fns as LayerExperimentFns } from 'optly/modules/entity/layer_experiment';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import { actions as P13NDashboardActions } from 'bundles/p13n/modules/dashboard';
import { actions as P13NUIActions } from 'bundles/p13n/modules/ui'; // eslint-disable-line import/no-cycle
import { showSupportDialog } from 'optly/modules/support/actions';

import ErrorBoundary from 'core/ui/components/error_boundary';
import AudienceSearchPicker from 'bundles/p13n/components/audience_search_picker';
import ReactCodeMirror from 'react_components/codemirror';

import {
  fns as ComponentModuleFns,
  getters as ComponentModuleGetters,
} from '../component_module';

const { AND, OR } = AudienceEnums.ConditionGroupTypes;
const {
  ALL_AUDIENCES,
  ANY_AUDIENCES,
  CUSTOM_AUDIENCES,
} = AudienceEnums.audienceMatchTypes;
const audienceConditionsMap = {
  [ALL_AUDIENCES]: AND,
  [ANY_AUDIENCES]: OR,
};
const AUDIENCE_CONDITONS_DEV_DOC_LINK =
  'https://docs.developers.optimizely.com/full-stack/docs/set-audiences-for-experiments-and-rollouts#section-step-3-define-the-conditions';
const FULL_STACK_AUDIENCES_KB_DEFAULT_LINK =
  'https://docs.developers.optimizely.com/full-stack/docs/set-audiences-for-experiments-and-rollouts';
const CODE_MODE_TOGGLE_DISABLED_POPTIP_TEXT =
  'Due to the structure or syntax of your conditions, you can only edit this Audience combination in the Advanced code editor';

export class AudienceCombinationsBuilder extends Component {
  static displayName = 'AudienceCombinationsBuilder';

  /* eslint-disable react/sort-prop-types */
  static propTypes = {
    /**
     * Provided by @connect - See above getters for more info
     */
    audiences: PropTypes.instanceOf(Immutable.List).isRequired,
    isFlagProject: PropTypes.bool,
    isWebProject: PropTypes.bool.isRequired,
    inForm: PropTypes.bool,
    projectId: PropTypes.number.isRequired,
    shouldShowAudienceCombinationOptions: PropTypes.bool.isRequired,
    shouldShowCodeModeButton: PropTypes.bool,
    shouldShowAudienceWithHelper: PropTypes.bool,

    /**
     * Passed Props
     */
    /** Audience Names Map.  Can be derived from props or state. */
    audienceNamesMap: PropTypes.object,
    /** Audience Conditions JSON provided as an Immutable.List */
    audienceConditions: PropTypes.instanceOf(Immutable.List),
    /** Whether or not Audience can be edited */
    canEditAudience: PropTypes.bool,
    /** Default Audience Match Type of type ANY_AUDIENCES or ALL_AUDIENCES, chosen for resets and simplified dropdown */
    defaultAudienceMatchType: PropTypes.oneOf([ALL_AUDIENCES, ANY_AUDIENCES]),
    /**
     * Function that will be called with the following object when this component is updated:
     *
     * @param {Object} config
     * @param {Immutable.List} config.audienceConditions
     * @param {Boolean} config.isValidAudienceConfig
     */
    onSelectionChange: PropTypes.func,
    poptipContent: PropTypes.node,
    /**
     * Whether or not the component should wait until the audience has been successfully fetched before calling onSelectionChange.
     * Should be set to true if other elements on the page rely on the newly-added audience data being immediately available in the data store.
     * Defaults to false since this is not typically necessary in the AudienceCombinationsBuilder (except, for example, in Campaigns)
     */
    shouldWaitForAudienceFetch: PropTypes.bool,
    showEveryoneAsDefault: PropTypes.bool,
    showLabel: PropTypes.bool,
    isFullStackProject: PropTypes.bool.isRequired,
  };
  /* eslint-enable react/sort-prop-types */

  static defaultProps = {
    audienceNamesMap: null,
    audienceConditions: toImmutable([]),
    canEditAudience: true,
    isFlagProject: false,
    defaultAudienceMatchType: ANY_AUDIENCES,
    inForm: false,
    onSelectionChange: () => {},
    poptipContent: <p>Who do you want to be included in this experience?</p>,
    shouldShowCodeModeButton: true,
    shouldWaitForAudienceFetch: false,
    showEveryoneAsDefault: true,
    showLabel: true,
    shouldShowAudienceWithHelper: true,
  };

  constructor(props) {
    const { audienceConditions, audiences } = props;
    super(props);
    /* eslint-disable react/state-in-constructor */
    this.state = {
      audienceNamesMap: ComponentModuleFns.computeSelectedAudienceNamesMap(
        audienceConditions,
        audiences,
      ),
      isCodeMode: false,
      ...this.deriveComponentStateFromAudienceConditionsProp(),
    };
    /* eslint-enable react/state-in-constructor */
  }

  /**
   * Using this.props.audienceConditions, derive state in case updates are needed for audienceConditionsString, audienceMatchType, or isCodeMode
   *
   * @returns {Object}
   */
  deriveComponentStateFromAudienceConditionsProp = () => {
    const { defaultAudienceMatchType } = this.props;

    let { audienceConditions } = this.props;

    if (!audienceConditions || !audienceConditions.size) {
      audienceConditions = toImmutable([
        defaultAudienceMatchType === ANY_AUDIENCES ? OR : AND,
      ]);
    }
    const audienceMatchType = ComponentModuleFns.deriveAudienceMatchTypeFromConditions(
      audienceConditions,
    );
    return {
      ...(audienceMatchType === CUSTOM_AUDIENCES ? { isCodeMode: true } : {}),
      audienceConditionsString: ComponentModuleFns.derivePrettyStringFromImmutableList(
        audienceConditions,
      ),
      audienceConditions,
      audienceMatchType,
    };
  };

  /**
   * This method should be called from the parent via React.createRef if a revert is needed.
   * Example:
   *  audienceCombinationsBuilderRef = React.createRef()
   *  handleRevert() => {
   *    this.audienceCombinationsBuilderRef.current.reinitializeComponent();
   *  }
   *  <AudienceCombinationsBuilder ...props ref={this.audienceCombinationsBuilderRef} />
   *  <Button onClick={ this.handleRevert }>Revert</Button>
   */
  reinitializeComponent = () => {
    this.setState(this.deriveComponentStateFromAudienceConditionsProp());
  };

  /**
   * Handles calling onSelectionChange and updating component state
   *
   * @param {String} audienceMatchType
   *  Set in component state
   * @param {Immutable.List} audienceCondition
   *  Sent in onSelectionChange alongside isValidAudienceConfig
   * @param {String} audienceConditionsString
   *  Set in component state
   */
  setStateAndUpdateParent = ({
    audienceMatchType,
    audienceConditions,
    audienceConditionsString,
  }) => {
    const { canEditAudience, onSelectionChange } = this.props;

    // No-op if no edit permissions
    if (!canEditAudience) {
      return;
    }
    const audienceIds = LayerExperimentFns.deriveAudienceIdsFromAudienceConditions(
      audienceConditions,
    );
    this.setState(
      {
        audienceConditions,
        audienceMatchType,
        audienceConditionsString,
      },
      () => {
        onSelectionChange({
          audienceConditions,
          isValidAudienceConfig: this.validateAudienceConfig(
            audienceConditionsString,
            audienceIds,
          ).isValid,
        });
      },
    );
  };

  /**
   * Passes provided arguments to each function in the ComponentModuleFns.validators. If there are validation errors, it sets
   * the error and separately provides a Boolean key/value on whether the Audience config is valid.
   *
   * @param {String} audienceConditionsString
   * @param {Array} audienceIds
   * @returns {Object} { isValid: {Boolean}, validations, {Object} }
   *  Returns true if any validator returned true
   */
  validateAudienceConfig = (audienceConditionsString, audienceIds) => {
    const validations = {};

    Object.values(ComponentModuleFns.validators).forEach(fn => {
      const validation = fn({
        audienceConditionsString,
        audienceIds,
      });
      if (validation) {
        validations[fn.name] = validation;
      }
    });

    return {
      isValid: !Object.keys(validations).length,
      validations,
    };
  };

  /**
   * Component Handlers
   */

  /**
   * Adds the audience specified by audienceId in UI Mode
   *
   * @param {Number} audienceId
   *  Audience ID to ADD
   * @param {Number} audienceName
   *  Audience Name
   */
  handleAddAudience = (audienceId, audienceName) => {
    const { defaultAudienceMatchType } = this.props;
    const { audienceConditions, audienceNamesMap } = this.state;
    let { audienceMatchType } = this.state;

    let updatedAudienceConditions = audienceConditions;
    // If audience_ids nor audience_conditions have never been saved for a layerExperiment, this list will be empty
    if (
      !audienceConditions.filter(item => item instanceof Immutable.Map).size
    ) {
      audienceMatchType = defaultAudienceMatchType;
      updatedAudienceConditions = toImmutable([
        defaultAudienceMatchType === ANY_AUDIENCES ? OR : AND,
      ]);
    }
    updatedAudienceConditions = updatedAudienceConditions.push(
      toImmutable({ audience_id: audienceId }),
    );
    this.setState({
      audienceNamesMap: {
        ...audienceNamesMap,
        [audienceId]: audienceName,
      },
    });

    this.setStateAndUpdateParent({
      audienceConditions: updatedAudienceConditions,
      audienceConditionsString: ComponentModuleFns.derivePrettyStringFromImmutableList(
        updatedAudienceConditions,
      ),
      audienceMatchType,
    });
  };

  /**
   * Removes the audience specified by audienceId in UI Mode
   *
   * @param {Number} audienceId
   *  Audience ID to REMOVE
   */
  handleRemoveAudience = audienceId => {
    const { defaultAudienceMatchType } = this.props;
    const { audienceConditions } = this.state;
    let { audienceMatchType } = this.state;

    let updatedAudienceConditions = audienceConditions;
    updatedAudienceConditions = audienceConditions.filterNot(
      item =>
        item instanceof Immutable.Map && item.get('audience_id') === audienceId,
    );

    // If there are fewer than two audience_ids, we need to override this to be the defaultAudienceMatchType, which
    // will update audience_match_type and audience_conditions. This will ensure that LayerExperiments with only one
    // Audience combination leaf will be compatible with older SDKs
    if (
      ((defaultAudienceMatchType === ANY_AUDIENCES &&
        audienceMatchType === ALL_AUDIENCES) ||
        (defaultAudienceMatchType === ALL_AUDIENCES &&
          audienceMatchType === ANY_AUDIENCES)) &&
      // Detect if there are fewer than 2 condition leafs
      updatedAudienceConditions.filter(i => i instanceof Immutable.Map).size <=
        1
    ) {
      audienceMatchType = defaultAudienceMatchType;
      updatedAudienceConditions = updatedAudienceConditions.set(
        0,
        audienceMatchType === ANY_AUDIENCES ? OR : AND,
      );
    }

    this.setStateAndUpdateParent({
      audienceConditions: updatedAudienceConditions,
      audienceConditionsString: ComponentModuleFns.derivePrettyStringFromImmutableList(
        updatedAudienceConditions,
      ),
      audienceMatchType,
    });
  };

  /**
   * When Audience Conditions are updated via the Advanced code editor, convert to Immutable.List if valid and attempt to prettify to stringified JSON
   *
   * @param {String} updatedAudienceConditionsString
   */
  handleAudienceConditionsStringUpdate = updatedAudienceConditionsString => {
    const updatedAudienceConditions = ComponentModuleFns.deriveAudienceConditionsJsonFromString(
      updatedAudienceConditionsString,
    );
    const audienceConditionsString = ComponentModuleFns.derivePrettyStringFromImmutableList(
      updatedAudienceConditions,
    );
    this.setStateAndUpdateParent({
      audienceConditions: updatedAudienceConditions || toImmutable([]),
      audienceConditionsString:
        audienceConditionsString || updatedAudienceConditionsString,
      audienceMatchType: ComponentModuleFns.deriveAudienceMatchTypeFromConditions(
        updatedAudienceConditions,
      ),
    });
  };

  /**
   * If shouldShowAudienceCombinationOptions is true, the Audience Match Type Dropdown menu will be visible when in UI Mode
   *
   * @param {String} audienceMatchType
   */
  handleAudienceMatchTypeUpdate = audienceMatchType => {
    const { audienceConditions } = this.state;

    const updatedAudienceConditions = audienceConditions.set(
      0,
      audienceMatchType === ANY_AUDIENCES ? OR : AND,
    );
    const audienceConditionsString = ComponentModuleFns.derivePrettyStringFromImmutableList(
      updatedAudienceConditions,
    );
    this.setStateAndUpdateParent({
      audienceConditions: updatedAudienceConditions,
      audienceConditionsString,
      audienceMatchType,
    });
  };

  /**
   * For creating or viewing Audiences
   *
   * @param {Object} config
   * @param {Number} config.id
   *  If provided, the Audience for this ID will be fetched
   * @param {String} config.name
   *  If provided when options.id is falsy, the Audience builder will be loaded with this value as the name
   */
  handleCreateOrEditAudience = ({ id = null, name = '' }) => {
    ui.loadingStart('main');
    P13NDashboardActions.fetchOrCreateAudience(id, name).then(audience => {
      const createEditDeferred = $.Deferred();
      if (!id) {
        createEditDeferred.then(changedAudience => {
          this.handleAddAudience(changedAudience.id, changedAudience.name);
          // TODO(MGMT-2910): Fix: Allow default (empty string) search result with recent audiences to be updated upon creation of a new audience (or edit of an existing audience)
        });
      }
      P13NUIActions.showAudienceEditorDialog(
        audience,
        createEditDeferred,
      ).finally(() => {
        ui.loadingStop('main');
      });
    });
  };

  handleViewExperimentUsage = audience_id => {
    ui.showReactDialog(
      AudienceExperimentUsage,
      {
        props: {
          selectedAudienceId: audience_id,
        },
      },
      {
        isOuiDialog: true,
        fullScreen: false,
        dismissOnBack: true,
      },
    );
  };

  /**
   * Toggles UI and the Advanced code editor when audienceMatchType is not CUSTOM_AUDIENCES
   */
  handleToggleCodeMode = () => {
    const { audienceMatchType } = this.state;

    if (audienceMatchType === CUSTOM_AUDIENCES) {
      return;
    }

    this.setState(prevState => ({ isCodeMode: !prevState.isCodeMode }));
  };

  /**
   * Render Helpers
   */

  renderAudienceSelectionText = audienceSize => {
    const {
      canEditAudience,
      shouldShowAudienceCombinationOptions,
      isFlagProject,
    } = this.props;
    const { audienceMatchType, isCodeMode } = this.state;
    const showText =
      shouldShowAudienceCombinationOptions &&
      audienceSize > 0 &&
      audienceMatchType !== CUSTOM_AUDIENCES &&
      !isCodeMode;

    if (!showText) {
      return null;
    }
    const renderMatchText = () =>
      !isFlagProject ? (
        <Row paddedContent="ends">
          <Col small="fillSpace" data-test-section="audience-selection-text">
            Match the following audience:
          </Col>
        </Row>
      ) : (
        <></>
      );

    return audienceSize > 1 ? (
      <Row
        data-test-section="audience-selection-text"
        paddedContent="ends"
        verticalAlignment="center">
        <Col small="fitContent">Match</Col>
        <Col small="fitContent">
          <SelectDropdown
            buttonStyle="underline"
            isDisabled={!canEditAudience}
            items={[
              {
                activatorLabel: <strong>any</strong>,
                label: 'any',
                value: ANY_AUDIENCES,
              },
              {
                activatorLabel: <strong>all</strong>,
                label: 'all',
                value: ALL_AUDIENCES,
              },
            ]}
            onChange={value => this.handleAudienceMatchTypeUpdate(value)}
            testSection="audience-combination-dropdown"
            value={audienceMatchType}
          />
        </Col>
        <Col small="fitContent">of the following audiences:</Col>
      </Row>
    ) : (
      renderMatchText()
    );
  };

  renderEveryoneTag = () => {
    const { isFlagProject } = this.props;
    return isFlagProject ? (
      <div className="width--1-1">
        <Tile
          isDraggable={false}
          key="everyone"
          name="Everyone"
          onTileClick={() => {}}
          testSection="selected-audience-everyone"
        />
      </div>
    ) : (
      <Row paddedContent="top">
        <Col small="fillSpace">
          <Pill
            isFullWidth={true}
            hasWrap={false}
            name="Everyone"
            showWell={false}
            testSection="selected-audience-everyone"
          />
        </Col>
      </Row>
    );
  };

  renderAudiencesBasicByIds = (audienceIds, audienceSize) => {
    const {
      audienceMatchType,
      audienceNamesMap: audienceNamesMapState,
    } = this.state;
    const {
      audiences,
      canEditAudience,
      audienceNamesMap: audienceNamesMapProp,
      isWebProject,
      isFullStackProject,
      isFlagProject,
    } = this.props;
    const smallPropValue = isWebProject ? 'fillSpace' : '11';
    const isUsageInspectorEnabled = isFeatureEnabled(
      'usage_inspector_audiences',
    );
    const audienceNamesMap = audienceNamesMapProp || audienceNamesMapState;

    return audienceIds.map((audienceId, index) => {
      const isLastAudience = audienceSize === index + 1;
      const audience = audiences.find(
        audience => audience.get('id') === audienceId,
      );

      const getActionsDropdownItems = selectedIndex => {
        return [
          <>
            {canEditAudience && (
              <AxiomDropDown.ListItem
                hideOnClick={true}
                key={`${selectedIndex}-action`}>
                <AxiomDropDown.BlockLink
                  isLink={true}
                  onClick={() =>
                    this.handleCreateOrEditAudience({
                      id: audienceId,
                    })
                  }
                  testSection={`view-selected-audience-${audienceId}`}>
                  <AxiomDropDown.BlockLinkText text="Edit" />
                </AxiomDropDown.BlockLink>
              </AxiomDropDown.ListItem>
            )}
            {isWebProject && (
              <AxiomDropDown.ListItem removeBorderTop={true}>
                <OptimizelyFeature feature="usage_inspector_audiences">
                  {isEnabled =>
                    isEnabled && (
                      <AxiomDropDown.BlockLink
                        isLink={true}
                        onClick={() =>
                          this.handleViewExperimentUsage(audienceId)
                        }
                        testSection={`view-experiment-usage-audience-${audienceId}`}>
                        View Experiment Usage
                      </AxiomDropDown.BlockLink>
                    )
                  }
                </OptimizelyFeature>
              </AxiomDropDown.ListItem>
            )}
            {canEditAudience && (
              <AxiomDropDown.ListItem>
                <AxiomDropDown.BlockLink
                  onClick={() => this.handleRemoveAudience(audienceId)}
                  testSection={`delete-selected-audience-${audienceId}`}>
                  <AxiomDropDown.BlockLinkText
                    isDestructive={true}
                    text="Delete"
                  />
                </AxiomDropDown.BlockLink>
              </AxiomDropDown.ListItem>
            )}
          </>,
        ];
      };
      const renderName = () => (
        <span style={{ display: 'inline-grid' }}>
          <span
            className="truncate"
            title={audienceNamesMap[audienceId]}
            data-test-section="tile-name">
            {truncate(audienceNamesMap[audienceId], 75)}
          </span>
        </span>
      );
      const renderTile = (
        <React.Fragment key={audienceId}>
          <div className="width--1-1">
            <Tile
              description={`ID:${audienceId}`}
              {...(canEditAudience
                ? {
                    dropdownItems: getActionsDropdownItems(index),
                  }
                : {})}
              isDraggable={false}
              key={audienceId}
              name={renderName()}
              onTileClick={() => {}}
              testSection={`selected-audience-${audienceId}`}
            />

            {!isLastAudience && (
              <div className="push-half--ends">
                {audienceConditionsMap[audienceMatchType]}
              </div>
            )}
          </div>
        </React.Fragment>
      );
      const renderToken = (
        <React.Fragment key={audienceId}>
          <Row verticalAlignment="center" data-test-section="selected-audience">
            <Col small={smallPropValue}>
              <Pill
                description={`ID:${audienceId}`}
                hasWrap={false}
                isDismissible={canEditAudience}
                isFullWidth={true}
                name={truncate(audienceNamesMap[audienceId], 75)}
                onDismiss={() => this.handleRemoveAudience(audienceId)}
                showWell={false}
                testSection={`selected-audience-${audienceId}`}
              />
            </Col>
            {isWebProject && isUsageInspectorEnabled && (
              <Col small="5">
                <span className="micro muted">
                  Used in {audience.get('experiment_count')} experiments
                </span>
              </Col>
            )}
            <Col small="1">
              <Dropdown
                activator={
                  <Button
                    testSection="audience-combinations-builder-actions"
                    size="small"
                    style="outline">
                    <div className="flex flex-align--center">
                      <span className="flex flex--1">Actions</span>
                      <span className="push--left oui-arrow-inline--down" />
                    </div>
                  </Button>
                }>
                <DropdownContents direction="left" minWidth="200px">
                  <DropdownListItem hideOnClick={true}>
                    <DropdownBlockLink
                      isLink={true}
                      onClick={() =>
                        this.handleCreateOrEditAudience({
                          id: audienceId,
                        })
                      }
                      testSection={`view-selected-audience-${audienceId}`}>
                      Edit
                    </DropdownBlockLink>
                    {!isFullStackProject && (
                      <OptimizelyFeature feature="usage_inspector_audiences">
                        {isEnabled =>
                          isEnabled && (
                            <DropdownBlockLink
                              isLink={true}
                              onClick={() =>
                                this.handleViewExperimentUsage(audienceId)
                              }
                              testSection={`view-experiment-usage-audience-${audienceId}`}>
                              View Experiment Usage
                            </DropdownBlockLink>
                          )
                        }
                      </OptimizelyFeature>
                    )}
                  </DropdownListItem>
                </DropdownContents>
              </Dropdown>
            </Col>
          </Row>
          {!isLastAudience && (
            <Row paddedContent="remove" verticalAlignment="center">
              <div className="push-half--ends">
                {audienceConditionsMap[audienceMatchType]}
              </div>
            </Row>
          )}
        </React.Fragment>
      );

      return isFlagProject ? renderTile : renderToken;
    });
  };

  renderAudiencesBasic = (audienceIds, audienceSize) => {
    const { showEveryoneAsDefault, isFlagProject } = this.props;

    return (
      <Row
        verticalAlignment="center"
        paddedContent={isFlagProject ? 'none' : 'sides'}
        data-test-section="selected-audiences">
        <Col small="12">
          {(!audienceSize &&
            showEveryoneAsDefault &&
            this.renderEveryoneTag()) ||
            (!!audienceSize &&
              this.renderAudiencesBasicByIds(audienceIds, audienceSize))}
        </Col>
      </Row>
    );
  };

  renderAudiencesAdvanced = audienceIds => {
    const { audiences, canEditAudience, isFlagProject } = this.props;
    const { audienceConditionsString } = this.state;

    if (isFlagProject) {
      return (
        <Row paddedContent="top">
          <Col small="fillSpace">
            <Pill
              description="Custom code audience, use the REST API to update"
              hasWrap={false}
              isDismissible={false}
              isFullWidth={true}
              name="Advanced audience"
              showWell={false}
            />
          </Col>
        </Row>
      );
    }

    const audienceConfigValidations = this.validateAudienceConfig(
      audienceConditionsString,
      audienceIds,
    );

    return (
      <React.Fragment>
        <Row paddedContent="top">
          <Col small="fillSpace">
            <ReactCodeMirror
              isReadOnly={!canEditAudience}
              maxHeight={900}
              onChange={this.handleAudienceConditionsStringUpdate}
              options={{ lint: false }}
              testSection="audience-conditions-code-mirror"
              value={audienceConditionsString}
            />
            <div>
              For help writing condition code, see our{' '}
              <Link href={AUDIENCE_CONDITONS_DEV_DOC_LINK} newWindow={true}>
                API documentation
              </Link>
              .
            </div>
          </Col>
        </Row>
        <Row>
          <Col small="fillSpace">
            <Label>
              <span className="weight--bold">Evaluated Audiences</span>
            </Label>
            <div
              className={classNames({
                'color--bad-news': !audienceConfigValidations.isValid,
              })}
              data-test-section="evaluated-audiences">
              {!audienceConfigValidations.isValid
                ? Object.values(audienceConfigValidations.validations).join(' ')
                : AudienceFns.getPlaceHolderNameFromAudiences(
                    audienceConditionsString,
                    audiences,
                  ) || 'Everyone'}
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  render() {
    const {
      canEditAudience,
      inForm,
      isWebProject,
      isFlagProject,
      poptipContent,
      projectId,
      shouldShowAudienceCombinationOptions,
      shouldShowCodeModeButton,
      shouldWaitForAudienceFetch,
      showLabel,
      shouldShowAudienceWithHelper,
    } = this.props;
    const {
      audienceConditions,
      audienceConditionsString,
      audienceMatchType,
      isCodeMode,
    } = this.state;
    const audienceIds = LayerExperimentFns.deriveAudienceIdsFromAudienceConditions(
      audienceConditions,
    );
    const audienceSize = audienceIds.length;
    const audienceConfigValidations = this.validateAudienceConfig(
      audienceConditionsString,
      audienceIds,
    );
    const isEasyEditorDisabled =
      !audienceConfigValidations.isValid ||
      audienceMatchType === CUSTOM_AUDIENCES;

    return (
      <ErrorBoundary
        alternateContent={
          <div
            title="Something went wrong"
            data-test-section="audiences-error-boundary">
            <h3>Audiences</h3>
            There was an error loading Audiences. Please refresh the page or
            visit our Help Center if the issue persists.
            <div className="display--block push--top">
              <Button style="highlight" onClick={showSupportDialog}>
                Optimizely Help Center
              </Button>
            </div>
          </div>
        }>
        <Container fluid={false} pushRowsTop={true} pullRowPadding={true}>
          {showLabel && (
            <Row verticalAlignment="center">
              <Col small="fillSpace">
                <Label testSection="audience-label">
                  {shouldShowAudienceWithHelper && (
                    <h3
                      className="display--inline-block"
                      data-test-section="audience-title">
                      Audiences
                    </h3>
                  )}
                  {inForm && shouldShowAudienceWithHelper === false && (
                    <h3
                      className="display--inline-block flush"
                      data-test-section="audience-form-title">
                      Audiences
                    </h3>
                  )}
                  {(inForm || shouldShowAudienceWithHelper) && (
                    <HelpPopover
                      horizontalAttachment="left"
                      popoverTitle="Audiences"
                      verticalAttachment="top"
                      testSection="audiences-help-popover">
                      <React.Fragment>
                        {poptipContent}
                        {shouldShowAudienceCombinationOptions && (
                          <span data-test-section="audiences-help-popover-optional-combination-note">
                            When multiple Audiences are selected, you can choose
                            a combination of <em>any</em> or <em>all</em> of the
                            selected Audiences, or even define a custom
                            combination in the <em>Advanced code editor</em>.
                          </span>
                        )}
                      </React.Fragment>
                    </HelpPopover>
                  )}
                </Label>
              </Col>
            </Row>
          )}
        </Container>
        <Container fluid={false} pushRowsTop={true} pullRowPadding={true}>
          <Row verticalAlignment="center">
            <Col
              small={shouldShowCodeModeButton ? 6 : 12}
              data-test-section="audience-picker"
              style={{ padding: '5px 0' }}>
              {canEditAudience && audienceConfigValidations.isValid && (
                <AudienceSearchPicker
                  addAudience={audience => {
                    this.handleAddAudience(audience.id, audience.name);
                  }}
                  openCreateAudienceDialog={audienceName => {
                    this.handleCreateOrEditAudience({
                      name: audienceName,
                    });
                  }}
                  openEditAudienceDialog={audience => {
                    this.handleCreateOrEditAudience({
                      id: audience.id,
                    });
                  }}
                  projectId={projectId}
                  selectedEntityIds={audienceIds}
                  shouldWaitForAudienceFetch={shouldWaitForAudienceFetch}
                />
              )}
            </Col>
            {!isFlagProject && <Col small="fillSpace"> &nbsp;</Col>}
            <Col small="fitContent">
              {shouldShowAudienceCombinationOptions &&
                shouldShowCodeModeButton && (
                  <Poptip
                    content={CODE_MODE_TOGGLE_DISABLED_POPTIP_TEXT}
                    disable={!isEasyEditorDisabled}
                    isAnimated={false}>
                    <Button
                      isDisabled={isEasyEditorDisabled}
                      key="btn-toggle-code-mode"
                      onClick={this.handleToggleCodeMode}
                      size="small"
                      style="outline"
                      testSection="code-mode-toggle">
                      {`${isCodeMode ? 'Easy' : 'Advanced code'} editor`}
                    </Button>
                  </Poptip>
                )}
            </Col>
          </Row>
        </Container>
        <Container fluid={false} pullRowPadding={true}>
          {this.renderAudienceSelectionText(audienceSize)}
          {isCodeMode
            ? this.renderAudiencesAdvanced(audienceIds)
            : this.renderAudiencesBasic(audienceIds, audienceSize)}
          {!isWebProject &&
            audienceSize !== 0 &&
            audienceConfigValidations.isValid &&
            audienceMatchType !== ANY_AUDIENCES && (
              <Row paddedContent="top">
                <Col small="fillSpace">
                  <Attention
                    alignment="center"
                    type="brand"
                    testSection="sdk-compatibility-warning">
                    Using this audience combination requires Optimizely SDK 3.0
                    or later.{' '}
                    <Link
                      newWindow={true}
                      href={FULL_STACK_AUDIENCES_KB_DEFAULT_LINK}>
                      Learn more
                    </Link>
                  </Attention>
                </Col>
              </Row>
            )}
        </Container>
      </ErrorBoundary>
    );
  }
}

export default connect(() => ({
  audiences: CurrentProjectGetters.audiences,
  isWebProject: CurrentProjectGetters.isWebProject,
  projectId: CurrentProjectGetters.id,
  shouldShowAudienceCombinationOptions: ComponentModuleGetters.shouldShowAudienceCombinationOptions(),
  isFullStackProject: CurrentProjectGetters.isFullStackProject,
}))(AudienceCombinationsBuilder);
