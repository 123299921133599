import React from 'react';

import { Metric } from '../component_module/types';

import ConfigureMetrics from '../components/configure_metrics';
import MetricsSearchPicker from '../components/metrics_search_picker';

export interface EventsAndMetricsBuilderProps {
  /** Id of the project the user is using */
  currentProjectId: number;
  /** The status of the project the user is using; one of enums.project_status*/
  currentProjectStatus: string;
  /** If true, the implementer should provide all account project IDs the user permission for */
  canUseCrossProjectMetrics?: boolean;
  /** If true disable the edit functionality on Primary metric */
  disablePrimaryMetricEdit?: boolean;
  /** When true, the component will be in a read only state (and not allow for event search, creation, or editing)  */
  isDisabled?: boolean;
  /**
   * A function to that will be called with the updated metrics Array on child component update.
   * This and its children components' "metrics" props are controlled, so the ancestor component's state
   * is the source of truth and the parent of this component should store / modify the state.
   */
  onChange: (newSelectedMetrics: Metric[]) => void;
  /**
   * Array of Project IDs with at least one value (the current project ID).
   * Multiple project IDs can be provided and queried if the user has the CROSS_PROJECT_METRICS
   * account feature
   */
  projectIds?: number[];
  /**
   * Array of Projects with at least one value (the current project).
   * Multiple projects can be provided and queried if the user has the CROSS_PROJECT_METRICS
   * account feature
   */
  projects?: any[];
  /**
   * Currently configured metrics array (order matters!)
   * This and its children components' "selectedMetrics" props are controlled, so this value will be
   * treated as the source of truth and the ancestor of this component should store / modify the state.
   */
  selectedMetrics: Metric[];
}

const EventsAndMetricsBuilder = ({
  canUseCrossProjectMetrics = false,
  currentProjectId,
  currentProjectStatus,
  disablePrimaryMetricEdit = false,
  isDisabled = false,
  onChange,
  projectIds = [],
  projects = [],
  selectedMetrics,
}: EventsAndMetricsBuilderProps) => (
  // eslint-disable-next-line react/jsx-filename-extension
  <>
    {!isDisabled && (
      <MetricsSearchPicker
        canUseCrossProjectMetrics={canUseCrossProjectMetrics}
        currentProjectId={currentProjectId}
        currentProjectStatus={currentProjectStatus}
        onChange={onChange}
        projectIds={projectIds}
        projects={projects}
        selectedMetrics={selectedMetrics}
      />
    )}
    <ConfigureMetrics
      currentProjectId={currentProjectId}
      disablePrimaryMetricEdit={disablePrimaryMetricEdit}
      isDisabled={isDisabled}
      onChange={onChange}
      selectedMetrics={selectedMetrics}
    />
  </>
);

export default EventsAndMetricsBuilder;
