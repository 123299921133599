import {
  accountPermissions as accountFeatures,
  accountFeatureConfigs,
} from 'optly/modules/admin_account/getters';
import {
  isFullStackProject,
  project as currentProject,
} from 'optly/modules/current_project/getters';
import OptimizelyChampagneEnums from 'optly/modules/optimizely_champagne/enums';
import { isFeatureEnabled } from 'optly/modules/optimizely_champagne/getters';

import fns from './fns';

/**
 * ------------------------------------------ ACCOUNT Feature Limit Getters --------------------------------------------
 */
export const changeHistoryLimit = [
  accountFeatureConfigs,
  fns.getChangeHistoryLimit,
];

export const numberOfSeatsLimit = [
  accountFeatureConfigs,
  fns.getNumberOfSeatsLimit,
];

export const targetedRolloutsRulesLimit = [
  accountFeatureConfigs,
  fns.getTargetedRolloutsRulesLimit,
];

export const runningExperimentLimit = [
  accountFeatureConfigs,
  fns.getRunningExperimentLimit,
];

/**
 * ------------------------------------------ ACCOUNT Features ONLY Getters --------------------------------------------
 * These getters are global and don't care which project you're in.
 * Double check before adding one here, as you likely depend on information
 * about the currentProject. Those getters are in the 2nd section below.
 *
 * The expected form is:
 *   canAccountUse<Thing> = [accountFeatures, isFeatureEnabled(<Thing>), fns.canAccountUse<Thing>];
 *
 * @type {*[]}
 */
export const canAccountUseDatasources = [
  accountFeatures,
  fns.canAccountUseDatasources,
];

export const canAccountUseEdgeProjects = [
  accountFeatures,
  fns.canUseEdgeProjects,
];

export const canAccountUseMobileSDKs = [accountFeatures, fns.canUseMobileSDKs];
export const canAccountUseFeatureFlags = [
  accountFeatures,
  fns.canUseFeatureFlags,
];
export const canAccountUseProjects = [
  accountFeatures,
  fns.canUseProjects.bind(fns),
];
export const canAccountUseTeamsWorkflow = [
  accountFeatures,
  fns.canUseTeamsWorkflow,
];
export const canAccountUseFullStackSDKs = [
  accountFeatures,
  fns.canUseFullStackSDKs,
];
export const canUseOpalCopilot = [accountFeatures, fns.canUseOpalCopilot];
export const canAccountUseOverTheTopSDKs = [
  accountFeatures,
  fns.canUseOverTheTopSDKs,
];
export const canAccountUseFullStackEvents = [
  accountFeatures,
  fns.canUseFullStackEvents,
];
export const canAccountUseFeatureRollouts = [
  accountFeatures,
  fns.canUseFeatureRollouts,
];
export const canAccountUseFeatureManagement = [
  accountFeatures,
  fns.canUseFeatureManagement,
];
export const canAccountUseFullStackAdvancedSettings = [
  accountFeatures,
  fns.canUseFullStackAdvancedSettings,
];
export const canAccountUseStatSigNotificationPreferences = [
  accountFeatures,
  fns.canUseStatSigNotificationPreference,
];
export const canAccountUseFullStackExperiments = [
  accountFeatures,
  fns.canUseFullStackExperiments,
];
export const canAccountUseFeatureVariables = [
  accountFeatures,
  fns.canUseFeatureVariables,
];
export const canAccountUseAtLeastOneCustomProjectSDK = [
  canAccountUseFullStackSDKs,
  canAccountUseMobileSDKs,
  canAccountUseOverTheTopSDKs,
  (canUseFullStackSDKs, canUseMobileSDKs, canUseOverTheTopSDKs) =>
    canUseFullStackSDKs || canUseMobileSDKs || canUseOverTheTopSDKs,
];
export const canAccountUseOnlineTickets = [
  accountFeatures,
  fns.canUseOnlineTickets,
];

/** ------------------------------------------ END ACCOUNT Features ONLY -------------------------------------------- */

/**
 * --------------------------------- ACCOUNT Features & Project Capabilities Getters ----------------------------------
 * Used for checking if the Admin Account has access to a Feature and if the current project has a given capability.
 * All Features being checked should exist in exactly 2 places:
 *   BE - models/feature.py:EnumeratedFeatures
 *   FE - optly/utils/enums.js:Features
 *
 * All Project Capabilities being checked should exist in exactly 2 places:
 *   BE - models/feature.py:EnumeratedFeatures (this means it's both an Account feature and a Project Capability)
 *   FE - optly/utils/enums.js:Features
 *        -OR-
 *        optly/utils/enums.js:Capabilities
 *
 * The expected form is:
 *   canUse<Thing> = [accountFeatures, currentProject, fns.canUse<Thing>];
 *
 * NOTE: The (...args) => fns.canUseSomething(...args) pattern can be used to please test stubbers
 */
/**
 * This getter is implicitly a function of the current project, due to how the
 * "targeted_rollouts" feature is configured in the app. Other getters in this section
 * examine attributes on a given project (e.g. delivery mode, project type),
 * whereas this getter examines the identity of the project itself.
 */
export const canCurrentProjectUseTargetedRollouts = [
  accountFeatures,
  isFeatureEnabled('targeted_rollouts'),
  fns.canCurrentProjectUseTargetedRollouts,
];
export const canMultivariateTestInX = [
  accountFeatures,
  currentProject,
  (...args) => fns.canMultivariateTestInX(...args),
];
export const canSetImageCdnHostPrefix = [
  accountFeatures,
  currentProject,
  fns.canSetImageCdnHostPrefix,
];
export const canTargetBehavior = [
  accountFeatures,
  currentProject,
  fns.canTargetBehavior,
];
export const canUseAdaptiveAudiences = [
  accountFeatures,
  currentProject,
  (...args) => fns.canUseAdaptiveAudiences(...args),
];
export const canUseABTestingV2 = [
  accountFeatures,
  (...args) => fns.canUseABTestingV2(...args),
];
export const canUseAdvancedPageSettings = [
  currentProject,
  fns.canUseAdvancedPageSettings,
];
export const canUseAnalyticsIntegrationExtensions = [
  accountFeatures,
  currentProject,
  (...args) => fns.canUseAnalyticsIntegrationExtensions(...args),
];
export const canUseAsyncChanges = [
  currentProject,
  (...args) => fns.canUseAsyncChanges(...args),
];
export const canUseChangeDependencies = [
  currentProject,
  (...args) => fns.canUseChangeDependencies(...args),
];
export const canUseCollaborators = [
  accountFeatures,
  currentProject,
  fns.canUseCollaborators,
];
export const canUseCrossProjectMetrics = [
  accountFeatures,
  currentProject,
  (...args) => fns.canUseCrossProjectMetrics(...args),
];
export const canUseCustomAttributes = [
  accountFeatures,
  currentProject,
  (...args) => fns.canUseCustomAttributes(...args),
];
export const canUseCustomSegments = [
  accountFeatures,
  currentProject,
  (...args) => fns.canUseCustomSegments(...args),
];
export const canUseDatafileAuthentication = [
  accountFeatures,
  currentProject,
  isFeatureEnabled(
    OptimizelyChampagneEnums.FEATURES.datafile_authentication.FEATURE_KEY,
  ),
  (...args) => fns.canUseDatafileAuthentication(...args),
];
export const canEnableDatafileAuthentication = [
  isFeatureEnabled(
    OptimizelyChampagneEnums.FEATURES.enable_datafile_authentication
      .FEATURE_KEY,
  ),
  (...args) => fns.canEnableDatafileAuthentication(...args),
];
export const canUseDatasources = [
  accountFeatures,
  currentProject,
  fns.canUseDatasources,
];
export const canUseEdgeProjectSettings = [
  currentProject,
  fns.canUseEdgeProjectSettings,
];
export const canUseEventExtensions = [
  accountFeatures,
  currentProject,
  fns.canUseEventExtensions,
];
export const canUseExperiments = [
  accountFeatures,
  currentProject,
  (...args) => fns.canUseExperiments(...args),
];
export const canUseFeatureTests = [
  accountFeatures,
  isFullStackProject,
  fns.canUseFeatureTests,
];
export const canUseLayerIntegrations = [
  currentProject,
  fns.canUseLayerIntegrations,
];
export const canUseListAttributes = [
  accountFeatures,
  currentProject,
  (...args) => fns.canUseListAttributes(...args),
];
export const canUseMultiArmedBandits = [
  accountFeatures,
  currentProject,
  (...args) => fns.canUseMultiArmedBandits(...args),
];
export const canUseContextualMultiArmedBandits = [
  accountFeatures,
  currentProject,
  (...args) => fns.canUseContextualMultiArmedBandits(...args),
];
export const canUseMutex = [
  accountFeatures,
  currentProject,
  (...args) => fns.canUseMutex(...args),
];
export const canUsePersonalization = [
  accountFeatures,
  currentProject,
  (...args) => fns.canUsePersonalization(...args),
];
export const canUsePersonalizationOnly = [
  accountFeatures,
  currentProject,
  (...args) =>
    !fns.canUseExperiments(...args) && fns.canUsePersonalization(...args),
];
export const canUsePartialFactorial = [
  accountFeatures,
  currentProject,
  (...args) => fns.canUsePartialFactorial(...args),
];
export const canUsePlugins = [
  accountFeatures,
  currentProject,
  (...args) => fns.canUsePlugins(...args),
];
export const canUsePreview = [currentProject, fns.canUsePreview];
export const canUseProjectJS = [
  accountFeatures,
  currentProject,
  fns.canUseProjectJS,
];
export const canUseEventInspector = [
  accountFeatures,
  currentProject,
  isFeatureEnabled(
    OptimizelyChampagneEnums.FEATURES.event_inspector.FEATURE_KEY,
  ),
  (...args) => fns.canUseEventInspector(...args),
];
export const canUseFullStackFlagDecisions = [
  accountFeatures,
  currentProject,
  isFeatureEnabled(
    OptimizelyChampagneEnums.FEATURES.send_flag_decisions.FEATURE_KEY,
  ),
  (...args) => fns.canUseFullStackFlagDecisions(...args),
];
export const canManageRecommendations = [
  currentProject,
  (...args) => fns.canManageRecommendations(...args),
];
export const canUseRecommendationsDashboard = [
  accountFeatures,
  currentProject,
  fns.canUseRecommendations,
];
export const canUseScheduler = [
  accountFeatures,
  currentProject,
  fns.canUseScheduler,
];
export const canUseSelectContainer = [
  accountFeatures,
  currentProject,
  fns.canUseSelectContainer,
];
export const canUseShareProjects = [
  accountFeatures,
  currentProject,
  (...args) => fns.canUseShareProjects(...args),
];
export const canUseStatsAccelerator = [
  accountFeatures,
  currentProject,
  // Bandits and stats accelerator use the same feature for now
  (...args) => fns.canUseStatsAccelerator(...args),
];
export const canUseUrlTargeting = [
  accountFeatures,
  currentProject,
  fns.canUseUrlTargeting,
];
export const canUseViewActivationModeCallback = [
  currentProject,
  fns.canUseViewActivationModeCallback,
];

export const canUseViewActivationModeDomChanged = [
  currentProject,
  fns.canUseViewActivationModeDomChanged,
];
export const canUseViewActivationModeManual = [
  currentProject,
  fns.canUseViewActivationModeManual,
];
export const canUseViewActivationModePolling = [
  currentProject,
  fns.canUseViewActivationModePolling,
];
export const canUseViewActivationModeUrlChanged = [
  currentProject,
  fns.canUseViewActivationModeUrlChanged,
];
export const canUseViewConditionCustomCode = [
  currentProject,
  fns.canUseViewConditionCustomCode,
];
export const canUseViewConditionElementPresent = [
  currentProject,
  fns.canUseViewConditionElementPresent,
];
export const canUseVisualTags = [currentProject, fns.canUseVisualTags];
export const canUseWebStickyBucketing = [
  currentProject,
  fns.canUseWebStickyBucketing,
];
export const canUseXWeb = [accountFeatures, currentProject, fns.canUseXWeb];

// Features that are more used as directives ("do this" or "dont do this").
export const shouldDisableEditorIframePreload = [
  accountFeatures,
  currentProject,
  fns.shouldDisableEditorIframePreload,
];
export const shouldUpsellPersonalization = [
  currentProject,
  fns.shouldUpsellPersonalization,
];
export const canUseLayerTableRowActions = [
  accountFeatures,
  currentProject,
  (...args) => fns.canUseLayerTableRowActions(...args),
];

export const canUseFXChangeHistoryWebhooks = [
  accountFeatures,
  currentProject,
  (...args) => fns.canUseFXChangeHistoryWebhooks(...args),
];

/* ----------------------------- END ACCOUNT Features & Project Capabilities Getters -------------------------------- */

/**
 * ------------------------------------------ PROJECT Permissions Getters --------------------------------------------
 * Used for checking CRUD permissions for an individual USER based on the current PROJECT's project_permissions field.
 * All permissions being checked should exist in exactly 2 places:
 *   BE - models/role.py:EnumeratedPermissions
 *   FE - optly/utils/enums.js:Permissions
 *
 * The expected form is:
 *   can<View|Edit|Update|Manage|Delete><Thing> = [currentProject, fns.can<View|Edit|Update|Manage|Delete><Thing>];
 */

export const canCreateCustomAttribute = [
  currentProject,
  fns.canCreateCustomAttribute,
];
export const canCreateExperiment = [
  currentProject,
  (...args) => fns.canCreateExperiment(...args),
];
export const canCreateListAttributes = [
  currentProject,
  fns.canCreateListAttributes,
];
export const canCreateLayer = [
  currentProject,
  (...args) => fns.canCreateLayer(...args),
];
export const canDeleteCustomAttribute = [
  currentProject,
  fns.canDeleteCustomAttribute,
];
export const canEditCustomAttribute = [
  currentProject,
  fns.canEditCustomAttribute,
];
export const canEditListAttributes = [
  currentProject,
  fns.canEditListAttributes,
];
export const canUseAIWebCopyVariations = [
  accountFeatures,
  currentProject,
  fns.canUseAIWebCopyVariations,
];

/* --------------------------------------- END PROJECT Permissions Getters ------------------------------------------ */

export default {
  /* ACCOUNT FeatureConfigs ONLY Getters */
  changeHistoryLimit,
  numberOfSeatsLimit,
  targetedRolloutsRulesLimit,
  runningExperimentLimit,

  /* ACCOUNT Features ONLY Getters */
  canAccountUseDatasources,
  canAccountUseEdgeProjects,
  canAccountUseMobileSDKs,
  canAccountUseFeatureFlags,
  canAccountUseProjects,
  canAccountUseTeamsWorkflow,
  canAccountUseFullStackSDKs,
  canAccountUseOverTheTopSDKs,
  canAccountUseFullStackEvents,
  canAccountUseFeatureRollouts,
  canAccountUseFeatureVariables,
  canAccountUseFeatureManagement,
  canAccountUseFullStackAdvancedSettings,
  canAccountUseStatSigNotificationPreferences,
  canAccountUseFullStackExperiments,
  canAccountUseAtLeastOneCustomProjectSDK,
  canAccountUseOnlineTickets,

  /* ACCOUNT Features & Project Capabilities Getters */
  canCurrentProjectUseTargetedRollouts,
  canMultivariateTestInX,
  canSetImageCdnHostPrefix,
  canTargetBehavior,
  canUseABTestingV2,
  canUseAdaptiveAudiences,
  canUseAdvancedPageSettings,
  canUseAnalyticsIntegrationExtensions,
  canUseAsyncChanges,
  canUseChangeDependencies,
  canUseCollaborators,
  canUseCrossProjectMetrics,
  canUseCustomAttributes,
  canUseCustomSegments,
  canEnableDatafileAuthentication,
  canUseDatafileAuthentication,
  canUseDatasources,
  canUseEdgeProjectSettings,
  canUseEventExtensions,
  canUseExperiments,
  canUseFeatureTests,
  canUseFullStackFlagDecisions,
  canUseFXChangeHistoryWebhooks,
  canUseLayerIntegrations,
  canUseListAttributes,
  canUseMultiArmedBandits,
  canUseContextualMultiArmedBandits,
  canUseMutex,
  canUseOpalCopilot,
  canUsePartialFactorial,
  canUsePersonalization,
  canUsePersonalizationOnly,
  canUsePlugins,
  canUsePreview,
  canUseProjectJS,
  canUseEventInspector,
  canUseRecommendationsDashboard,
  canUseScheduler,
  canUseSelectContainer,
  canUseShareProjects,
  canUseStatsAccelerator,
  canUseUrlTargeting,
  canUseViewActivationModeCallback,
  canUseViewActivationModeDomChanged,
  canUseViewActivationModeManual,
  canUseViewActivationModePolling,
  canUseViewActivationModeUrlChanged,
  canUseViewConditionCustomCode,
  canUseViewConditionElementPresent,
  canUseVisualTags,
  canUseWebStickyBucketing,
  canUseXWeb,
  canUseLayerTableRowActions,
  canUseAIWebCopyVariations,

  /* PROJECT Permissions Getters */
  canCreateExperiment,
  canCreateLayer,
  canCreateCustomAttribute,
  canCreateListAttributes,
  canDeleteCustomAttribute,
  canEditCustomAttribute,
  canEditListAttributes,
  canManageRecommendations,

  /* Grab bag of other project- or- account-related getters that don't fall neatly
   * into the above
   */
  shouldDisableEditorIframePreload,
  shouldUpsellPersonalization,
};
