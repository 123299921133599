import React from 'react';
import { Button, Code, Input, Sheet, Disclose } from '@optimizely/axiom';
import sprintf from 'sprintf';
import PropTypes from 'prop-types';

import ui from 'core/ui';
import Immutable, { toImmutable } from 'optly/immutable';
import regexUtils from 'optly/utils/regex';
import { connect } from 'core/ui/decorators';
import ProjectEnums from 'optly/modules/entity/project/enums';
import LoadingOverlay from 'react_components/loading_overlay';
import CodeSamplePicker from 'bundles/p13n/components/code_sample_picker';
import { fns as PermissionsFns } from 'optly/modules/permissions';
import { getters as AdminAccountGetters } from 'optly/modules/admin_account';
import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import SectionModuleGetters from 'bundles/p13n/sections/oasis_implementation/section_module/getters';
import SectiontModuleFns from 'bundles/p13n/sections/oasis_implementation/section_module/fns';
import OasisImplementationEnums from 'bundles/p13n/sections/oasis_implementation/section_module/enums';
import EventProperties from 'bundles/p13n/components/event_properties';
import {
  populateEventPropertiesFromRequest,
  cleanEventPropertiesForRequest,
} from 'bundles/p13n/components/event_properties/utils';

@connect({
  currentProjectEvents: SectionModuleGetters.currentProjectEvents,
  canCreateEvent: [
    CurrentProjectGetters.project,
    PermissionsFns.canCreateUserEvent,
  ],
  isFx: CurrentProjectGetters.isFlagsProject,
  isMobileOnly: [
    AdminAccountGetters.accountPermissions,
    PermissionsFns.isMobileOnlyAccount,
  ],
})
class EventConfigDialog extends React.Component {
  static componentId = 'oasis-event-config';

  state = {
    editingEvent: this.props.event,
    errors: toImmutable([]),
    isSaving: false,
    isKeyFieldTouched: false,
    submitCount: 0,
  };

  static propTypes = {
    canCreateEvent: PropTypes.bool,
    currentProjectEvents: PropTypes.instanceOf(Immutable.List),
    event: PropTypes.instanceOf(Immutable.Map).isRequired,
    isEditing: PropTypes.bool,
    isFx: PropTypes.bool,
    isMobileOnly: PropTypes.bool,
    language: PropTypes.string,
    onSaveEvent: PropTypes.func,
    syntaxHighlightingLanguage: PropTypes.string,
  };

  static defaultProps = {
    isFx: false,
  };

  componentDidMount = () => {
    const event = this.props.event.toJS();
    const editingEvent = populateEventPropertiesFromRequest(event);

    this.setState({
      editingEvent: toImmutable(editingEvent),
    });
  };

  onSave = eventToSave => {
    const saveDef = this.props.onSaveEvent(eventToSave).then(savedEvent => {
      ui.hideDialog();
      return savedEvent;
    });

    ui.loadingWhen('save-oasis-event', saveDef);
    return saveDef;
  };

  handleEventNameChange = inputChangeEvent => {
    const { submitCount } = this.state;
    const newEventName = inputChangeEvent.target.value;
    let newEventKey;
    // if creating new event and user has not touched key field
    // auto-fill event key with suggested key value based on event name
    if (!this.props.isEditing && !this.state.isKeyFieldTouched) {
      newEventKey = SectiontModuleFns.getFormattedEventAPIName(
        inputChangeEvent.target.value,
      );
    } else {
      newEventKey = this.state.editingEvent.get('api_name');
    }
    this.setState(
      prevState => ({
        editingEvent: prevState.editingEvent
          .set('name', newEventName)
          .set('api_name', newEventKey),
      }),
      () => {
        // Callback function that will be executed after setState
        if (submitCount > 0) {
          this.validate();
        }
      },
    );
  };

  handleEventKeyChange = inputChangeEvent => {
    const { submitCount } = this.state;
    const newEventKey = inputChangeEvent.target.value;
    this.setState(
      prevState => ({
        editingEvent: prevState.editingEvent.set('api_name', newEventKey),
        isKeyFieldTouched: true,
      }),
      () => {
        if (submitCount > 0) {
          this.validate();
        }
      },
    );
  };

  handleEventDescriptionChange = inputChangeEvent => {
    const newEventDescription = inputChangeEvent.target.value;
    this.setState(prevState => ({
      editingEvent: prevState.editingEvent.set(
        'description',
        newEventDescription,
      ),
    }));
  };

  save = () => {
    const editingEvent = this.state.editingEvent.toJS();
    const cleanEvent = cleanEventPropertiesForRequest(editingEvent);

    this.setState(prevState => ({
      submitCount: prevState.submitCount + 1,
    }));
    if (this.validate()) {
      this.setState({
        isSaving: true,
      });

      this.onSave(cleanEvent).always(() => {
        this.setState({
          isSaving: false,
        });
      });
    }
  };

  validate = () => {
    let errors = toImmutable({});
    const name = this.state.editingEvent.get('name');
    const apiName = this.state.editingEvent.get('api_name');
    const eventProperties = this.state.editingEvent
      .get('event_properties')
      .toJS();
    const hasEmptyProperties = eventProperties.some(
      ({ value, type }) => !value || !type,
    );

    if (!apiName) {
      errors = errors.set('api_name', 'This field is required.');
    } else if (regexUtils.whiteSpaceOnly.test(apiName)) {
      errors = errors.set('api_name', 'Key must not consist of only spaces.');
    } else if (!regexUtils.eventAPIName.test(apiName)) {
      errors = errors.set(
        'api_name',
        'Key must be no longer than 64 characters and consist of only alphanumeric characters, hyphens, underscores, spaces, and periods.',
      );
    } else if (this.isKeyNonUnique()) {
      errors = errors.set(
        'api_name',
        `Key ${apiName} is already in use by another event in this project. Please choose a unique key.`,
      );
    }
    if (!name) {
      errors = errors.set('name', 'This field is required.');
    } else if (regexUtils.whiteSpaceOnly.test(name)) {
      errors = errors.set('name', 'Name must not consist of only spaces.');
    } else {
      const duplicateEvent = this.getDuplicateEvent();
      if (duplicateEvent) {
        errors = errors.set(
          'name',
          `This name ${name} is already in use by another UserEvent (id: ${duplicateEvent.get(
            'id',
          )}. Please provide a unique value.`,
        );
      }
    }
    if (hasEmptyProperties) {
      errors = errors.set(
        'event_properties',
        'Please enter a name and data type for all properties.',
      );
    }
    this.setState({
      errors,
    });
    return !errors.size;
  };

  getDuplicateEvent = () =>
    this.props.currentProjectEvents.find(
      event =>
        event.get('name') === this.state.editingEvent.get('name') &&
        event.get('id') !== this.state.editingEvent.get('id'),
    );

  isKeyNonUnique = () =>
    this.props.currentProjectEvents.some(
      event =>
        event.get('api_name') === this.state.editingEvent.get('api_name') &&
        event.get('id') !== this.state.editingEvent.get('id'),
    );

  // eslint-disable-next-line class-methods-use-this
  getCodeBlock = (language, eventKey) => {
    if (eventKey) {
      return sprintf(OasisImplementationEnums.CODE_BLOCKS[language], eventKey);
    }
    return sprintf(OasisImplementationEnums.CODE_BLOCKS[language], '');
  };

  renderCodeBlock = () => {
    const eventKey = this.state.editingEvent.get('api_name');
    const { language, isMobileOnly, syntaxHighlightingLanguage } = this.props;

    let content;
    if (!ProjectEnums.mobileAndOTTSDKs.includes(language)) {
      content = (
        <CodeSamplePicker
          description={tr(
            'Choose your preferred SDK to view sample code. Copy and paste the code to track the event in your application.',
          )}
          getCodeSample={this.renderEventCode}
          showMobileOnly={isMobileOnly}
          title={tr('Event Tracking Code')}
        />
      );
    } else {
      const codeBlock =
        this.getCodeBlock(language, eventKey) ||
        this.getCodeBlock(ProjectEnums.sdkLanguages.PYTHON, eventKey);

      content = (
        <div>
          <h5 className="weight--normal">Tracking Code</h5>
          <Code
            isHighlighted={true}
            hasCopyButton={true}
            language={syntaxHighlightingLanguage}
            type="block"
            testSection="oasis-event-code-block">
            {codeBlock}
          </Code>
        </div>
      );
    }

    return (
      <div className="push-double--top">
        <Disclose title="API tracking code">{content}</Disclose>
      </div>
    );
  };

  renderEventCode = codeSampleLanguage => {
    const eventKey = this.state.editingEvent.get('api_name');
    const codeBlock =
      this.getCodeBlock(codeSampleLanguage, eventKey) ||
      this.getCodeBlock(ProjectEnums.sdkLanguages.PYTHON, eventKey);

    return (
      <Code
        isHighlighted={true}
        hasCopyButton={true}
        language={
          ProjectEnums.sdkSyntaxLanguage[codeSampleLanguage] ||
          codeSampleLanguage
        }
        type="block"
        testSection="event-code-sample">
        {codeBlock}
      </Code>
    );
  };

  updateEventProperties = updatedProperties => {
    return new Promise(resolve => {
      this.setState(
        prevState => ({
          editingEvent: prevState.editingEvent.set(
            'event_properties',
            toImmutable(updatedProperties),
          ),
        }),
        () => {
          resolve(this.state.editingEvent.get('event_properties').toJS());
        },
      );
    });
  };

  getFormTitle = () => (this.props.isEditing ? 'Edit Event' : 'New Event');

  getPrimaryButtonText = () =>
    this.props.isEditing ? 'Save Event' : 'Create Event';

  render() {
    const { canCreateEvent, isFx } = this.props;
    const { editingEvent, errors, isSaving } = this.state;
    const editingEventExperimentCount =
      editingEvent.get('experiment_count') || 0;

    return (
      <div className="reading-column">
        <Sheet
          title={this.getFormTitle()}
          onClose={ui.hideDialog}
          testSection="oasis-event-config-dialog"
          hasRequiredFieldsIndicator={true}
          subtitle={
            <p>
              Custom tracking events allow you to capture and report on visitor
              actions or events.{' '}
              <a href="https://support.optimizely.com/hc/en-us/articles/4410289407885-Metrics-in-Optimizely">
                Learn more.
              </a>
            </p>
          }
          footerButtonList={[
            <Button
              key="btn-cancel"
              style="plain"
              testSection="oasis-config-event-cancel"
              onClick={ui.hideDialog}>
              Cancel
            </Button>,
            <Button
              key="btn-save"
              style="highlight"
              testSection="oasis-config-event-save"
              isDisabled={!canCreateEvent || isSaving}
              onClick={this.save}>
              {this.getPrimaryButtonText()}
            </Button>,
          ]}>
          <LoadingOverlay loadingId="save-oasis-event">
            <div data-test-section="oasis-event-config">
              <form>
                <fieldset>
                  <ol className="lego-form-fields">
                    <li className="lego-form-field__item">
                      <Input
                        displayError={errors.has('name')}
                        id="event-name"
                        isRequired={true}
                        label="Event Name"
                        note={errors.get('name')}
                        onChange={this.handleEventNameChange}
                        placeholder="Enter an Event Name"
                        testSection="oasis-config-event-name"
                        type="text"
                        value={editingEvent.get('name') || ''}
                      />
                    </li>
                    <li className="lego-form-field__item">
                      <Input
                        displayError={errors.has('api_name')}
                        id="event-key"
                        isRequired={true}
                        label="Event Key"
                        note={errors.get('api_name')}
                        onChange={this.handleEventKeyChange}
                        placeholder="Enter an Event Key"
                        testSection="oasis-config-event-key"
                        type="text"
                        value={editingEvent.get('api_name') || ''}
                      />
                    </li>
                    <li className="lego-form-field__item">
                      <Input
                        defaultValue={editingEvent.get('description')}
                        placeholder="Add a description"
                        id="event-description"
                        label="Description"
                        onChange={this.handleEventDescriptionChange}
                        testSection="oasis-config-event-description"
                      />
                    </li>
                  </ol>
                  <br />
                  <EventProperties
                    eventProperties={editingEvent
                      .get('event_properties')
                      .toJS()}
                    eventPropertiesError={errors.get('event_properties')}
                    isEventUsedInExperiment={editingEventExperimentCount > 0}
                    isFx={isFx}
                    updateEventProperties={this.updateEventProperties}
                    sectionClassNames="push-quad--top"
                  />
                  {this.renderCodeBlock()}
                </fieldset>
              </form>
            </div>
          </LoadingOverlay>
        </Sheet>
      </div>
    );
  }
}
export default EventConfigDialog;
