import RestApi from 'optly/modules/rest_api';

import definition from './entity_definition';
import enums from './enums';
import fns from './fns';

const baseEntityActions = RestApi.createEntityActions(definition);

export const {
  save,
  fetch,
  fetchAll,
  fetchPage,
  fetchAllPages,
  flush,
} = baseEntityActions;

const deletePlugin = baseEntityActions.delete;

export { deletePlugin as delete };

export function fetchActiveEventPlugins(projectId) {
  return this.fetchAll({
    project_id: projectId,
    archived: false,
    plugin_type: enums.plugin_type.EVENT,
  });
}

export function onPluginCreate(plugin, currentProjectId, snippetGlobalName) {
  const pluginToSave = fns.fillDefaults(
    plugin,
    currentProjectId,
    snippetGlobalName,
  );
  return this.save(pluginToSave);
}

export default {
  ...baseEntityActions,
  fetchActiveEventPlugins,
  onPluginCreate,
};
