import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

import flux from 'core/flux';
import ui from 'core/ui';
import Router from 'core/router';

import {
  actions as PluginLayerCountActions,
  getters as PluginLayerGetters,
} from 'optly/modules/entity/plugin_layer_count';

import EditorActions from 'bundles/p13n/modules/editor/actions';
import EditorGetters from 'bundles/p13n/modules/editor/getters';
import CurrentLayerGetters from 'bundles/p13n/modules/current_layer/getters';
import CurrentProjectGetters from 'optly/modules/current_project/getters';
import CustomCodeActions from 'bundles/p13n/modules/custom_code/actions';
import CustomCodeGetters from 'bundles/p13n/modules/custom_code/getters';
import LayerExperimentHumanReadable from 'optly/modules/entity/layer_experiment/human_readable';
import P13NUIActions from 'bundles/p13n/modules/ui/actions';
import { canUsePlugins } from 'optly/modules/permissions/getters';
import UrlHelperV2 from 'optly/services/url_helper';
import WidgetConfigSidebar from 'bundles/p13n/components/editor/sidebar/widget_config_sidebar';

import { Features } from 'optly/utils/enums';

import SidebarHeader from '../sidebar_header';
import Template from './template.html';
import ListTemplatesDialog from './list_templates_dialog';

export const ChangeSelectorSidebar = {
  replace: true,

  componentId: 'p13n-change-selector-sidebar',

  components: {
    'sidebar-header': SidebarHeader,
  },

  template: Template,

  data: {
    layerCountAvailable: false,
    layerCounts: null,
  },

  methods: {
    createDefaultChange() {
      P13NUIActions.confirmNavigation(
        this.hasDirtyCustomCode,
        LayerExperimentHumanReadable.CHANGE_TYPES.custom_code,
        () => {
          CustomCodeActions.showCustomCodePanel(false);
          EditorActions.createAndSelectNewChange('');
          CustomCodeActions.resetCustomCodeTabsDirty();
          P13NUIActions.showChangeEditorSidebar(true);
        },
      );
    },

    createInsertHTMLChange() {
      P13NUIActions.confirmNavigation(
        this.hasDirtyCustomCode,
        LayerExperimentHumanReadable.CHANGE_TYPES.custom_code,
        () => {
          CustomCodeActions.showCustomCodePanel(false);
          EditorActions.createAndSelectInsertHTMLChange();
          CustomCodeActions.resetCustomCodeTabsDirty();
          P13NUIActions.showInsertHTMLSidebar(true);
        },
      );
    },

    createInsertImageChange() {
      P13NUIActions.confirmNavigation(
        this.hasDirtyCustomCode,
        LayerExperimentHumanReadable.CHANGE_TYPES.custom_code,
        () => {
          CustomCodeActions.showCustomCodePanel(false);
          EditorActions.createAndSelectInsertImageChange();
          CustomCodeActions.resetCustomCodeTabsDirty();
          P13NUIActions.showInsertImageSidebar(true);
        },
      );
    },

    createRedirectChange() {
      P13NUIActions.confirmNavigation(
        this.hasDirtyCustomCode,
        LayerExperimentHumanReadable.CHANGE_TYPES.custom_code,
        () => {
          CustomCodeActions.showCustomCodePanel(false);
          EditorActions.createAndSelectNewRedirect();
          CustomCodeActions.resetCustomCodeTabsDirty();
          P13NUIActions.showRedirectEditorSidebar(true);
        },
      );
    },

    goToExtensionsTab() {
      P13NUIActions.confirmNavigation(
        this.hasDirtyCustomCode,
        LayerExperimentHumanReadable.CHANGE_TYPES.custom_code,
        () => {
          CustomCodeActions.resetCustomCodeTabsDirty();
          Router.go(UrlHelperV2.extensionsHome(this.currentProjectId));
        },
      );
    },

    selectWidget(widget) {
      ui.renderRegion('p13n-editor-sidebar', {
        component: WidgetConfigSidebar,
        data: {
          _widget: widget,
          _editorIframeId: this.activeFrameId,
          isNewChange: true,
        },
      });
    },

    showChangeListSidebar() {
      P13NUIActions.showChangeListSidebar(
        this.changeListSidebarComponentConfig,
      );
    },

    getExperimentUsageCount(widget) {
      if (this.layerCountAvailable) {
        try {
          const layerCount = this.layerCounts[widget.id].experiment_count;
          return `Used in ${layerCount} experiments`;
        } catch (e) {
          console.log(e);
        }
      }
      return '';
    },
    showTemplatesDialog() {
      ui.showDialog({
        component: ListTemplatesDialog,
        data: {
          templates: this.enabledWidgets,
        },
      });
    },
    isTemplatesEnabled() {
      return isFeatureEnabled(Features.USE_OPTIMIZELY_TEMPLATES);
    },
  },

  created() {
    if (isFeatureEnabled('usage_inspector_extensions')) {
      PluginLayerCountActions.fetchAll(
        {
          project_id: flux.evaluate(CurrentProjectGetters.id),
        },
        { force: true },
      )
        .then(() => {
          const pluginLayerCounts = flux.evaluate(
            PluginLayerGetters.currentProjectPluginLayerCounts,
          );
          this.layerCounts = pluginLayerCounts.toJS();
          this.layerCountAvailable = true;
        })
        .catch(error => console.log(error));
    }

    flux.bindVueValues(this, {
      activeFrameId: EditorGetters.activeFrameId,
      canUsePlugins,
      changeListSidebarComponentConfig:
        EditorGetters.changeListSidebarComponentConfig,
      currentLayer: CurrentLayerGetters.layer,
      currentProjectId: CurrentProjectGetters.id,
      enabledWidgets: EditorGetters.enabledWidgets,
      hasDirtyCustomCode: CustomCodeGetters.hasDirtyCustomCode,
      selectedVariationDisplayName: EditorGetters.selectedVariationDisplayName,
      selectedView: EditorGetters.selectedView,
    });
  },

  ready() {},
};

export default ChangeSelectorSidebar;
