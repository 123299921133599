import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Label, SelectDropdown } from '@optimizely/axiom';

import { constants as MetricConstants } from 'optly/modules/entity/metric';
import Immutable from 'optly/immutable';

import {
  getAggregatorOptions,
  getIsAbandonmentMetric,
  getScopeOptions,
  isOverallRevenueEvent,
} from './utils';
import { COMPOUND_METRIC_TYPE } from './constants';

export function Measure({ onChange, formValues, layer }) {
  const { event, type, aggregator, winningDirection, scope } = formValues;

  const isCompound = useMemo(() => type === COMPOUND_METRIC_TYPE, [type]);

  if (!event.id && !isCompound) {
    return null;
  }

  const aggregatorOptions = getAggregatorOptions(type, event);
  const scopeOptions = getScopeOptions(aggregator, layer);
  const isAbandonmentMetric = getIsAbandonmentMetric(aggregator);
  const displayForField = isOverallRevenueEvent(event);

  return (
    <div>
      <Label>Measure</Label>
      <div className="flex">
        <SelectDropdown
          buttonStyle="underline"
          items={MetricConstants.winningDirectionOptions}
          minDropdownWidth={100}
          onChange={value => onChange('winningDirection', value)}
          value={winningDirection}
          width="auto"
          testSection="winning-direction-dropdown"
        />
        <div className="muted flex flex-align--center push-half--sides">
          {' '}
          in{' '}
        </div>
        {isCompound ? (
          <span className="muted flex flex-align--center">ratio</span>
        ) : (
          [
            aggregatorOptions.length > 1 && (
              <SelectDropdown
                buttonStyle="underline"
                items={aggregatorOptions}
                minDropdownWidth={175}
                onChange={value => {
                  onChange('aggregator', value);
                  if (
                    value === MetricConstants.aggregationOptions.BOUNCE_RATE ||
                    value === MetricConstants.aggregationOptions.EXIT_RATE
                  ) {
                    onChange('scope', 'event');
                  }
                }}
                testSection="aggregator-dropdown"
                value={aggregator}
                width="auto"
              />
            ),
            aggregatorOptions.length === 1 && (
              <div className="muted flex flex-align--center">
                {aggregatorOptions[0].label}
              </div>
            ),
            !isAbandonmentMetric && (
              <div className="muted flex flex-align--center push-half--sides">
                {' '}
                per{' '}
              </div>
            ),
            scopeOptions.length > 1 && (
              <SelectDropdown
                buttonStyle="underline"
                items={scopeOptions}
                minDropdownWidth={100}
                onChange={value => onChange('scope', value)}
                testSection="scope-dropdown"
                value={scope}
                width="auto"
              />
            ),
            scopeOptions.length === 1 && !isAbandonmentMetric && (
              <div className="muted flex flex-align--center">{scope}</div>
            ),
            !displayForField && (
              <div className="flex flex-wrap">
                <div className="muted flex flex-align--center push-half--sides">
                  {' '}
                  for{' '}
                </div>
                <div className="weight--bold muted flex flex-align--center">
                  {event.name}
                </div>
                <div className="muted flex flex-align--center push-half--sides">
                  {' '}
                  event.
                </div>
              </div>
            ),
          ]
        )}
      </div>
    </div>
  );
}

Measure.propTypes = {
  formValues: PropTypes.object.isRequired,
  layer: PropTypes.instanceOf(Immutable.Map).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default { Measure };
