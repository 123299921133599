/**
 * Services layer pure functions for the views
 */
import _ from 'lodash';

import cloneDeep from 'optly/clone_deep';
import Immutable from 'optly/immutable';

import matcher from 'optly/utils/matcher';
import ConditionGroup from 'optly/models/condition_group';
import ProjectEnums from 'optly/modules/entity/project/enums';

import constants from './constants';
import enums from './enums';

const CATEGORY_OTHER = 'other';

const API_NAME_DISALLOWED_REGEXP = /[^\w]/;

let fns;

/** *************************************************************************
 *     "Dumb" Page Functions - to be deprecated when Smart pages is GA     *
 ************************************************************************** */

/**
 * Creates an empty view entity object with the supplied data
 */
export function createViewEntity(data) {
  const DEFAULTS = {
    api_name: null,
    category: CATEGORY_OTHER,
    conditions: null,
    edit_url: null,
    id: null,
    name: null,
    page_type: null,
    activation_type: null,
    platform: null,
    project_id: null,
  };

  return _.extend({}, DEFAULTS, data);
}

/**
 * Verifies that the supplied view has valid properties
 *
 * @param {Immutable.Map} view The view object
 * @param {Immutable.List} includeConditions The conditions to include
 * @returns {object}
 */
export function validateViewData(view, includeConditions) {
  const errorObject = {};
  if (!view.get('name')) {
    errorObject.name = tr('Please provide a name for the page');
  }

  if (!view.get('page_type')) {
    errorObject.page_type = tr('Please select a page type');
  }

  if (
    !includeConditions.getIn([0, 'value']) &&
    (view.get('page_type') === enums.pageTypes.SINGLE_URL ||
      view.get('page_type') === enums.pageTypes.URL_SET)
  ) {
    errorObject.url_condition = tr('Please provide a url');
  }

  if (
    !view.get('edit_url') &&
    (view.get('page_type') === enums.pageTypes.URL_SET ||
      view.get('page_type') === enums.pageTypes.GLOBAL)
  ) {
    errorObject.edit_url = tr(
      'Please provide an example url for use when editing or previewing',
    );
  }

  const apiNameError = fns.validateApiName(view);
  if (apiNameError) {
    errorObject.api_name = apiNameError;
  }

  return errorObject;
}

/**
 * Returns an error message if viewData.api_name is invalid, otherwise null
 * If the activation type is not manual, the api_name doesn't matter, so this
 * returns null in that case
 * @param {Immutable.Map} viewData
 * @return {String|null}
 */
export function validateApiName(viewData) {
  if (viewData.get('activation_type') !== enums.activationModes.MANUAL) {
    return null;
  }

  if (!viewData.get('api_name')) {
    return tr('Please provide the api name.');
  }

  if (API_NAME_DISALLOWED_REGEXP.test(viewData.get('api_name'))) {
    return tr(
      'The api name can only contain alphanumeric characters and underscores.',
    );
  }

  return null;
}

/**
 * Trims white spaces from beginning and end of URL value in URL conditions.
 *
 * @param {Immutable.List} URL conditions (include or exclude)
 * @returns {Immutable.List}
 */
export function getTrimmedUrlValues(urlConditions) {
  return urlConditions.map(condition => {
    const trimmedCondition = condition.get('value').trim();
    return condition.set('value', trimmedCondition);
  });
}

/**
 * Returns true if provided url passes url targeting conditions provided (both includes and excludes)
 * @param {string} url
 * @param {Immutable.List} includeUrlConditions
 * @param {Immutable.List} excludeUrlConditions
 */
export function validateUrlForConditions(
  url,
  includeUrlConditions,
  excludeUrlConditions,
) {
  url = url.trim();
  includeUrlConditions = fns.getTrimmedUrlValues(includeUrlConditions);
  excludeUrlConditions = fns.getTrimmedUrlValues(excludeUrlConditions);
  return (
    !fns.doesUrlMatchAny(url, excludeUrlConditions) &&
    fns.doesUrlMatchAny(url, includeUrlConditions)
  );
}

/**
 * Returns true if provided url matches any url targeting condition in the
 * array of url targeting conditions passed in
 * @param {string} url
 * @param {Immutable.List} urlTargetingConditionList
 */
export function doesUrlMatchAny(url, urlTargetingConditionList) {
  return (
    urlTargetingConditionList.size &&
    urlTargetingConditionList.some(condition =>
      matcher.matchUrl(
        url,
        condition.get('value'),
        condition.get('match_type'),
      ),
    )
  );
}

/*
 * Deserialized conditions for a view
 * Conditions are stored on the view entity as a string, for example
 * '["and",["or",{"value":"optimizely.com","match_type":"simple","type":"url"}],["not",["or",{"value":"google.com","match_type":"simple","type":"url"}]]]'
 * and is deserialized into
 * include conditions:
 * [{
 *   value: 'optimizely.com',
 *   match_type: 'simple',
 *   type: 'url',
 * }]
 * and exclude conditions:
 * [{
 *   value: 'google.com',
 *   match_type: 'simple',
 *   type: 'url',
 * }]
 * This makes it possible to add/remove/update the conditions from the UI
 * @param {String} conditionString ex. '["and",["or",{"value":"optimizely.com","match_type":"simple","type":"url"}]]'
 *
 * @returns {Object} { includeConditions: [], excludeConditions, [] }
 */
export function deserializeConditions(conditionString) {
  function ungroupUrlConditions(groupedUrlConditions, negated) {
    const urlConditions = _.find(
      groupedUrlConditions.conditions,
      condition => condition.type === 'or' && condition.negate === negated,
    );

    return urlConditions
      ? urlConditions.conditions
      : [cloneDeep(constants.DEFAULT_URL_CONDITION)];
  }

  const groupedConditions = new ConditionGroup();
  groupedConditions.load(JSON.parse(conditionString));
  if (groupedConditions.type === 'and') {
    const includeConditions = ungroupUrlConditions(groupedConditions, false);
    const excludeConditions = ungroupUrlConditions(groupedConditions, true);
    return {
      includeConditions,
      excludeConditions,
    };
  }
  return {
    includeConditions: Immutable.List(),
    excludeConditions: Immutable.List(),
  };
}
/*
 * Get the serialized conditions for a view
 * Conditions are stored in the configure view store as arrays of objects, for example
 * include conditions:
 * [{
 *   value: 'optimizely.com',
 *   match_type: 'simple',
 *   type: 'url',
 * }]
 * But they are stored on the view entity as a string, for example
 * '["and",["or",{"value":"optimizely.com","match_type":"simple","type":"url"}],["not",["or",{"value":"google.com","match_type":"simple","type":"url"}]]]
 *
 * @param {Array} includeConditions
 * @param {Array} excludeConditions
 *
 * @returns {String} conditionString ex. '["and",["or",{"value":"optimizely.com","match_type":"simple","type":"url"}]]'
 */
export function serializeConditions(includeConditions, excludeConditions) {
  function groupUrlConditions(conditions, type, negate) {
    const sanitizedUrlConditions = conditions.filter(
      condition => !!condition.value,
    );
    const groupedUrlConditions = new ConditionGroup(type);
    Array.prototype.push.apply(
      groupedUrlConditions.conditions,
      sanitizedUrlConditions,
    );
    groupedUrlConditions.negate = negate;
    return groupedUrlConditions;
  }

  // merge url conditions
  const urlConditions = new ConditionGroup('and');
  urlConditions.conditions.push(
    groupUrlConditions(
      fns.getTrimmedUrlValues(includeConditions).toJS(),
      'or',
      false,
    ),
    groupUrlConditions(
      fns.getTrimmedUrlValues(excludeConditions).toJS(),
      'or',
      true,
    ),
  );
  urlConditions.deleteEmptyConditions();

  return JSON.stringify(urlConditions.serialize());
}

/** *************************************************************************
 *                            Smart Pages Functions                        *
 ************************************************************************** */

/**
 * Creates an empty view entity object with the supplied data
 */
export function createSmartViewEntity(data) {
  const DEFAULTS = {
    api_name: null,
    category: CATEGORY_OTHER,
    conditions:
      '["and",["or",{"value":"","match_type":"simple","type":"url"}]]',
    edit_url: null,
    id: null,
    name: null,
    page_type: null,
    activation_type: enums.activationModes.IMMEDIATE,
    platform: ProjectEnums.project_platforms.WEB,
    project_id: null,
  };

  return _.extend({}, DEFAULTS, data);
}
/*
 * Get deserialized conditions for a smart pages view
 *
 * Conditions are stored on the view entity as a string, for example:
 * '["and",["or",{"value":"optimizely.com","match_type":"simple","type":"url"}]]'
 *
 * and then this is deserialized into a condition group to be worked upon
 * with the UI, for example:
 * {
 *    conditions: [
 *      {
 *          value: 'optimizely.com',
 *          match_type: 'simple',
 *          type: 'url',
 *      },
 *      ... // more condition objects
 *    ],
 *    negate: false,
 *    type: "and",
 *    ... // the methods for a conditionGroup
 *}
 *
 * @param {String} conditionString
 *
 * @returns {Object} conditionGroup
 */
export function smartDeserializeConditions(conditionString) {
  const conditionGroup = new ConditionGroup();
  conditionGroup.load(JSON.parse(conditionString));

  return conditionGroup;
}
/*
 * Get the serialized conditions for a smart pages view
 *
 * The UI works with condition groups, which are objects, for example:
 * {
 *    conditions: [
 *      {
 *          value: 'optimizely.com',
 *          match_type: 'simple',
 *          type: 'url',
 *         ... // the methods for a conditionGroup, eg serialize()
 *      },
 *      ... // more condition objects
 *    ],
 *    negate: false,
 *    type: "and",
 *    ... // the methods for a conditionGroup
 * }
 *
 * These need to get serialized into a string so they can be stored in
 * the database, for example:
 * '["and",["or",{"value":"optimizely.com","match_type":"simple","type":"url"}]]'
 *
 * @param {Object} conditionGroup
 *
 * @returns {String} conditionString
 */
export function smartSerializeConditions(conditionGroup) {
  return JSON.stringify(conditionGroup.serialize());
}

/*
 * @param {Object} conditionGroup
 *
 * @returns {String}
 */
export function createConditionGroup(
  conditionObj,
  groupType = enums.conditionGroupTypes.OR,
) {
  const newGroup = new ConditionGroup(groupType);
  if (conditionObj) {
    newGroup.load([groupType, conditionObj]);
  }
  return newGroup;
}

/*
 * @param {Immutable.Map} view
 * @param {Boolean} validateName
 *
 * @returns {Object} map of errors in view config
 */
export function validateSmartView(view, validateName) {
  const errors = {
    name: null,
    edit_url: null,
    activation_code: null,
    api_name: null,
    conditions: fns.validateConditions(view.get('conditions')),
  };

  if (!view.get('name') && validateName) {
    errors.name = tr('Please provide a name for the page.');
  }

  if (!view.get('edit_url')) {
    errors.edit_url = tr('Editor URL is required.');
  }

  if (
    (view.get('activation_type') === enums.activationModes.CALLBACK ||
      view.get('activation_type') === enums.activationModes.POLLING) &&
    !view.get('activation_code')
  ) {
    errors.activation_code = tr('A javascript function is required.');
  }

  // Only validate api name if editing existing view OR type is manual
  if (
    view.get('id') ||
    view.get('activation_type') === enums.activationModes.MANUAL
  ) {
    const apiNameError = fns.validateApiNameSmart(view);
    if (apiNameError) {
      errors.api_name = apiNameError;
    }
  }

  return errors;
}

/**
 * Returns an error message if viewData.api_name is invalid, otherwise null
 * @param {Immutable.Map} viewData
 * @return {String|null}
 */
export function validateApiNameSmart(viewData) {
  if (!viewData.get('api_name')) {
    return tr('Please provide the api name.');
  }

  if (API_NAME_DISALLOWED_REGEXP.test(viewData.get('api_name'))) {
    return tr(
      'The api name can only contain alphanumeric characters and underscores.',
    );
  }

  return null;
}

/*
 * @param {String} conditions - JSON string of conditions
 *
 * @returns {Array} list of errors in conditions
 */
export function validateConditions(conditions) {
  if (!conditions) {
    return [];
  }

  const deserializedConditions = fns.smartDeserializeConditions(conditions);

  return deserializedConditions.conditions.map(conditionGroup =>
    conditionGroup.conditions.map(condition => {
      if (condition.type === enums.conditionMatchTypes.URL) {
        const matchUrl = condition.value.trim();
        const matchType = condition.match_type;

        if (!matchUrl) {
          return 'URL is required.';
        }

        if (matchType === constants.REGEX_MATCH_TYPE) {
          try {
            const matchRegex = new RegExp(matchUrl);
            return null;
          } catch (e) {
            return 'Valid regular expression is required.';
          }
        }

        return null;
      }

      if (condition.type === enums.conditionMatchTypes.ELEMENT_PRESENT) {
        return condition.value.trim() ? null : 'CSS Selector is required.';
      }

      if (condition.type === enums.conditionMatchTypes.CUSTOM_CODE) {
        return condition.value.trim() ? null : 'Custom Code is required.';
      }
      return null;
    }),
  );
}

/*
 * Validate if url matches conditions
 * Emulates client-js url to condition matching, found at https://github.com/optimizely/client-js/blob/master/src/core/lib/condition.js#L119
 *
 * @param {String} url
 * @param {Object} conditionGroup - instance of ConditionGroup model, or object formatted as:
 *                                  { value: 'someurl.com', match_type: 'simple', type: 'url' }
 *
 * @returns {Boolean} whether url matches all conditions in conditionGroup
 */
export function smartValidateUrlForConditions(url, conditionGroup) {
  function evaluateConditions(conditions, operator) {
    let hasUndefined = false;
    const truthComparator = operator === enums.conditionGroupTypes.OR;
    for (let i = 0; i < conditions.length; i++) {
      const thisCondGroup = conditions[i];
      const isConditionGroup = thisCondGroup instanceof ConditionGroup;
      if (
        !isConditionGroup ||
        !thisCondGroup.conditions[0] ||
        thisCondGroup.conditions[0].type === enums.conditionMatchTypes.URL
      ) {
        const eachResult = fns.smartValidateUrlForConditions(
          url,
          conditions[i],
        );
        // If type is 'and', every condition must match, so if any return false, return early with false
        // If type is 'or', any condition must match, so if any return true, return early with true
        if (eachResult === truthComparator) {
          return truthComparator;
        }
        if (_.isUndefined(eachResult)) {
          hasUndefined = true;
        }
      }
    }
    // If we've evaluated all conditions
    // And type is 'and', and no condition returned false or undefined, return true (because all conditions matched)
    // And type is 'or', and no condition returned true or undefined, return false (because no conditions matched)
    if (!hasUndefined) {
      return !truthComparator;
    }
  }
  if (conditionGroup.conditions) {
    const result = evaluateConditions(
      conditionGroup.conditions,
      conditionGroup.type,
    );
    return conditionGroup.negate ? !result : result;
  }

  // If conditionGroup.conditions is undefined, conditionGroup is of format
  // { value: 'someurl.com', match_type: 'simple', type: 'url' }
  // so do the actual match validation
  return matcher.matchUrl(url, conditionGroup.value, conditionGroup.match_type);
}

/*
 * Break URL conditions with linebreak characters into conditions with single URL only
 * One condition with linebreaks can generates many conditions with single URL value
 * @param {Immutable.Map} view
 * @param {Boolean} validateName
 *
 * @returns {Object} map of errors in view config
 */
export function splitMultilineConditions(viewConfiguration) {
  const viewConfigConditions = viewConfiguration.get('conditions');
  if (viewConfigConditions === null) {
    return viewConfigConditions;
  }

  const deserializedConditions = smartDeserializeConditions(
    viewConfigConditions,
  );
  const splitConditionGroups = deserializedConditions.conditions.map(
    conditionGroup => {
      conditionGroup.conditions = conditionGroup.conditions.flatMap(
        condition => {
          const { type, match_type: matchType, value } = condition;
          return type === enums.conditionMatchTypes.URL
            ? value
                .trim()
                .split(/(?:\\r)+|(?:\\n)+|(?:\\t)+|<br>/)
                .filter(url => url)
                .map(url => {
                  return { match_type: matchType, type, value: url };
                })
            : condition;
        },
      );
      return conditionGroup;
    },
  );
  deserializedConditions.conditions = splitConditionGroups;
  return smartSerializeConditions(deserializedConditions);
}

export default fns = {
  createViewEntity,
  validateViewData,
  validateApiName,
  getTrimmedUrlValues,
  validateUrlForConditions,
  doesUrlMatchAny,
  deserializeConditions,
  serializeConditions,
  createSmartViewEntity,
  smartDeserializeConditions,
  smartSerializeConditions,
  splitMultilineConditions,
  createConditionGroup,
  validateSmartView,
  validateApiNameSmart,
  validateConditions,
  smartValidateUrlForConditions,
};
