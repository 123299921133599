const _ = require('lodash');
const diff = require('diff');

const Immutable = require('optly/immutable').default;

const Nuclear = require('nuclear-js');

const { toImmutable } = require('optly/immutable');

const { constants: LayerConstants } = require('optly/modules/entity/layer');
const LayerFns = require('optly/modules/entity/layer/fns').default;
const LayerExperimentEnums = require('optly/modules/entity/layer_experiment/enums');
const LayerExperimentFns = require('optly/modules/entity/layer_experiment/fns');
const PermissionsModuleFns = require('optly/modules/permissions/fns');
const PluginEnums = require('optly/modules/entity/plugin/enums');

const { isWinnerRolloutFeatureEnabled } = require('optly/utils/features');

const enums = require('./enums');

/**
 * Return a Map of number of lines of code change added/removed between liveCommit customCodeChange and
 * saved custom code change.
 *
 * @param  {Immutable.Map <Change>} liveCode
 * @param  {Immutable.Map <Change>} workspaceCode
 * @return {Map}
 */
exports.customCodeLinesChanged = function(liveCode, workspaceCode) {
  const liveCodeValue = (liveCode && liveCode.get('value')) || '';
  const workspaceCodeValue =
    (workspaceCode && workspaceCode.get('value')) || '';
  const codeDiff = diff.diffTrimmedLines(liveCodeValue, workspaceCodeValue);
  return _.reduce(
    codeDiff,
    (diffStats, lineDiff) => {
      if (lineDiff.removed) {
        diffStats.removed +=
          lineDiff.count || lineDiff.value.split('\n').length;
      } else if (lineDiff.added) {
        diffStats.added += lineDiff.count || lineDiff.value.split('\n').length;
      }
      return diffStats;
    },
    { added: 0, removed: 0 },
  );
};

/**
 * Given a list of layer experiments, return a list of all the changes included in it.
 * @param {Immutable.List<LayerExperiment>} layerExperiments
 * @return {Immutable.List<Change>}
 */
exports.changesForLayerExperimentList = function(layerExperiments) {
  return layerExperiments.reduce((allChanges, layerExperiment) => {
    if (
      layerExperiment.get('archived') ||
      layerExperiment.get('actual_status') === 'archived' ||
      (isWinnerRolloutFeatureEnabled() &&
        (layerExperiment.get('concluded') ||
          layerExperiment.get('actual_status') === 'concluded'))
    ) {
      return allChanges;
    }
    return allChanges.concat(
      exports.changesForLayerExperiment(layerExperiment),
    );
  }, toImmutable([]));
};

/**
 * Given a layer experiment, return a list of all the changes included in it.
 * @param {Immutable.Map} layerExperiment
 * @return {Immutable.List<Change>}
 */
exports.changesForLayerExperiment = function(layerExperiment) {
  let changes = toImmutable([]);
  if (!layerExperiment.get('variations')) {
    return changes;
  }
  layerExperiment.get('variations').forEach(variation => {
    // Fullstack has variations but no actions.
    if (variation.get('actions')) {
      variation.get('actions').forEach(action => {
        if (action.get('changes')) {
          action.get('changes').forEach(change => {
            changes = changes.push(change);
          });
        }
      });
    }
  });
  return changes;
};

/**
 * Get a list of the saved changes for a given set of layer_experiments.
 * @param  {Map} savedLayerExperiments
 * @return {Immutable.List<Change>}
 */
exports.savedChangesForCampaign = function(savedLayerExperiments) {
  if (savedLayerExperiments) {
    return exports.changesForLayerExperimentList(savedLayerExperiments);
  }
  return toImmutable([]);
};

/**
 * Get a list of the live changes for a given live_commit.
 * Optionally pass a layerExperiment Status type to get livecommit Changes from those layerExperiments
 * @param  {Map} layer
 * @param  {Map} liveCommit
 * @param  {Map} layerExperimentsMap Immutable map of experiment id to experiment
 * @param  {String} layerExperimentStatus  Status type (archived/active) to filter changes by
 * @return {Immutable.List<Change>}
 */
exports.liveChangesForCampaign = function(
  layer,
  liveCommit,
  layerExperimentsMap = {},
  layerExperimentStatus = null,
) {
  if (liveCommit) {
    // If it's a multivariate layer, we only care about the changes in the sections.
    let layerExperiments = liveCommit.get('revisions').get('layer_experiment');
    if (layerExperiments && layerExperimentsMap.size && layerExperimentStatus) {
      layerExperiments = layerExperiments.filter(layerExperiment => {
        const experimentId = layerExperiment.get('id');
        const experiment = layerExperimentsMap.get(experimentId);
        return experiment && experiment.get('status') === layerExperimentStatus;
      });
    }
    const isMultivariateTestLayer = LayerFns.isMultivariateTestLayer(layer);
    const layerExperimentOrSectionList = isMultivariateTestLayer
      ? liveCommit.get('revisions').get('experiment_section')
      : layerExperiments;
    if (layerExperimentOrSectionList) {
      // exclude archived experiments in the commit from being checked for changes
      return exports.changesForLayerExperimentList(
        layerExperimentOrSectionList,
      );
    }
  }
  return toImmutable([]);
};

/**
 * get the new (working-copy-only) changes for a given set of working copy changes and live changes
 *
 * @param  {Immutable.List<Change>} workingCopyChanges
 * @param  {Immutable.List<Change>} liveChanges
 * @return {Immutable.List<Change>} newChanges
 */
exports.newChangesForChangeList = function(workingCopyChanges, liveChanges) {
  return workingCopyChanges.filter(
    workingCopyChange =>
      !liveChanges.some(
        liveChange => liveChange.get('id') === workingCopyChange.get('id'),
      ),
  );
};

/**
 * get the modifed (working-copy and live) changes for a given set of working copy changes and live changes
 *
 * @param  {Immutable.List<Change>} workingCopyChanges
 * @param  {Immutable.List<Change>} liveChanges
 * @return {Immutable.List<Change>} modifiedChanges
 */
exports.modifiedChangesForChangeList = function(
  workingCopyChanges,
  liveChanges,
) {
  return workingCopyChanges.filter(change => {
    const correspondingLiveChange = liveChanges.find(
      liveChange => liveChange.get('id') === change.get('id'),
    );
    return (
      correspondingLiveChange && !Immutable.is(change, correspondingLiveChange)
    );
  });
};

/**
 * get the deleted (live-only) changes for a given set of working copy changes and live changes
 *
 * @param  {Immutable.List<Change>} workingCopyChanges
 * @param  {Immutable.List<Change>} liveChanges
 * @return {Immutable.List<Change>} deletedChanges
 */
exports.deletedChangesForChangeList = function(
  workingCopyChanges,
  liveChanges,
) {
  // live changes with no corresponding change in the working copy are deleted changes
  return liveChanges.filter(
    liveChange =>
      !workingCopyChanges.some(
        workingCopyChange =>
          workingCopyChange.get('id') === liveChange.get('id'),
      ),
  );
};

/**
 * get the complete changeset for a given variation
 *
 * @param  {Immutable.List<Experiment>} experiments
 * @param  {Number} variationId
 * @return {Immutable.List<Change>}
 */
exports.changesForVariation = function(layerExperiments, variationId) {
  let changes = toImmutable([]);
  if (layerExperiments && variationId) {
    let variation;
    layerExperiments.find(experiment => {
      const foundVariation = experiment
        .get('variations')
        .find(
          currentVariation =>
            currentVariation.get('variation_id') === variationId,
        );
      if (foundVariation) {
        variation = foundVariation;
        return true;
      }
      return false;
    });
    if (variation) {
      variation.get('actions').forEach(action => {
        changes = changes.concat(action.get('changes'));
      });
    }
  }
  return changes;
};

/**
 * Get the live changes list for a given action
 * @param  {Map} liveCommit
 * @param  {number} viewId
 * @param  {number} variationId
 * @return {Immutable.List<Change>}
 */
exports.liveChangesForVariation = function(liveCommit, variationId) {
  let liveChanges = toImmutable([]);
  if (liveCommit) {
    // get the layerExperiment from the live commit
    const layerExperiments = liveCommit
      .get('revisions')
      .get('layer_experiment');
    liveChanges = exports.changesForVariation(layerExperiments, variationId);
  }
  return liveChanges;
};

/**
 * get the complete changeset for a given action
 *
 * @param  {Immutable.List<LayerExperiment|Section>} layerExperimentsOrSections
 * @param  {Number} viewId
 * @param  {Number} variationId
 * @return {Immutable.List<Change>}
 */
exports.changesForAction = function(
  layerExperimentsOrSections,
  viewId,
  variationId,
) {
  if (layerExperimentsOrSections && viewId && variationId) {
    let variation;
    layerExperimentsOrSections.find(experimentOrSection => {
      const foundVariation = experimentOrSection
        .get('variations')
        .find(
          currentVariation =>
            currentVariation.get('variation_id') === variationId,
        );
      if (foundVariation) {
        variation = foundVariation;
        return true;
      }
      return false;
    });
    if (variation) {
      const action = variation
        .get('actions')
        .find(actions => actions.get('view_id') === viewId);
      const changes = action && action.get('changes');
      return changes || toImmutable([]);
    }
  }
  return toImmutable([]);
};

/**
 * Get the live changes list for a given action from all layer_experiment or experiment_section in a live commit
 * @param  {Map} liveCommit
 * @param  {number} viewId
 * @param  {number} variationId
 * @return {Immutable.List<Change>}
 */
exports.liveChangesForAction = function(liveCommit, viewId, variationId) {
  let liveChanges = toImmutable([]);
  if (liveCommit) {
    // get the layerExperiment from the live commit
    const layer = liveCommit
      .get('revisions')
      .get('layer')
      .get(0);
    const isMultivariateTestLayer = LayerFns.isMultivariateTestLayer(layer);
    const layerExperimentsOrSections = isMultivariateTestLayer
      ? liveCommit.get('revisions').get('experiment_section')
      : liveCommit.get('revisions').get('layer_experiment');
    liveChanges = exports.changesForAction(
      layerExperimentsOrSections,
      viewId,
      variationId,
    );
  }
  return liveChanges;
};

/**
 * Get the changes with type attribute, with status injected, for the given action
 *
 * @param  {List} newChanges
 * @param  {List} liveChanges
 * @param  {List} modifiedChanges
 * @param  {List} deletedChanges
 * @param  {List} listToOrderBy - List containing the known order for the action.
 * @return {Immutable.List<EditorChange>}
 */
exports.attributeChangesWithStatusForAction = function(
  newChanges,
  liveChanges,
  modifiedChanges,
  deletedChanges,
  listToOrderBy,
) {
  const changes = toImmutable([]);
  return (
    changes
      // concat the formatted new changes
      .concat(
        newChanges
          // inject the status
          .map(newChange =>
            newChange.set('status', LayerExperimentEnums.ChangeStatuses.NEW),
          ),
      )
      // concat the formatted modified changes
      .concat(
        modifiedChanges
          // inject the status
          .map(modifiedChange =>
            modifiedChange.set(
              'status',
              LayerExperimentEnums.ChangeStatuses.MODIFIED,
            ),
          ),
      )
      // concat the formatted deleted changes
      .concat(
        deletedChanges
          // inject the status
          .map(deletedChange =>
            deletedChange.set(
              'status',
              LayerExperimentEnums.ChangeStatuses.DELETED,
            ),
          ),
      )
      // concat the formatted new changes
      .concat(
        liveChanges
          // filter live changes to live-only (ie. not live-and-modified or live-and-deleted)
          // the live *status* lives with modified and deleted changes, and is distinct from the published (live) changes set
          .filter(
            liveChange =>
              // live-only => no change with matching id in modified or deleted change lists
              !modifiedChanges.some(
                modifiedChange =>
                  modifiedChange.get('id') === liveChange.get('id'),
              ) &&
              !deletedChanges.some(
                deletedChange =>
                  deletedChange.get('id') === liveChange.get('id'),
              ),
          )
          // inject the status
          .map(liveChange =>
            liveChange.set('status', LayerExperimentEnums.ChangeStatuses.LIVE),
          ),
      )
      // filter for attribute changes
      .filter(change =>
        _.includes(
          [
            LayerExperimentEnums.ChangeTypes.ATTRIBUTE,
            LayerExperimentEnums.ChangeTypes.WIDGET,
            LayerExperimentEnums.ChangeTypes.INSERT_HTML,
            LayerExperimentEnums.ChangeTypes.INSERT_IMAGE,
            LayerExperimentEnums.ChangeTypes.REDIRECT,
          ],
          change.get('type'),
        ),
      )
      .sort(exports.createActionListSortFn(listToOrderBy))
  );
};

/**
 * Given a known ordered list of changes (an action list), return a sort function that sorts
 * based on the order of the provided list.
 *
 * @param {Immutable.List} listToOrderBy - The changeset which contains the correct ordering.
 *
 * @returns {Function} The function which will sort by that list.
 */
exports.createActionListSortFn = listToOrderBy => (change1, change2) => {
  const changeIndex1 = listToOrderBy.findIndex(
    change => change.get('id') === change1.get('id'),
  );
  const changeIndex2 = listToOrderBy.findIndex(
    change => change.get('id') === change2.get('id'),
  );
  if (changeIndex1 === -1 && changeIndex2 === -1) {
    return 0; // Neither change was found, so no-op.
  }
  if (changeIndex1 === -1) {
    return 1; // Only change2 was found, so it should come before.
  }
  if (changeIndex2 === -1) {
    return -1; // Only change1 was found, so it should come before.
  }
  return changeIndex1 - changeIndex2;
};

/**
 * Return a status for the changes in variations in working & live copies of an experiment
 * @param {Immutable.Map} workingCopyExperiment
 * @param {Immutable.Map} liveCopyExperiment
 * @return {Boolean} Returns true if the experiment has any changes to its
 * variations that would require a publish to take effect
 */
function experimentHasUnpublishedVariationChanges(
  workingCopyExperiment,
  liveCopyExperiment,
) {
  const workingCopyChanges = exports.changesForLayerExperiment(
    workingCopyExperiment,
  );
  const liveCopyChanges = liveCopyExperiment
    ? exports.changesForLayerExperiment(liveCopyExperiment)
    : Immutable.List();
  const newChanges = exports.newChangesForChangeList(
    workingCopyChanges,
    liveCopyChanges,
  );
  if (newChanges.size) {
    return true;
  }
  const modifiedChanges = exports.modifiedChangesForChangeList(
    workingCopyChanges,
    liveCopyChanges,
  );
  if (modifiedChanges.size) {
    return true;
  }
  const deletedChanges = exports.deletedChangesForChangeList(
    workingCopyChanges,
    liveCopyChanges,
  );
  if (deletedChanges.size) {
    return true;
  }
  return false;
}

/**
 * Return a status for the variation weights in working & live copies of an experiment
 * @param {Immutable.Map} workingCopyExperiment
 * @param {Immutable.Map} liveCopyExperiment
 * @return {Boolean} Returns true if the experiment has any changes in traffic
 * allocation among variations that would require a publish to take effect
 */
function experimentHasUnpublishedTrafficAllocationChanges(
  workingCopyExperiment,
  liveCopyExperiment,
) {
  // If there is no live copy, traffic allocation doesn't matter
  if (!liveCopyExperiment) {
    return false;
  }
  const workingCopyVariationWeights = workingCopyExperiment
    .get('variations')
    .map(variation => variation.get('weight'));
  const liveCopyVariaitonWeights = liveCopyExperiment
    .get('variations')
    .map(variation => variation.get('weight'));
  return !Immutable.is(workingCopyVariationWeights, liveCopyVariaitonWeights);
}

/**
 * Return a status for the audiences associated with working & live copies of an experiment
 * @param {Immutable.Map} workingCopyExperiment
 * @param {Immutable.Map} liveCopyExperiment
 * @return {Boolean} Returns true if the experiment has any changes in audiences
 * that would require a publish to take effect
 */
exports.experimentHasUnpublishedAudienceChanges = function(
  workingCopyExperiment,
  liveCopyExperiment,
) {
  // If there is no live copy, audiences don't matter
  if (!liveCopyExperiment) {
    return false;
  }
  return (
    LayerExperimentFns.getAudienceConditions(workingCopyExperiment) !==
    LayerExperimentFns.getAudienceConditions(liveCopyExperiment)
  );
};

/**
 * Returns a publish status for an experiment, based on comparing changes in
 * variations, traffic allocation, and audiences between the working copy and
 * live copy (if one exists)
 * @param {Immutable.Map} workingCopyExperiment
 * @param {Immutable.Map} liveCopyExperiment
 * @return {Boolean} true if the experiment has changes that require a publish
 * to take effect
 */
exports.experimentHasUnpublishedChanges = function(
  workingCopyExperiment,
  liveCopyExperiment,
) {
  return (
    experimentHasUnpublishedVariationChanges(
      workingCopyExperiment,
      liveCopyExperiment,
    ) ||
    experimentHasUnpublishedTrafficAllocationChanges(
      workingCopyExperiment,
      liveCopyExperiment,
    ) ||
    exports.experimentHasUnpublishedAudienceChanges(
      workingCopyExperiment,
      liveCopyExperiment,
    )
  );
};

/**
 * Returns true if the variation with the given ID has changes in actions that
 * would require a publish to take effect
 * @param {Number} variationId
 * @param {Immutable.Map}
 * @param {Immutable.Map}
 * @return {Boolean}
 */
exports.variationHasUnpublishedActionChanges = function(
  variationId,
  workingCopyExperimentOrSection,
  liveCopyExperimentOrSection,
) {
  const workingVariationChanges = exports.changesForVariation(
    Immutable.List([workingCopyExperimentOrSection]),
    variationId,
  );
  let liveVariationChanges;
  if (liveCopyExperimentOrSection) {
    liveVariationChanges = exports.changesForVariation(
      Immutable.List([liveCopyExperimentOrSection]),
      variationId,
    );
  } else {
    liveVariationChanges = Immutable.List();
  }
  return (
    exports.newChangesForChangeList(
      workingVariationChanges,
      liveVariationChanges,
    ).size > 0 ||
    exports.modifiedChangesForChangeList(
      workingVariationChanges,
      liveVariationChanges,
    ).size > 0 ||
    exports.deletedChangesForChangeList(
      workingVariationChanges,
      liveVariationChanges,
    ).size > 0
  );
};

/**
 * Returns true if the variation with the given ID has changes in its traffic
 * allocation that would require a publish to take effect
 * @param {Number} variationId
 * @param {Immutable.Map}
 * @param {Immutable.Map}
 * @return {Boolean}
 */
exports.variationHasUnpublishedTrafficAllocationChanges = function(
  variationId,
  workingCopyExperimentOrSection,
  liveCopyExperimentOrSection,
) {
  if (!liveCopyExperimentOrSection) {
    return false;
  }
  const workingCopyVariation = workingCopyExperimentOrSection
    .get('variations')
    .find(variation => variation.get('variation_id') === variationId);
  const liveCopyVariation = liveCopyExperimentOrSection
    .get('variations')
    .find(variation => variation.get('variation_id') === variationId);
  if (!liveCopyVariation) {
    return false;
  }
  return workingCopyVariation.get('weight') !== liveCopyVariation.get('weight');
};

/**
 * Returns true if the variation with the given ID has changes (either in its
 * actions or traffic allocation) that would require a publish to take effect
 * @param {Number} variationId
 * @param {Immutable.Map}
 * @param {Immutable.Map}
 * @return {Boolean}
 */
exports.variationHasUnpublishedChanges = function(
  variationId,
  workingCopyExperimentOrSection,
  liveCopyExperimentOrSection,
) {
  return (
    exports.variationHasUnpublishedActionChanges(
      variationId,
      workingCopyExperimentOrSection,
      liveCopyExperimentOrSection,
    ) ||
    exports.variationHasUnpublishedTrafficAllocationChanges(
      variationId,
      workingCopyExperimentOrSection,
      liveCopyExperimentOrSection,
    )
  );
};

/**
 * Returns true if the given variation has been published with changes before,
 * and its parent experiment is not paused
 * @param {Number} variationId
 * @param {Immutable.Map} workingCopyExperimentOrSection
 * @param {Immutable.Map} liveCopyExperimentOrSection
 * @return {Boolean}
 */
exports.variationIsRunning = function(
  currentLayer,
  variationId,
  workingCopyExperimentOrSection,
  liveCopyExperimentOrSection,
) {
  // If it's a Multivariate Test, then we know that the variation is running if the variation
  // Is in the commit.
  const isMultivariateTestLayer = LayerFns.isMultivariateTestLayer(
    currentLayer,
  );
  if (
    !liveCopyExperimentOrSection ||
    (!isMultivariateTestLayer &&
      workingCopyExperimentOrSection.get('status') !==
        LayerExperimentEnums.status.ACTIVE)
  ) {
    return false;
  }
  const liveVariationChanges = exports.changesForVariation(
    Immutable.List([liveCopyExperimentOrSection]),
    variationId,
  );
  return liveVariationChanges.size > 0;
};

/**
 * Returns the same list of variations with two new properties added to them:
 * hasUnpublishedChanges (whether or not the variation has draft changes that
 * require publish to take effect), and isRunning (true if the variation has
 * been published before and its parent experiment isn't paused)
 * @param {Immutable.List} variations
 * @param {Immutable.Map} workingCopyExperimentOrSection
 * @param {Immutable.Map} liveCopyExperimentOrSection
 * @return {Immutable.List}
 */
exports.addStatusInfoToVariations = (
  currentLayer,
  variations,
  workingCopyExperimentOrSection,
  liveCopyExperimentOrSection,
) =>
  variations.map(variation => {
    const variationHasUnpublishedActionChanges = exports.variationHasUnpublishedActionChanges(
      variation.get('variation_id'),
      workingCopyExperimentOrSection,
      liveCopyExperimentOrSection,
    );
    const isRunning = exports.variationIsRunning(
      currentLayer,
      variation.get('variation_id'),
      workingCopyExperimentOrSection,
      liveCopyExperimentOrSection,
    );
    return variation
      .set('hasUnpublishedChanges', variationHasUnpublishedActionChanges)
      .set('isRunning', isRunning);
  });

/**
 * @param {Number} id
 * @param {Immutable.Map} layerExperimentMap
 * @return {Immutable.Map}
 */
exports.getVariationById = function(id, layerExperimentMap) {
  let variation;
  layerExperimentMap.find(experiment =>
    experiment.get('variations').find(currentVariation => {
      if (currentVariation.get('variation_id') === id) {
        variation = currentVariation;
        return true;
      }
    }),
  );
  return variation;
};

/**
 * Generate an html message describing how many changes of each change type are in the
 * array provided as a parameter
 * @param {Immutable.List} changeSet
 * @return {String}
 */
exports.generateChangeCountsMessage = function(changeSet) {
  const customCodeChanges = changeSet.filter(
    change =>
      change.get('type') === LayerExperimentEnums.ChangeTypes.CUSTOM_CODE ||
      change.get('type') === LayerExperimentEnums.ChangeTypes.CUSTOM_CSS,
  );
  const visualChanges = changeSet.filter(
    change =>
      change.get('type') === LayerExperimentEnums.ChangeTypes.ATTRIBUTE ||
      change.get('type') === LayerExperimentEnums.ChangeTypes.INSERT_HTML ||
      change.get('type') === LayerExperimentEnums.ChangeTypes.INSERT_IMAGE,
  );
  const widgetChanges = changeSet.filter(
    change => change.get('type') === LayerExperimentEnums.ChangeTypes.WIDGET,
  );
  const redirectChanges = changeSet.filter(
    change => change.get('type') === LayerExperimentEnums.ChangeTypes.REDIRECT,
  );

  let changeCountsMessage = '';
  if (customCodeChanges.size) {
    changeCountsMessage += `${tr.pluralize(
      '{0} variation code change',
      '{0} variation code changes',
      customCodeChanges.size,
    )}<br />`;
  }
  if (visualChanges.size) {
    changeCountsMessage += `${tr.pluralize(
      '{0} visual change',
      '{0} visual changes',
      visualChanges.size,
    )}<br />`;
  }
  if (widgetChanges.size) {
    changeCountsMessage += `${tr.pluralize(
      '{0} extension change',
      '{0} extension changes',
      widgetChanges.size,
    )}<br />`;
  }
  if (redirectChanges.size) {
    changeCountsMessage += `${tr.pluralize(
      '{0} redirect change',
      '{0} redirect changes',
      redirectChanges.size,
    )}<br />`;
  }

  return changeCountsMessage;
};

/**
 * @param {Immutable.Map} workingCopyExperiment
 * @param {Immutable.Map} liveCopyExperiment
 * @param {Immutable.Map} liveTag
 * @return {String} One of CurrentLayer.enums.snippetStatuses
 */
exports.getExperimentSnippetStatus = (
  workingCopyExperiment,
  liveCopyExperiment,
  liveTag,
) => {
  if (
    workingCopyExperiment.get('status') === LayerExperimentEnums.status.PAUSED
  ) {
    return enums.snippetStatuses.PAUSED;
  }
  if (liveTag && liveTag.get('active') === false) {
    return enums.snippetStatuses.CAMPAIGN_PAUSED;
  }
  if (!liveCopyExperiment) {
    return enums.snippetStatuses.NOT_STARTED;
  }
  return workingCopyExperiment.get('status') ===
    LayerExperimentEnums.status.ACTIVE
    ? enums.snippetStatuses.RUNNING
    : enums.snippetStatuses.NOT_STARTED;
};

/**
 * Temporary function to transform analytics integration extensions
 * to fit the UI of default integrations.
 * Will be updated, tracking in WEB-1514
 * @param {Immutable.List} enabledAnalyticsIntegrationExtensions
 * @param {Object} integrationIdToLayerSettingsMap
 * @return {Immutable.List} formatted enabledAnalyticsIntegrationExtensions
 */
exports.formatAnalyticsExtensionsToIntegrationsUI = (
  enabledAnalyticsIntegrationExtensions,
  integrationIdToLayerSettingsMap,
) =>
  enabledAnalyticsIntegrationExtensions.map(extension => {
    const fields = extension.get('form_schema').map(schema => {
      const values =
        schema.getIn(['options', 'choices']) &&
        schema.getIn(['options', 'choices']).map(choice =>
          toImmutable({
            id: choice.get('value'),
            text: choice.get('label'),
          }),
        );
      const inputType =
        schema.get('field_type') === PluginEnums.fieldType.DROPDOWN
          ? 'select'
          : schema.get('field_type');
      return toImmutable({
        label: schema.get('label'),
        name: schema.get('name'),
        inputType,
        values,
        required: false,
      });
    });
    let layerIntegration = toImmutable({
      layerLevelData: toImmutable({
        fields: toImmutable(fields),
        generalHelp: toImmutable({
          message: extension.get('description'),
        }),
      }),
      masterLabel: extension.get('name'),
      id: extension.get('plugin_id'),
    });

    if (
      Object.prototype.hasOwnProperty.call(
        integrationIdToLayerSettingsMap,
        extension.get('plugin_id'),
      )
    ) {
      const settings = integrationIdToLayerSettingsMap[
        extension.get('plugin_id')
      ].get('settings');
      // Get setting from layer first, if that doesn't exist, use the default value provided in the extension setup
      const layerSettings = {};
      extension.get('form_schema').forEach(schema => {
        if (settings.get(schema.get('name'))) {
          layerSettings[schema.get('name')] = settings.get(schema.get('name'));
        } else if (schema.get('default_value')) {
          layerSettings[schema.get('name')] = schema.get('default_value');
        }
      });
      layerIntegration = layerIntegration
        .setIn(
          ['layerLevelData', 'enabled'],
          toImmutable(
            integrationIdToLayerSettingsMap[extension.get('plugin_id')].get(
              'enabled',
            ),
          ),
        )
        .setIn(['layerLevelData', 'settings'], toImmutable(layerSettings));
    } else {
      const defaultSettings = {};
      extension.get('form_schema').forEach(schema => {
        if (schema.get('default_value')) {
          defaultSettings[schema.get('name')] = schema.get('default_value');
        }
      });
      layerIntegration = layerIntegration
        .setIn(['layerLevelData', 'enabled'], toImmutable(false))
        .setIn(['layerLevelData', 'settings'], toImmutable(defaultSettings));
    }

    return layerIntegration;
  });

/**
 * Get the default targeting type of a layer based on canUseUrlTargeting
 *
 * @param {Boolean} canUseUrlTargeting
 * @returns {String}
 */
exports.getDefaultTargetingType = canUseUrlTargeting =>
  canUseUrlTargeting
    ? LayerConstants.TargetingTypes.URL
    : LayerConstants.TargetingTypes.SAVED_PAGES;

/**
 * Returns true if there are section traffic changes to publish. Section traffic changes include:
 * 1) Adding a variation to a section
 * 2) Deleting a variation from a section
 * 3) Changing the traffic distribution between variations within one or more sections
 * 4) Adding a section
 * 5) Removing a section
 * It will return false if there's no sections in the live commit
 * @param {Immutable.List} workingCopySections
 * @param {Immutable.List} liveCommitSections
 * @return {Boolean} whether the workingCopySections and liveCommitSections have differences in traffic allocation
 */
exports.sectionsHaveTrafficChanges = (
  workingCopySections,
  liveCommitSections,
) => {
  if (!liveCommitSections || !liveCommitSections.size) {
    return false;
  }
  const liveCommitUnarchivedSections =
    liveCommitSections &&
    liveCommitSections.filter(
      liveCommitSection => !liveCommitSection.get('archived'),
    );
  const workingCopyUnarchivedSections =
    workingCopySections &&
    workingCopySections.filter(
      workingCopySection => !workingCopySection.get('archived'),
    );

  // Check if live commit section IDs and working copy section IDs for unarchived sections match.
  // If they don't match, that means means a section has either been added or removed.
  const liveCommitUnarchivedSectionIds = liveCommitUnarchivedSections
    .map(liveCommitSection => liveCommitSection.get('id'))
    .sort();
  const workingCopyUnarchivedSectionIds = workingCopyUnarchivedSections
    .map(workingCopySection => workingCopySection.get('id'))
    .sort();
  if (
    !Immutable.is(
      liveCommitUnarchivedSectionIds,
      workingCopyUnarchivedSectionIds,
    )
  ) {
    return true;
  }

  return exports.hasWeightDifferences(
    workingCopyUnarchivedSections,
    liveCommitUnarchivedSections,
  );
};

/**
 * Returns true if there are experiment traffic changes to publish. Includes:
 * 1) Stopping a variation
 * 2) Changing traffic on the variation
 * @param {Immutable.List} workingCopyExperiments
 * @param {Immutable.List} liveCommitExperiments
 * @return {Boolean} whether the workingCopyExperiments and liveCommitExperiments have different traffic
 */
exports.destinationLayerExperimentHasTrafficChanges = (
  workingCopyExperiments,
  liveCommitExperiments,
) => {
  if (!liveCommitExperiments || !liveCommitExperiments.size) {
    return false;
  }
  const liveCommitUnarchivedExperiments =
    liveCommitExperiments &&
    liveCommitExperiments.filter(
      liveCommitSection => !liveCommitSection.get('archived'),
    );
  const workingCopyUnarchivedExperiments =
    workingCopyExperiments &&
    workingCopyExperiments.filter(
      workingCopyExperiment => !workingCopyExperiment.get('archived'),
    );

  return exports.hasWeightDifferences(
    workingCopyUnarchivedExperiments,
    liveCommitUnarchivedExperiments,
  );
};

/**
 * Returns true if there are differences in weights between the working copy and live experiments or sections
 * @param {Immutable.List} workingCopyExperimentsOrSections
 * @param {Immutable.List} liveCommitExperimentsOrSections
 */
exports.hasWeightDifferences = (
  workingCopyExperimentsOrSections,
  liveCommitExperimentsOrSections,
) =>
  workingCopyExperimentsOrSections.some(workingCopyExperimentOrSection => {
    const liveCommitExperimentOrSectionWithMatchingId = liveCommitExperimentsOrSections.find(
      liveCommitExperimentOrSection =>
        liveCommitExperimentOrSection.get('id') ===
        workingCopyExperimentOrSection.get('id'),
    );
    const workingVariationWeights = workingCopyExperimentOrSection
      .get('variations')
      .map(variation => variation.get('weight'));
    const liveCommitVariationWeights =
      liveCommitExperimentOrSectionWithMatchingId &&
      liveCommitExperimentOrSectionWithMatchingId
        .get('variations')
        .map(variation => variation.get('weight'));
    return !Immutable.is(liveCommitVariationWeights, workingVariationWeights);
  });
