import sprintf from 'sprintf';

import OasisImplementationEnums from 'bundles/p13n/sections/oasis_implementation/section_module/enums';

import regexUtils from 'optly/utils/regex';

import {
  DefaultMetrics,
  MAX_EVENT_KEY_CHARACTER_LENGTH,
  ReadableMetricsFields,
  UNDEFINED,
} from './constants';

/* eslint-disable no-unused-vars */
import {
  Event,
  EventFromSearchApi,
  EventFromSearchApiType,
  Metric,
  MetricAggregatorEnum,
  MetricFieldEnum,
  MetricScopeEnum,
  MetricWinningDirectionEnum,
} from './types';
/* eslint-enable no-unused-vars */

const UNIQUE_ID_WITH_DELIMITER_CHARACTER_LENGTH = 6;

/**
 * @description A null value will prompt this being set in selected metrics component
 */
export const computeDefaultMetricFromSearchApiEvent = ({
  id,
  name,
}: {
  id: number;
  name: string;
}): Metric => {
  return {
    ...DefaultMetrics.CUSTOM,
    display_title: name,
    event_id: id,
  };
};

/* eslint-disable camelcase */
/**
 * @description Given an event from the internal api, coerce it to the search api format
 */
export const computeSearchApiEventFormatFromEvent = (
  event: Event,
): EventFromSearchApi => {
  const {
    archived,
    api_name,
    created,
    description,
    id,
    last_modified,
    name,
    project_id,
  } = event;
  return {
    archived,
    created,
    description,
    id,
    last_modified,
    name: api_name || name,
    project_id,
    type: EventFromSearchApiType.Event,
  };
};
/* eslint-enable camelcase */

/* eslint-disable no-bitwise */
/**
 * @description Adds a short unique key to end of the provided string(s)
 * @example
 *  generateOrConcatenateUniqueId() => "6f6s2"
 *  generateOrConcatenateUniqueId(['_']) => "_6f6s2"
 *  generateOrConcatenateUniqueId(['my_event_key', '_']) => "my_event_key_6f6s2"
 */
export const generateOrConcatenateUniqueId = (
  valuesToConcatenate: string[] = [],
) => {
  const fiveCharacterUniqueId = 'xfxsx'
    .replace(/[x]/g, c => {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    })
    .toLowerCase();
  return valuesToConcatenate.join('') + fiveCharacterUniqueId;
};
/* eslint-enable no-bitwise */

/**
 * @description Given the aggregator and field value, return readable name if possible or otherwise just the values themselves
 */
export const getAggregatorAndFieldDescription = (
  metricAggregator: MetricAggregatorEnum,
  metricField: MetricFieldEnum | null,
): string => {
  const aggregatorToFieldMap = ReadableMetricsFields.aggregatorAndField[
    metricAggregator
  ] as { [key: string]: string };
  const aggregatorAndFieldDescription =
    aggregatorToFieldMap && aggregatorToFieldMap[metricField || UNDEFINED];
  if (aggregatorAndFieldDescription) {
    return aggregatorAndFieldDescription;
  }
  return `${metricAggregator} ${metricField}`;
};

/**
 * @description Given a language nad eventKey, return example code
 */
export const getCodeBlock = (language: string, eventKey?: string): string => {
  const CodeBlocks = OasisImplementationEnums.CODE_BLOCKS as {
    [key: string]: string;
  };
  if (eventKey) {
    return sprintf(CodeBlocks[language], eventKey);
  }
  return sprintf(CodeBlocks[language], '');
};

/**
 * @description Returns a readable description of the metric. Also returns whether the metric configuration is advanced and therefore requires a read only UI.
 */
export const getMetricDescription = (
  metricWinningDirection: MetricWinningDirectionEnum,
  metricAggregator: MetricAggregatorEnum,
  metricField: MetricFieldEnum | null,
  metricScope: MetricScopeEnum,
  apiName = '',
): { description: string; isAdvancedConfigFromApi: boolean } => {
  const winningDirection =
    ReadableMetricsFields.winningDirection[metricWinningDirection];
  const aggregatorAndField = getAggregatorAndFieldDescription(
    metricAggregator,
    metricField,
  );
  const scope = ReadableMetricsFields.scope[metricScope];

  const eventName = apiName ? ` ${apiName}` : '';
  if (winningDirection && aggregatorAndField && scope) {
    return {
      description: `${winningDirection} in ${aggregatorAndField} per ${scope} for${eventName} event`,
      isAdvancedConfigFromApi: false,
    };
  }
  return {
    description: `Winning direction: ${metricWinningDirection}, aggregator: ${metricAggregator}, field: ${metricField}, scope: ${metricScope} for${eventName} event`,
    isAdvancedConfigFromApi: true,
  };
};

/**
 * @description Given a string returns, replaces all disallowed characters with underscores ("_")
 */
export const getFormattedEventAPIName = (apiName: string) => {
  let formattedEventAPIName = apiName.replace(
    regexUtils.eventAPINameDisallowedChars,
    '_',
  );

  if (formattedEventAPIName.length >= MAX_EVENT_KEY_CHARACTER_LENGTH) {
    formattedEventAPIName = generateOrConcatenateUniqueId([
      formattedEventAPIName.slice(
        0,
        MAX_EVENT_KEY_CHARACTER_LENGTH -
          UNIQUE_ID_WITH_DELIMITER_CHARACTER_LENGTH,
      ),
      '_',
    ]);
  }

  return formattedEventAPIName;
};

export default {
  computeDefaultMetricFromSearchApiEvent,
  computeSearchApiEventFormatFromEvent,
  generateOrConcatenateUniqueId,
  getAggregatorAndFieldDescription,
  getCodeBlock,
  getFormattedEventAPIName,
  getMetricDescription,
};
