import Nuclear from 'nuclear-js';

import { toImmutable } from 'optly/immutable';

import LocalStorageWrapper from 'optly/utils/local_storage_wrapper';
import { constants as ResultsApiConstants } from 'bundles/p13n/modules/results_api';

import actionTypes from '../action_types';
import constants from '../constants';

const INITIAL_PROMISE_STATE = {
  promises: [],
  allResolved: true,
  anyRejected: false,
};

export default Nuclear.Store({
  getInitialState() {
    return toImmutable({
      andOrSegmentCondition: constants.AND_OR.AND,
      currencyType:
        LocalStorageWrapper.getItem('currencyType') ||
        constants.currencyTypes.DOLLAR,
      differenceTypeOverride: null,
      isCleaningResultsFilters: false,
      timerange: {},
      selectedMetric: {},
      selectedSectionId: null,
      selectedSegments: [],
      selectedExperimentId: null,
      selectedExperimentIdsForFilter: [],
      selectedVariations: {},
      colorOrder: [],
      resultsQueryPromises: INITIAL_PROMISE_STATE,
      statsConfig: {
        differenceType: ResultsApiConstants.DIFFERENCE_TYPES.RELATIVE,
        epochEnabled: false,
      },
    });
  },

  initialize() {
    this.on(actionTypes.ADD_SELECTED_SEGMENT, addSelectedSegment);
    this.on(actionTypes.CLEAR_SELECTED_SEGMENTS, clearSelectedSegments);
    this.on(actionTypes.REMOVE_SELECTED_SEGMENT, removeSelectedSegment);
    this.on(actionTypes.RESULTS_SET_CURRENCY_TYPE, setCurrencyType);
    this.on(
      actionTypes.RESULTS_SET_DIFFERENCE_TYPE_OVERRIDE,
      setDifferenceTypeOverride,
    );
    this.on(actionTypes.RESULTS_SET_STATS_CONFIG, setStatsConfig);
    this.on(actionTypes.RESULTS_SET_TIMERANGE, setTimerange);
    this.on(actionTypes.RESULTS_SET_SELECTED_METRIC, setSelectedMetric);
    this.on(
      actionTypes.RESULTS_SET_SELECTED_EXPERIMENT,
      setSelectedExperimentId,
    );
    this.on(
      actionTypes.RESULTS_SET_SELECTED_EXPERIMENT_IDS_FOR_FILTER,
      setSelectedExperimentIdsForFilter,
    );
    this.on(actionTypes.RESULTS_SET_SELECTED_SECTION_ID, setSelectedSectionId);
    this.on(actionTypes.RESULTS_SET_SELECTED_VARIATION, setSelectedVariation);
    this.on(actionTypes.RESULTS_ORDER_COLORS, orderColors);
    this.on(actionTypes.RESULTS_ADD_PROMISE, addPromise);
    this.on(actionTypes.RESULTS_CLEAR_PROMISES, clearPromises);
    this.on(actionTypes.API_REQUEST_RESOLVED, reevaluatePromises);
    this.on(actionTypes.API_REQUEST_REJECTED, reevaluatePromises);
    this.on(actionTypes.SET_AND_OR_SEGMENT_CONDITION, setAndOrSegmentCondition);
    this.on(
      actionTypes.SET_IS_CLEANING_RESULTS_FILTERS,
      setIsCleaningResultsFilters,
    );
    this.on(
      actionTypes.SET_IS_CLEANING_RESULTS_FILTERS_TO_FALSE,
      setIsCleaningResultsFiltersToFalse,
    );
  },
});

function addPromise(state, payload) {
  return reevaluatePromises(
    state.setIn(
      ['resultsQueryPromises', 'promises'],
      state.getIn(['resultsQueryPromises', 'promises']).push(payload.promise),
    ),
  );
}

function addSelectedSegment(state, payload) {
  const selectedSegments = state.get('selectedSegments');

  return state.set(
    'selectedSegments',
    selectedSegments.push(toImmutable(payload.segment)),
  );
}

function clearSelectedSegments(state) {
  return state
    .set('selectedSegments', toImmutable([]))
    .set('andOrSegmentCondition', constants.AND_OR.AND);
}

function removeSelectedSegment(state, payload) {
  const selectedSegments = state.get('selectedSegments');
  const updatedSegments = selectedSegments.filterNot(
    segment =>
      segment.get('id') === payload.segment.get('id') &&
      segment.get('value') === payload.segment.get('value'),
  );

  return state.set('selectedSegments', updatedSegments);
}

function clearPromises(state) {
  return state.set('resultsQueryPromises', toImmutable(INITIAL_PROMISE_STATE));
}

function reevaluatePromises(state) {
  const promises = state.getIn(['resultsQueryPromises', 'promises']);
  let allResolved;
  let anyRejected;

  if (!promises.size) {
    allResolved = true;
    anyRejected = false;
  } else {
    allResolved = !promises.find(promise => !promise.state() === 'resolved');
    anyRejected = promises.find(promise => promise.state() === 'rejected');
  }
  return state
    .setIn(['resultsQueryPromises', 'anyRejected'], anyRejected)
    .setIn(['resultsQueryPromises', 'allResolved'], allResolved);
}

function setAndOrSegmentCondition(state, payload) {
  return state.set('andOrSegmentCondition', payload.value);
}

function setCurrencyType(state, payload) {
  return state.set('currencyType', payload.currencyType);
}

function setDifferenceTypeOverride(state, payload) {
  return state.set('differenceTypeOverride', payload.differenceType);
}

function setIsCleaningResultsFilters(state) {
  return state.set('isCleaningResultsFilters', true);
}

function setIsCleaningResultsFiltersToFalse(state) {
  return state.set('isCleaningResultsFilters', false);
}

/**
 *
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {Object} payload.statsConfig
 */
function setStatsConfig(state, payload) {
  return state.set('statsConfig', toImmutable(payload.statsConfig));
}

/**
 *
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {Number} payload.layerId
 * @param {Number} payload.begin
 * @param {Number} payload.end
 */
function setTimerange(state, payload) {
  return state.setIn(
    ['timerange', payload.layerId],
    toImmutable({
      begin: payload.begin,
      end: payload.end,
    }),
  );
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {Number} payload.layerId
 * @param {Number} payload.selectedMetric
 */
function setSelectedMetric(state, payload) {
  return state.setIn(
    ['selectedMetric', payload.layerId],
    toImmutable(payload.selectedMetric),
  );
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {Number} payload.layerId
 * @param {Number} payload.experimentId
 * @param {Number} payload.selectedVariation
 */
function setSelectedVariation(state, payload) {
  return state.setIn(
    ['selectedVariations', payload.layerId, payload.experimentId],
    toImmutable(payload.selectedVariation),
  );
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {Number} payload.selectedExperimentId
 */
function setSelectedExperimentId(state, payload) {
  return state.set(
    'selectedExperimentId',
    toImmutable(payload.selectedExperimentId),
  );
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {Array} payload.colorOrder
 */
function orderColors(state, payload) {
  return state.set('colorOrder', toImmutable(payload.colorOrder));
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {Array} payload.experimentIds
 */
function setSelectedExperimentIdsForFilter(state, payload) {
  return state.set(
    'selectedExperimentIdsForFilter',
    toImmutable(payload.experimentIds),
  );
}

/**
 *
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {Number} payload.selectedSectionId
 */
function setSelectedSectionId(state, payload) {
  return state.set('selectedSectionId', payload.selectedSectionId);
}
