/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox, Icon, Input, SelectDropdown } from '@optimizely/axiom';

import {
  formatEventProperties,
  getDefaultOperator,
  getOperatorOptions,
  mapEventProperties,
} from './utils';
import { BOOLEAN_FIELD_OPTIONS, COMBINE_OPTIONS } from './constants';

export function EventPropertiesFilter({ error, onChange, formValues }) {
  const {
    event: { event_properties },
    conditions,
    combineOperator,
    filterByProperties: checked,
  } = formValues;

  if (!event_properties || !event_properties.length) {
    return null;
  }

  const propertiesMap = mapEventProperties(event_properties);
  const propertiesOptions = formatEventProperties(event_properties);

  const addCondition = () => {
    const id = Date.now();
    const { name, data_type: type } = event_properties[0];
    const operator = getDefaultOperator(type);
    onChange('conditions', {
      ...conditions,
      [id]: {
        id,
        name,
        value: '',
        type,
        operator,
      },
    });
  };

  const removeCondition = id => {
    const { [id]: _, ...rest } = conditions;
    onChange('conditions', rest);
  };

  const handleFilterChange = propertyFilter => {
    onChange('conditions', {
      ...conditions,
      [propertyFilter.id]: { ...propertyFilter },
    });
  };

  const checkHandler = e => {
    onChange('filterByProperties', e.target.checked);
    if (!Object.keys(conditions).length) {
      addCondition();
    }
  };

  const conditionsArray = Object.values(conditions);
  const isLast = index => index === conditionsArray.length - 1;
  const hasMultipleConditions = conditionsArray.length > 1;

  return (
    <div>
      <div className="flex push-double--bottom">
        <Checkbox
          className="push--right"
          checked={checked}
          onChange={checkHandler}
          testSection="filter-by-properties-checkbox"
        />
        <div>
          <b>Filter this metric on event properties</b>
          {checked && (
            <div>
              Only count this metric if certain properties have specified
              values.
            </div>
          )}
        </div>
      </div>

      {checked && (
        <div>
          {hasMultipleConditions && (
            <div className="flex align-items-center push--bottom">
              Match{' '}
              <SelectDropdown
                buttonStyle="underline"
                items={COMBINE_OPTIONS}
                onChange={value => onChange('combineOperator', value)}
                value={combineOperator}
                width="auto"
                testSection="combine-operator-dropdown"
              />
              of the following conditions:
            </div>
          )}
          {error && <p className="color--bad-news">{error}</p>}
          {conditionsArray.map((condition, index) => (
            <div
              key={condition.id}
              className="flex align-items-center push--bottom">
              <Condition
                condition={condition}
                onChange={handleFilterChange}
                propertiesMap={propertiesMap}
                propertiesOptions={propertiesOptions}
              />
              {hasMultipleConditions && (
                <Icon
                  name="trash-can"
                  size="small"
                  className="lego-icon color--brand vertical-align--middle cursor--pointer push-half--left flex--none"
                  onClick={() => removeCondition(condition.id)}
                  testSection="remove-condition-button"
                />
              )}
              {isLast(index) && (
                <Icon
                  name="plus"
                  size="small"
                  className="lego-icon color--brand vertical-align--middle cursor--pointer push-half--left flex--none"
                  onClick={addCondition}
                  testSection="add-condition-button"
                />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

EventPropertiesFilter.propTypes = {
  error: PropTypes.string,
  formValues: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

function Condition({ condition, onChange, propertiesMap, propertiesOptions }) {
  const operatorOptions = getOperatorOptions(condition.type);
  const handlePropertyChange = value => {
    const { name, data_type: type } = propertiesMap[value];
    const operator = getDefaultOperator(type);

    onChange({
      ...condition,
      name,
      operator,
      type,
      value: type === 'boolean' ? true : '',
    });
  };

  const handleChange = (field, value) => {
    onChange({
      ...condition,
      [field]: value,
    });
  };

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '3fr 1fr 2fr',
        width: '70%',
        gap: '8px',
      }}>
      <SelectDropdown
        items={propertiesOptions}
        onChange={value => handlePropertyChange(value)}
        value={condition.name}
        fullWidth={true}
        testSection="event-property-dropdown"
      />
      <SelectDropdown
        items={operatorOptions}
        onChange={value => handleChange('operator', value)}
        value={condition.operator}
        fullWidth={true}
        testSection="event-property-operator-dropdown"
      />
      <ConditionValue condition={condition} onChange={handleChange} />
    </div>
  );
}

Condition.propTypes = {
  condition: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  propertiesMap: PropTypes.object.isRequired,
  propertiesOptions: PropTypes.array.isRequired,
};

function ConditionValue({ condition, onChange }) {
  if (condition.type === 'boolean') {
    return (
      <SelectDropdown
        items={BOOLEAN_FIELD_OPTIONS}
        onChange={value => onChange('value', value)}
        value={condition.value}
        fullWidth={true}
        testSection="event-property-value-dropdown"
      />
    );
  }

  return (
    <Input
      type={condition.type === 'number' ? 'number' : ''}
      onChange={e => onChange('value', e.target.value)}
      value={condition.value}
      textAlign="left"
      testSection="event-property-value-input"
    />
  );
}

ConditionValue.propTypes = {
  condition: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default { EventPropertiesFilter };
