import { DialogNew, Button, Link, Spinner } from '@optimizely/axiom';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { connect } from 'core/ui/decorators';
import {
  getters as CurrentLayerGetters,
  actions as CurrentLayerActions,
} from 'bundles/p13n/modules/current_layer';
import {
  actions as MetricsManagerModuleActions,
  getters as MetricsManagerModuleGetters,
} from 'bundles/p13n/modules/metrics_manager';
import {
  fns as MetricFns,
  constants as MetricConstants,
} from 'optly/modules/entity/metric';
import flux from 'core/flux';
import { getters as ViewGetters } from 'optly/modules/entity/view';
import { getters as EventGetters } from 'optly/modules/entity/event';
import Immutable from 'optly/immutable';
import ProjectActions from 'optly/modules/entity/project/actions';
import ProjectEnums from 'optly/modules/entity/project/enums';

import {
  buildMetricWrapper,
  buildMetricFormFromWrapper,
  validateMetricForm,
} from './utils';
import { METRIC_TYPES, GLOBAL_EVENTS } from './constants';
import { MetricsForm } from './metrics_form';
import { getters as MetricsPickerModuleGetters } from '../metrics_manager_react/subcomponents/metrics_picker_react/component_module';

const subtitle = (
  <div>
    Custom tracking events allow you to capture and report on visitor actions or
    events.{' '}
    <Link
      href="https://support.optimizely.com/hc/en-us/articles/4410289407885-Overview-of-metrics"
      newWindow={true}>
      Learn more
    </Link>
  </div>
);

@connect(() => ({
  layer: CurrentLayerGetters.layer,
  workingMetricWrappers: MetricsManagerModuleGetters.workingMetricWrappers,
}))
export class MetricsModalWrapper extends React.Component {
  render() {
    return <MetricsModal {...this.props} />;
  }
}

function MetricsModal({
  title,
  onClose,
  onSave,
  layer,
  metricWrapper,
  workingMetricWrappers,
}) {
  const [metricForm, setMetricForm] = useState({
    type: '',
    name: '',
    event: {},
    winningDirection: '',
    aggregator: '',
    scope: '',
    alias: '',
    combineOperator: 'and',
    conditions: {},
    filterByProperties: false,
    isDraft: true,
    compoundNumerator: null,
    compoundDenominator: null,
  });
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleFormValue = (field, value) => {
    setMetricForm(prev => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSave = () => {
    const errors = validateMetricForm(metricForm);
    if (!isEmpty(errors)) {
      setFormErrors(errors);
      return;
    }

    const updatedMetricWrapper = buildMetricWrapper(metricForm, layer);

    let metricWrappers;

    if (metricWrapper) {
      metricWrappers = workingMetricWrappers.map(wrapper => {
        if (
          wrapper.get('metric').get('alias') ===
          updatedMetricWrapper.get('metric').get('alias')
        ) {
          return updatedMetricWrapper;
        }
        return wrapper;
      });
    } else {
      metricWrappers = workingMetricWrappers.push(updatedMetricWrapper);
    }

    const updatedWorkingMetricWrappers = MetricFns.createMetricWrappers(
      metricWrappers.map(wrapper => wrapper.get('metric')),
      layer,
      flux.evaluate(ViewGetters.entityCache),
      flux.evaluate(EventGetters.entityCache),
    );

    MetricsManagerModuleActions.updateWorkingMetricWrappers(
      updatedWorkingMetricWrappers,
    );

    onSave();
  };

  useEffect(() => {
    if (metricWrapper) {
      setIsLoading(true);
      const { eventId, ...metricDataFromWrapper } = buildMetricFormFromWrapper(
        metricWrapper,
      );
      const { type: metricType, aggregator } = metricDataFromWrapper;
      let eventData;
      if (
        metricType === METRIC_TYPES.GLOBAL &&
        aggregator === MetricConstants.aggregationOptions.TOTAL_REVENUE
      ) {
        const [TOTAL_REVENUE] = GLOBAL_EVENTS;
        eventData = TOTAL_REVENUE;
      } else {
        eventData = flux.evaluateToJS(EventGetters.byId(eventId));
      }

      if (!eventData) {
        Promise.all([
          ProjectActions.fetchAll(
            {
              project_status: ProjectEnums.project_status.ACTIVE,
            },
            { excludeFields: ['jira_integration'] },
          ),
          CurrentLayerActions.fetchAllCrossProjectMetrics(),
        ]).then(() => {
          const eventsFullList = flux.evaluateToJS(
            MetricsPickerModuleGetters.queryableActiveProjectEventsMap,
          );
          eventData = eventsFullList[eventId] || {};
          setMetricForm(form => ({
            ...form,
            ...metricDataFromWrapper,
            event: eventData,
          }));
          setIsLoading(false);
        });
      } else {
        setMetricForm(form => ({
          ...form,
          ...metricDataFromWrapper,
          event: eventData,
        }));
        setIsLoading(false);
      }
    }
  }, [metricWrapper]);

  return (
    <DialogNew
      title={title}
      subtitle={subtitle}
      className="dialog-xl-wrapper"
      onClose={onClose}
      footerButtonList={
        isLoading
          ? []
          : [
              <Button key="cancel-button" onClick={onClose}>
                Cancel
              </Button>,
              <Button
                key="save-button"
                style="highlight"
                onClick={handleSave}
                testSection="metrics-modal-save-button">
                Save
              </Button>,
            ]
      }>
      {isLoading ? (
        <div className="flex flex-justified--center">
          <Spinner />
        </div>
      ) : (
        <MetricsForm
          formValues={metricForm}
          onChange={handleFormValue}
          layer={layer}
          formErrors={formErrors}
        />
      )}
    </DialogNew>
  );
}

MetricsModal.propTypes = {
  layer: PropTypes.instanceOf(Immutable.Map).isRequired,
  metricWrapper: PropTypes.instanceOf(Immutable.Map),
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  title: PropTypes.string,
  workingMetricWrappers: PropTypes.instanceOf(Immutable.List).isRequired,
};

MetricsModal.defaultProps = {
  title: '',
};

export default { MetricsModalWrapper };
