import includes from 'lodash/includes';
import { toImmutable } from 'optly/immutable';

import AudienceFns from 'optly/modules/entity/audience/fns';
import CurrentlyEditingExperimentFns from 'bundles/p13n/modules/currently_editing_experiment/fns';
import CurrentLayerFns from 'bundles/p13n/modules/current_layer/fns';

import filter from 'optly/utils/filter';
import LayerFns from 'optly/modules/entity/layer/fns';
import LayerExperimentFns from 'optly/modules/entity/layer_experiment/fns';
import LayerExperimentEnums from 'optly/modules/entity/layer_experiment/enums';
import tr from 'optly/translate';
import capitalize from 'optly/filters/capitalize';

import enums from './enums';

/**
 * Given a representation of priority groups, return a map of experiment ids in
 * the groups, to indexes of groups containing those ids. Example:
 *   Input:
 *     priorityGroups: [[1, 2], [3], [4, 5]]
 *
 *   Output:
 *     { 1: 0, 2: 0, 3: 1, 4: 2, 5: 2 }
 *
 * @param {Immutable.List} priorityGroups - List of lists of experiment ids
 * @return {Immutable.Map} A Map from experiment ids, to priority group indexes
 */
const getPriorityGroupsByExperiment = priorityGroups => {
  // flatMap is the same as map(...).flatten(), and only flattens one level
  // down
  const idGroupIndexPairs = priorityGroups.flatMap((group, index) =>
    group.map(experimentId => toImmutable([experimentId, index])),
  );
  // Convert list of [id, priorityGroupIndex] pairs into a map
  const groupIndexesById = idGroupIndexPairs.fromEntrySeq().toMap();
  return groupIndexesById;
};

/**
 * Combine experiment, audience, live commit, live commit tag, and layer data
 * into the format needed by the experiment cards
 * @param {Immutable.Map} experiments - LayerExperiment entity cache
 * @param {Immutable.Map} audiences - Audience entity cache
 * @param {Immutable.Map} liveCommit
 * @param {Immutable.Map} layer
 * @param {Boolean} canUpdateLayer
 * @param {Immutable.Map} liveTag
 * @return {Immutable.List} A list of experiments, in the correct order, with
 * extra properties needed by the experiment cards
 */
export const getExperimentCardData = (
  experiments,
  audiences,
  liveCommit,
  layer,
  canUpdateLayer,
  liveTag,
) => {
  let liveCommitLayerExperiments;
  let liveExperimentsById;
  if (liveCommit) {
    liveCommitLayerExperiments =
      liveCommit.getIn(['revisions', 'layer_experiment']) || toImmutable([]);
    // We need to figure out live/changed status for each experiment. We'll use
    // this, which maps live commit experiment IDs to full live experiment objects
    liveExperimentsById = liveCommitLayerExperiments.reduce(
      (byExperimentMap, liveExperiment) => {
        byExperimentMap[liveExperiment.get('id')] = liveExperiment;
        return byExperimentMap;
      },
      {},
    );
  }
  const {
    unarchivedGroups,
    archivedGroup,
  } = LayerFns.getPriorityGroupsSplitByStatus(layer, experiments);
  const priorityGroupsByExperiment = getPriorityGroupsByExperiment(
    unarchivedGroups,
  );
  const orderedExperiments = unarchivedGroups
    .flatten()
    .concat(archivedGroup)
    .map(experimentId => experiments.get(experimentId))
    // We may not have all experiments in the entityCache, so filter those out.
    .filter(e => e);
  return orderedExperiments.map(experiment => {
    const experimentAudiences = experiment
      .get('audience_ids')
      .map(audience_id => audiences.get(audience_id))
      .filter(audience => !!audience);

    const audienceLabels = AudienceFns.constructAudienceLabelFromList(
      experiment.get('audience_conditions'),
      experimentAudiences,
    );

    const experimentId = experiment.get('id');
    const liveExperiment = liveExperimentsById
      ? liveExperimentsById[experimentId]
      : null;
    const hasUnpublishedChanges = CurrentLayerFns.experimentHasUnpublishedChanges(
      experiment,
      liveExperiment,
    );
    const experimentSnippetStatus = CurrentLayerFns.getExperimentSnippetStatus(
      experiment,
      liveExperiment,
      liveTag,
    );
    const variations = experiment.get('variations');
    const variationsWithPercentages = LayerExperimentFns.addPercentageToVariations(
      variations,
    );
    const isMultivariateTestLayer = LayerFns.isMultivariateTestLayer(layer);
    const isPersonalizationLayer = LayerFns.isPersonalizationLayer(layer);
    const variationsWithModificationInfo = LayerExperimentFns.addVariationModificationInfo(
      variationsWithPercentages,
      canUpdateLayer,
      liveExperiment,
      isMultivariateTestLayer,
      isPersonalizationLayer,
      experiment.get('allocation_policy'),
    );
    const variationsWithStatus = CurrentLayerFns.addStatusInfoToVariations(
      layer,
      variationsWithModificationInfo,
      experiment,
      liveExperiment,
    );
    const finalVariations = CurrentlyEditingExperimentFns.addTrafficDistributionInfoToVariations(
      variationsWithStatus,
      layer.get('holdback'),
    );
    return experiment.withMutations(mutableExp => {
      mutableExp.set('audiences', experimentAudiences);
      mutableExp.set('audienceLabels', audienceLabels);
      mutableExp.set('variations', finalVariations);
      mutableExp.set('hasUnpublishedChanges', hasUnpublishedChanges);
      mutableExp.set('snippetStatus', experimentSnippetStatus);
      mutableExp.set('name', LayerFns.getExperimentName(mutableExp, layer));
      mutableExp.set(
        'priorityGroupIndex',
        priorityGroupsByExperiment.get(experimentId),
      );
      mutableExp.set(
        'suggestedNewVariationName',
        LayerExperimentFns.suggestedNewVariationName(
          experiment.get('variations'),
        ),
      );
    });
    // TODO: Primary metric improvement
  });
};

/**
 * Returns true if an experiment should be shown for a given status filter
 * @param {Immutable.Map} experiment
 * @param {String} statusFilter - One of enums.experimentFilterStatuses
 * @return {Boolean}
 */
const experimentAllowedByStatusFilter = (experiment, statusFilter) => {
  const experimentStatus = experiment.get('status');

  switch (statusFilter) {
    case enums.experimentFilterStatuses.ALL:
      return true;

    case enums.experimentFilterStatuses.ACTIVE:
      return includes(
        [
          LayerExperimentEnums.status.ACTIVE,
          LayerExperimentEnums.status.PAUSED,
        ],
        experimentStatus,
      );

    case enums.experimentFilterStatuses.ARCHIVED:
      return experimentStatus === LayerExperimentEnums.status.ARCHIVED;

    case enums.experimentFilterStatuses.PAUSED:
      return experimentStatus === LayerExperimentEnums.status.PAUSED;

    default:
      if (__INVARIANT__) {
        throw new Error(
          `Unknown filter status on campaign overview experiments page: ${statusFilter}`,
        );
      }
      return true;
  }
};

/**
 * @param {Immutable.List} audiences
 * @return {String}
 */
const getAudiencesSubtitle = audiences => {
  if (audiences.size === 0) {
    return tr('Everyone');
  }
  const names = audiences.map(audience => audience.get('name'));
  return names.toJS().join(', ');
};

/**
 * Return a filtered experiments list, without experiments that don't match the
 * string or status filters. If the experiment has a name, the filtering will be
 * done against that, otherwise it'll use audiences.
 * @param {Immutable.List} experiments
 * @param {Immutable.Map} experimentsCache
 * @param {String} stringFilter
 * @param {String} statusFilter
 * @return {Immutable.List}
 */
export const filterExperiments = (
  experiments,
  experimentsCache,
  stringFilter,
  statusFilter,
) =>
  experiments.filter(experiment => {
    if (!experimentAllowedByStatusFilter(experiment, statusFilter)) {
      return false;
    }

    if (stringFilter === '') {
      return true;
    }

    const experimentName = experimentsCache.getIn([
      experiment.get('id'),
      'name',
    ]);
    if (experimentName) {
      return filter.isFilterTermInItem(stringFilter, experimentName);
    }

    const audiencesSubtitle = getAudiencesSubtitle(experiment.get('audiences'));
    return filter.isFilterTermInItem(stringFilter, audiencesSubtitle);
  });

/**
 * @param {Immutable.Map} layer
 * @param {Immutable.Map} liveCommit - liveCommit
 * @param {Immutable.Map} experiments - LayerExperiment entity cache
 * @param {Immutable.Map} audiences - Audience entity cache
 * @return {Immutable.Map} Data needed by the experiment priority dialog
 */
export const getExperimentPriorityDialogData = (
  layer,
  liveCommit,
  experiments,
  audiences,
) => {
  // We don't want to show archived experiments in the priorities dialog, so
  // filter out ids of archived experiments, and filter out empty groups that
  // result from removing archived IDs.
  // When saving changes made in the dialog, we'll add the archived IDs back in
  // before saving to the backend (see this module's actions)
  let liveCommitLayerExperiments;
  let liveExperimentsById;
  if (liveCommit) {
    liveCommitLayerExperiments =
      liveCommit.getIn(['revisions', 'layer_experiment']) || toImmutable([]);
    // We need to figure out live/changed status for each experiment. We'll use
    // this, which maps live commit experiment IDs to full live experiment objects
    liveExperimentsById = liveCommitLayerExperiments.reduce(
      (byExperimentMap, liveExperiment) => {
        byExperimentMap[liveExperiment.get('id')] = liveExperiment;
        return byExperimentMap;
      },
      {},
    );
  }
  const { unarchivedGroups } = LayerFns.getPriorityGroupsSplitByStatus(
    layer,
    experiments,
  );
  const layerExperimentsById = unarchivedGroups
    .flatten()
    .reduce((byIdMap, experimentId) => {
      const experiment = experiments.get(experimentId);
      const experimentAudiences = experiment
        .get('audience_ids')
        .map(audienceId => audiences.get(audienceId));
      const liveExperiment = liveExperimentsById
        ? liveExperimentsById[experimentId]
        : null;
      const hasUnpublishedChanges = CurrentLayerFns.experimentHasUnpublishedChanges(
        experiment,
        liveExperiment,
      );
      const audienceLabels = AudienceFns.constructAudienceLabelFromList(
        experiment.get('audience_conditions'),
        experimentAudiences,
      );
      return byIdMap.set(
        experimentId,
        toImmutable({
          name: experiment.get('name'),
          audiencesCount: experiment.get('audience_ids').size,
          audienceLabels,
          hasUnpublishedChanges,
          isPaused:
            experiment.get('status') === LayerExperimentEnums.status.PAUSED,
        }),
      );
    }, toImmutable({}));
  return toImmutable({
    layerId: layer.get('id'),
    experiments: layerExperimentsById,
    priorityGroups: unarchivedGroups,
  });
};

/**
 * Returns true if any of the experiments are unarchived with unpublished
 * changes
 * @param {Immutable.List} experiments - Immutable.List of Immutable.Map, with
 * each Map having properties hasUnpublishedChanges and status
 * @return {Boolean}
 */
export const unarchivedExperimentsHaveUnpublishedChanges = experiments =>
  experiments.some(
    experiment =>
      experiment.get('hasUnpublishedChanges') &&
      experiment.get('status') !== LayerExperimentEnums.status.ARCHIVED,
  );

export const getBucketingStrategyOptions = () => {
  const { LIFETIME, IMPRESSION } = enums.BucketingOptions;
  return [
    {
      activatorLabel: capitalize(LIFETIME),
      label: tr(capitalize(`${LIFETIME} (default)`)),
      description:
        'Choose a new variation of this experiment only if the visitor has not been previously bucketed.',
      value: LIFETIME,
    },
    {
      label: tr(capitalize(IMPRESSION)),
      description:
        'Choose a new variation of this experiment for each impression.',
      value: IMPRESSION,
    },
  ];
};

/**
 * @name getAllCampaignAudienceIds
 * @description Returns an array of unique audienceIds connected to a campaign
 *
 * @param experiments {Array}
 *
 * @returns {Immutable.Set}
 */
export const getAllCampaignAudienceIds = (experiments = []) => {
  let allCampaignAudienceIds = toImmutable([]);
  experiments.forEach(exp => {
    allCampaignAudienceIds = allCampaignAudienceIds.concat(
      LayerExperimentFns.deriveAudienceIdsFromAudienceConditions(
        toImmutable(exp.audience_conditions_json),
      ),
    );
  });
  return allCampaignAudienceIds.toSet();
};

export default {
  filterExperiments,
  getAllCampaignAudienceIds,
  getBucketingStrategyOptions,
  getExperimentCardData,
  getExperimentPriorityDialogData,
  unarchivedExperimentsHaveUnpublishedChanges,
};
