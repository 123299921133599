/* eslint-disable react/jsx-no-bind */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState } from 'react';
import {
  Sortable,
  Button,
  Sheet,
  Tile,
  Input,
  Attention,
} from '@optimizely/axiom';

import LayerFns from 'optly/modules/entity/layer/fns';
import { getters as CurrentLayerGetters } from 'bundles/p13n/modules/current_layer';
import Immutable from 'optly/immutable';
import ui from 'core/ui';

import { actions as SectionModuleActions } from 'bundles/p13n/sections/campaign_overview/section_module';
import { useGetters } from 'core/ui/methods/connect_getters';

type ExperimentPrioritiesDialogProps = {
  data: Immutable.Map<string, any>;
};

type TrafficAllocation = {
  experimentId: number;
  percentage: number;
};

type GroupedExperience = {
  order: number;
  id: string;
  trafficAllocation: TrafficAllocation[];
  hasError: boolean;
};

const ExperimentPrioritiesDialogV2 = ({
  data,
}: ExperimentPrioritiesDialogProps) => {
  const { currentLayer } = useGetters({
    currentLayer: CurrentLayerGetters.layer,
  }) as { currentLayer: Immutable.Map<string, any> };

  const [priorityGroups, setPriorityGroups] = useState(
    data.get('priorityGroups'),
  );
  const [isSaving, setSave] = useState<boolean>(false);
  const [groupedExperiences, setGroupedExperiences] = useState<
    GroupedExperience[]
  >(createGroups(priorityGroups.toJS()));

  function handleSubmit() {
    if (isSaving) {
      return;
    }

    setSave(true);

    SectionModuleActions.saveExperimentPriorities(
      data.get('layerId'),
      priorityGroups,
    )
      .then(() => {
        ui.showNotification({
          message: 'Experience priorities have been updated.',
          type: 'success',
        });
        setSave(false);
        ui.hideDialog();
      })
      .fail(() => {
        setSave(false);
      });
  }

  function handleCancel() {
    ui.hideDialog();
  }

  function handleSortableChange(sortableGroups: Immutable.List<any>) {
    const newPriorityGroups = sortableGroups.map(sortableItems =>
      sortableItems.map((item: any) => item.get('id')),
    );

    const groups = createGroups(newPriorityGroups.toJS());
    const previousExperiences = [...groupedExperiences];

    groups.forEach((group: GroupedExperience) => {
      const prevGroup = previousExperiences.find(
        _group => group.id === _group.id,
      );
      // preserve the traffic allocation if the group has not changed
      if (prevGroup) {
        group.hasError = getTotalTraffic(prevGroup.trafficAllocation) !== 100;
        group.trafficAllocation = prevGroup.trafficAllocation;
      }
    });

    setPriorityGroups(newPriorityGroups);
    setGroupedExperiences(groups);
  }

  function getExperimentDisplayName(experiment: Immutable.Map<string, any>) {
    const name = experiment.get('name');
    if (name) {
      return name;
    }

    return experiment.get('audienceLabels').join(' ');
  }

  function renderSortableItem({ item, connectDragSource }: any) {
    const id = item.get('id');

    let name = item.get('text');
    if (item.get('isPaused')) {
      name = `${name} (Paused)`;
    } else if (item.get('hasUnpublishedChanges')) {
      name = `${name} (Draft)`;
    }

    const trafficAllocation = {} as TrafficAllocation;
    let order: number;
    let hasError = false;
    let hasSingleItem = false;
    groupedExperiences.forEach(group => {
      group.trafficAllocation.forEach(experiment => {
        if (experiment.experimentId === id) {
          Object.assign(trafficAllocation, experiment);
          order = group.order;
          hasError = group.hasError;
          hasSingleItem = group.trafficAllocation.length === 1;
        }
      });
    });

    const { percentage } = trafficAllocation;

    return connectDragSource(
      <div className="flex align-items-center">
        <Tile isDraggable={true} name={name} />
        {!hasSingleItem ? (
          <Input
            testSection="experiment-priorities-traffic"
            className="traffic"
            value={percentage}
            type="number"
            max={100}
            min={0}
            displayError={hasError}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              updateTraffic(event.target.value, id, order);
            }}
            RightContainer={() => '%'}
          />
        ) : (
          <Input
            testSection="experiment-priorities-traffic"
            className="traffic"
            type="text"
            value="Auto"
            disabled={true}
          />
        )}
      </div>,
    );
  }

  function createGroups(groups: number[][]) {
    return groups.map((group, index) => {
      const trafficAllocation = allocateTraffic(group);
      const id = [...group].sort().join('-');

      return {
        order: index + 1,
        id,
        trafficAllocation,
        hasError: false,
      };
    });
  }

  function allocateTraffic(group: number[]): TrafficAllocation[] {
    const count = group.length;
    const distributedValue = Number((100 / count).toFixed(2));
    const remainder = Number((100 - distributedValue * count).toFixed(2));
    const lastValue = Number((distributedValue + remainder).toFixed(2));

    const traffic = group.map((experimentId: number, index: number) => {
      const percentage = index < count - 1 ? distributedValue : lastValue;
      return {
        experimentId,
        percentage,
      };
    });
    return traffic;
  }

  /**
   * Updates the traffic allocation percentage for a specific experiment in the groupedExperiences state.
   *
   * @param {string} value - The new traffic allocation percentage.
   * @param {string} experimentId - The ID of the experiment.
   * @param {number} order - The order of the group.
   */
  function updateTraffic(value: string, experimentId: number, order: number) {
    const percentage = Number(value);
    if (percentage > 100 || percentage < 0) {
      return;
    }

    const groups = [...groupedExperiences];
    groups.forEach(group => {
      if (group.order === order) {
        const index = group.trafficAllocation.findIndex(
          experiment => experiment.experimentId === experimentId,
        );
        group.trafficAllocation[index].percentage = percentage;
        const totalPercentage = getTotalTraffic(group.trafficAllocation);
        group.hasError = totalPercentage !== 100;
      }
    });

    setGroupedExperiences(groups);
  }

  function getTotalTraffic(trafficAllocation: TrafficAllocation[]): number {
    return trafficAllocation.reduce((acc, experiment) => {
      return acc + experiment.percentage;
    }, 0);
  }

  const sortablePriorityGroups = priorityGroups.map((group: number[]) =>
    group.map((id: number) =>
      Immutable.Map({
        id,
        text: getExperimentDisplayName(data.getIn(['experiments', id])),
        isPaused: data.getIn(['experiments', id, 'isPaused']),
        hasUnpublishedChanges: data.getIn([
          'experiments',
          id,
          'hasUnpublishedChanges',
        ]),
        type: 'item',
      }),
    ),
  );

  const displayError = groupedExperiences.some(group => group.hasError);

  return (
    <Sheet
      title="Prioritize Experiences"
      onClose={ui.hideDialog}
      testSection="experiment-priorities-dialog"
      subtitle={
        <p className="push-double--bottom">
          Change the order of each experience to control what visitors are
          bucketed into what experiences. You can group experiences here as
          well, by dragging one onto another. Grouped experiences will have
          traffic split between them randomly. Within grouped experiences, you
          can modify the traffic percentage that goes to each experience.
        </p>
      }
      footerButtonList={[
        <Button
          key="Cancel"
          style="plain"
          isDisabled={isSaving}
          testSection="experiment-priorities-cancel-button"
          onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="Save"
          style="highlight"
          isDisabled={
            isSaving || LayerFns.isLayerReadonly(currentLayer) || displayError
          }
          testSection="experiment-priorities-save-button"
          onClick={handleSubmit}>
          Save
        </Button>,
      ]}>
      <div className="flex align-items-end priority-label">
        <div className="milli muted">Priority</div>
        <div className="milli muted">Experience</div>
        <div className="milli muted traffic">Traffic Percentage</div>
      </div>
      <Sortable
        defaultValue={sortablePriorityGroups.toJS()}
        renderItem={renderSortableItem}
        allowGrouping={true}
        onChange={handleSortableChange}
        testSection="experiment-priorities-sortable"
      />
      {displayError && (
        <Attention
          testSection="experiment-priorities-error-message"
          type="bad-news">
          Traffic percentage must add up to 100% for each group of Experiences.
        </Attention>
      )}
    </Sheet>
  );
};

export default ExperimentPrioritiesDialogV2;
