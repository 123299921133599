import keyMirror from 'optly/utils/key_mirror';

export default keyMirror({
  ADD_SELECTED_SEGMENT: null,
  API_REQUEST_REJECTED: null,
  API_REQUEST_RESOLVED: null,
  CLEAR_SELECTED_SEGMENTS: null,
  REMOVE_SELECTED_SEGMENT: null,
  RESULTS_SET_CURRENCY_TYPE: null,
  RESULTS_SET_DIFFERENCE_TYPE_OVERRIDE: null,
  RESULTS_SET_STATS_CONFIG: null,
  RESULTS_ADD_PROMISE: null,
  RESULTS_CLEAR_PROMISES: null,
  RESULTS_SET_TIMERANGE: null,
  RESULTS_SET_SELECTED_METRIC: null,
  RESULTS_SET_SELECTED_EXPERIMENT: null,
  RESULTS_SET_SELECTED_EXPERIMENT_IDS_FOR_FILTER: null,
  RESULTS_SET_SELECTED_SECTION_ID: null,
  RESULTS_SET_SELECTED_SEGMENT: null,
  RESULTS_SET_SELECTED_VARIATION: null,
  RESULTS_ORDER_COLORS: null,
  SET_AND_OR_SEGMENT_CONDITION: null,
  SET_IS_CLEANING_RESULTS_FILTERS: null,
  SET_IS_CLEANING_RESULTS_FILTERS_TO_FALSE: null,
});
