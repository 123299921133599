import React, { useState, useEffect, useMemo } from 'react';

import PropTypes from 'prop-types';

import { DragAndDrop, Tile } from '@optimizely/axiom';

import ui from 'core/ui';

import { getFullMetricDescription } from '../../../metrics_manager_utils';

import {
  EditMetricAction,
  RemoveMetricAction,
  MoveToBottomAction,
  MoveToTopAction,
} from '../metrics_tile_dropdown_actions';
import { MetricName, MetricDescription } from '../metrics_tile_info';

const MetricsDragAndDrop = ({
  metricTileItems,
  updateWorkingMetricWrappers,
  removeMetric,
  isMultiArmedBandit,
  isStatsAcceleratorExperiment,
}) => {
  const draggableMetrics = useMemo(() => {
    return metricTileItems.map((metric, index) => {
      const {
        metricWrapper,
        editMetricFn,
        pushMetricTopFn,
        pushMetricBottomFn,
      } = metric;
      const displayName = metricWrapper.get('name');
      const description = getFullMetricDescription(metricWrapper);
      const singleMetricData = metricWrapper.get('metric');
      const eventId = singleMetricData.get('event_id') || index;
      return {
        description,
        id: eventId,
        metric,
        displayName,
        metricWrapper,
        editMetricFn,
        pushMetricTopFn,
        pushMetricBottomFn,
      };
    });
  }, [metricTileItems]);

  const [orderedMetrics, setOrderedMetrics] = useState(draggableMetrics);

  useEffect(() => {
    setOrderedMetrics(draggableMetrics);
  }, [draggableMetrics]);

  const onRemoveMetric = metricWrapper => {
    const displayName = metricWrapper.get('name');
    ui.confirm({
      title: tr('Remove metric'),
      message: tr(
        'Are you sure you would like to remove the metric {0} from this experiment?',
        displayName,
      ),
      confirmText: tr('Remove'),
    }).then(() => {
      removeMetric(metricWrapper);
    });
  };

  const getPrimaryMetricStatus = (
    isMAB,
    isStatsAcceleratorOn,
    metricList,
    listItemIndex,
  ) => {
    if (isMAB || isStatsAcceleratorOn) {
      return '';
    }
    if (metricList.length > 1 && listItemIndex === 0) {
      return 'Primary';
    }
    return '';
  };

  return (
    <DragAndDrop
      idForDroppableRegion="metrics-drag-and-drop"
      items={orderedMetrics}
      onDragEnd={updatedMetrics => {
        updateWorkingMetricWrappers(updatedMetrics);
        setOrderedMetrics(updatedMetrics);
      }}
      renderItem={items => {
        const { item, index } = items;
        const {
          description,
          displayName,
          metricWrapper,
          id,
          editMetricFn,
          pushMetricTopFn,
          pushMetricBottomFn,
        } = item;
        const getActionsDropdownItems = () => {
          return [
            <EditMetricAction
              key="edit-metric-action"
              onEditClick={() => editMetricFn()}
            />,
            <RemoveMetricAction
              key="remove-metric-action"
              onRemoveClick={() => onRemoveMetric(metricWrapper)}
            />,
            <MoveToTopAction
              key="move-top-metric-action"
              pushMetricTopFn={() => pushMetricTopFn()}
            />,
            <MoveToBottomAction
              key="move-bottom-metric-action"
              pushMetricBottomFn={() => pushMetricBottomFn()}
            />,
          ];
        };

        return (
          <div className="width--1-1">
            <Tile
              description={<MetricDescription description={description} />}
              dropdownItems={getActionsDropdownItems()}
              isDraggable={true}
              key={id}
              name={<MetricName name={displayName} />}
              status={getPrimaryMetricStatus(
                isMultiArmedBandit,
                isStatsAcceleratorExperiment,
                metricTileItems,
                index,
              )}
              onTileClick={() => {}}
              testSection={`metric-tile-${displayName || 'overall-revenue'}`}
            />
          </div>
        );
      }}
    />
  );
};

MetricsDragAndDrop.propTypes = {
  isMultiArmedBandit: PropTypes.bool.isRequired,
  isStatsAcceleratorExperiment: PropTypes.bool.isRequired,
  metricTileItems: PropTypes.array.isRequired,
  removeMetric: PropTypes.func.isRequired,
  updateWorkingMetricWrappers: PropTypes.func.isRequired,
};

export default MetricsDragAndDrop;
