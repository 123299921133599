/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
export enum EventCategoryEnum {
  AddToCart = 'add_to_cart',
  Convert = 'convert',
  Other = 'other',
  Purchase = 'purchase',
  Save = 'save',
  Search = 'search',
  Share = 'share',
  SignUp = 'sign_up',
  Subscribe = 'subscribe',
}

export enum EventTypeEnum {
  Custom = 'custom',
}

export type Event = {
  category: EventCategoryEnum;
  variation_specific: boolean;
  archived: boolean;
  description: string;
  created: Date;
  view_id: number | null;
  account_id: number;
  name: string;
  api_name: string;
  last_modified: Date;
  event_filter: { [key: string]: any } | null;
  event_properties: Array<{ name: string; data_type: string }>;
  project_id: number;
  config: { [key: string]: any } | null;
  id: number;
  event_type: EventTypeEnum;
};

export enum EventFromSearchApiType {
  Event = 'event',
}

export type EventFromSearchApi = {
  archived: boolean;
  created: Date;
  description: string;
  id: number;
  last_modified: Date;
  name: string;
  project_id: number;
  type: EventFromSearchApiType;
};

export enum MetricAggregatorEnum {
  Count = 'count',
  Sum = 'sum',
  Unique = 'unique',
}

export enum MetricFieldEnum {
  Revenue = 'revenue',
  Value = 'value',
}

export enum MetricScopeEnum {
  Event = 'event',
  Visitor = 'visitor',
}

export enum MetricWinningDirectionEnum {
  Increasing = 'increasing',
  Decreasing = 'decreasing',
}

export type FilterCondition = {
  name: string;
  operator: string;
  type: string;
  value: string;
};

export type EventPropertiesFilter = {
  filter: {
    combine_operator: string;
    conditions: Array<FilterCondition>;
  };
};

/**
 * Supported values based off of "metrics" field in API (although worth noting the below will be part of the Flags Ruleset API)
 * @see https://optimizely.github.io/docs/api/v2/#operation/update_experiment
 */
export type Metric = {
  /**
   * The aggregation function for the numerator of the metric. 'unique' measures the number of unique visitors/sessions
   * that include the specified Event. 'count' measures the total number of occurrences of Event for
   * the scope (visitor/session). 'sum' is the sum of the 'field' value
   */
  aggregator: MetricAggregatorEnum;
  display_title: string;
  display_unit: null;
  /**
   * The type of this Event. Omitted for global metrics that are not relative to a specific Event, i.e. "overall revenue"
   */
  event_type: EventTypeEnum | null;
  /** The ID for the Event to select data from. Omitted for global metrics that are not relative to a specific Event, i.e. "overall revenue" */
  event_id: number | null;
  /**
   * The field to aggregate for the numerator of the metric. Required when 'aggregator' = 'sum', otherwise omitted
   */
  field: MetricFieldEnum | null;
  /**
   * Specifies how Events should be grouped together. Can also be thought of as the denominator of the metric. 'session' divides
   * by the number of sessions. "Influenced sessions", or sessions that do not contain a decision Event but carry a decision
   * from a previous session are not included in counts for numerator or denominator. 'visitor' divides by the number
   * of visitors. 'event' divides by the total occurrences (impressions) of the specified Event.
   */
  scope: MetricScopeEnum;
  /**
   * The direction that determines the success of this metric
   */
  winning_direction: MetricWinningDirectionEnum;
  event_properties?: EventPropertiesFilter;
};

export enum SheetMetricTypeEnum {
  Custom = 'custom',
  Revenue = 'revenue',
}
/* eslint-enable no-unused-vars */
/* eslint-enable camelcase */
